import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Checkbox,
  HStack,
  Spacer,
  useToast,
  Input,
} from "@chakra-ui/react";
import {
  useDeleteBuyerLocationRelationMutation,
  useGetBuyerLocationsQuery,
  useGetOrganisationLocationsQuery,
  usePostBuyerLocationRelationMutation,
  usePostBuyingLocationMutation,
  usePostCustomerOrganisationWithLocationMutation,
} from "../../redux/apiSlice";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import OrganisationSearchOrCreate from "../../components/OrganisationSearchOrCreate/OrganisationSearchOrCreate";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { GoogleMapsAutoComplete } from "../../components/GoogleMapsAutoComplete/GoogleMapsAutoComplete.tsx";
import * as Sentry from "@sentry/react";
import { areSetsEqual } from "../../utils/calcUtils.ts";
import { Location, LocationBase } from "../../types";
import { getAddressFromComponents } from "../../utils/addressUtils.ts";

interface AddCustomerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddCustomerModal = ({ isOpen, onClose }: AddCustomerModalProps) => {
  const toast = useToast();
  const [radioValue, setRadioValue] = React.useState("org");
  const [isIndividual, setIsIndividual] = useState<boolean>(false);

  const errorLoadingMapsApiKey: boolean =
    !import.meta.env.VITE_GOOGLE_MAPS_API_KEY ||
    !import.meta.env.VITE_GOOGLE_MAPS_API_KEY.length;

  const handleSetIsIndividual = (value: string) => {
    setRadioValue(value);
    setIsIndividual(value === "individual");
  };

  const [selectedOrganisationId, setSelectedOrganisationId] = useState<
    number | null | undefined
  >(null);
  const [selectedOrganisationName, setSelectedOrganisationName] = useState<
    string | null
  >(null);

  const [newLocation, setNewLocation] = useState<LocationBase | null>(null);
  const [newLocationName, setNewLocationName] = useState<string | null>(null);

  const [isNewOrganisation, setIsNewOrganisation] = useState<boolean>(false);

  const [showAddLocation, setShowAddLocation] = useState<boolean>(false);
  const [showLocationNameInput, setShowLocationNameInput] =
    useState<boolean>(false);

  const [existingBuyerLocationIds, setExistingBuyerLocationIds] = useState<
    Set<string>
  >(new Set());
  const [currentBuyerLocationIds, setCurrentBuyerLocationIds] = useState<
    Set<string>
  >(new Set());

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const { data: buyerLocations } = useGetBuyerLocationsQuery({
    organisationId,
    locationId,
  });

  const { data: selectedOrganisationLocations } =
    useGetOrganisationLocationsQuery({
      organisationId: selectedOrganisationId?.toString(),
    });

  const [postBuyer, { isLoading: isLoadingPostBuyer }] =
    usePostBuyerLocationRelationMutation();
  const [deleteBuyer, { isLoading: isLoadingDeleteBuyer }] =
    useDeleteBuyerLocationRelationMutation();

  const [postBuyerLocation, { isLoading: isLoadingPostBuyerLocation }] =
    usePostBuyingLocationMutation();

  const [
    postOrganisationWithLocation,
    { isLoading: isLoadingPostCustomerOrg },
  ] = usePostCustomerOrganisationWithLocationMutation();

  useEffect(() => {
    if (errorLoadingMapsApiKey) {
      Sentry.captureException("Error loading Google Maps API key");
    }
  }, [isOpen]);

  useEffect(() => {
    if (!selectedOrganisationId) {
      setExistingBuyerLocationIds(new Set<string>());
      setCurrentBuyerLocationIds(new Set<string>());
      return;
    }
    if (buyerLocations) {
      const newBuyerLocationIds = new Set<string>();
      buyerLocations.forEach((buyer) => {
        newBuyerLocationIds.add(buyer.id.toString());
      });
      setExistingBuyerLocationIds(newBuyerLocationIds);
      setCurrentBuyerLocationIds(newBuyerLocationIds);
    } else {
      setExistingBuyerLocationIds(new Set<string>());
      setCurrentBuyerLocationIds(new Set<string>());
    }
  }, [buyerLocations, selectedOrganisationId]);

  useEffect(() => {
    if (!selectedOrganisationId) {
      setIsNewOrganisation(
        Boolean(
          selectedOrganisationName && selectedOrganisationName.length > 0,
        ),
      );
    } else {
      setIsNewOrganisation(false);
    }
  }, [selectedOrganisationId, selectedOrganisationName]);

  useEffect(() => {
    if (isNewOrganisation) {
      // logic for new orgs to follow
      setIsSaveDisabled(false);
      return;
    }
    if (currentBuyerLocationIds && existingBuyerLocationIds) {
      // if changes have occurred, i.e. tha currentBuyerLocationIds selected differ from existing, enable saving
      setIsSaveDisabled(
        areSetsEqual(currentBuyerLocationIds, existingBuyerLocationIds),
      );
    }
    if (
      newLocation &&
      newLocation.name &&
      newLocation.name.length > 0 &&
      newLocation.address
    ) {
      setIsSaveDisabled(false);
    }
  }, [
    currentBuyerLocationIds,
    existingBuyerLocationIds,
    newLocation,
    selectedOrganisationId,
    selectedOrganisationName,
    isNewOrganisation,
  ]);

  useEffect(() => {
    if (newLocation) {
      setNewLocation({ ...newLocation, name: newLocationName || "" });
    }
  }, [newLocationName]);

  const handleSelectExistingLocation = (locationId: string) => {
    const cachedBuyerLocationIds = new Set(currentBuyerLocationIds);
    if (!cachedBuyerLocationIds.has(locationId)) {
      cachedBuyerLocationIds.add(locationId);
    } else {
      cachedBuyerLocationIds.delete(locationId);
    }
    setCurrentBuyerLocationIds(cachedBuyerLocationIds);
  };

  const getNewLocationName = (name: string) =>
    selectedOrganisationName && name.includes(selectedOrganisationName)
      ? name.split(selectedOrganisationName)[1].trimStart()
      : name;

  const handleAddBuyers = async (buyerLocationList: string[]) => {
    const successfullyProcessedRelations: string[] = [];
    const failedRelations: string[] = [];
    if (!selectedOrganisationId) {
      return { successfullyProcessedRelations, failedRelations };
    }
    for (const buyerLocationId of buyerLocationList) {
      try {
        await postBuyer({
          buyerOrganisationId: selectedOrganisationId,
          buyerLocationId,
          organisationId,
          locationId,
        }).unwrap();
        successfullyProcessedRelations.push(buyerLocationId);
      } catch (e) {
        Sentry.captureException(e);
        failedRelations.push(buyerLocationId);
        currentBuyerLocationIds.delete(buyerLocationId);
      }
    }
    return { successfullyProcessedRelations, failedRelations };
  };

  const handleDeleteBuyers = async (buyerLocationList: string[]) => {
    const successfullyProcessedRelations: string[] = [];
    const failedRelations: string[] = [];
    if (!selectedOrganisationId) {
      return { successfullyProcessedRelations, failedRelations };
    }
    for (const buyerLocationId of buyerLocationList) {
      try {
        await deleteBuyer({
          buyerOrganisationId: selectedOrganisationId,
          buyerLocationId,
          organisationId,
          locationId,
        }).unwrap();
        successfullyProcessedRelations.push(buyerLocationId);
      } catch (e) {
        Sentry.captureException(e);
        failedRelations.push(buyerLocationId);
        currentBuyerLocationIds.add(buyerLocationId);
      }
    }
    return { successfullyProcessedRelations, failedRelations };
  };

  const handleSave = async () => {
    if (
      (isIndividual || (isNewOrganisation && newLocation)) &&
      selectedOrganisationName &&
      organisationId &&
      locationId
    ) {
      await postOrganisationWithLocation({
        organisationId: organisationId,
        locationId: locationId,
        customerOrganisation: {
          name: selectedOrganisationName,
          is_individual: isIndividual,
        },
        customerLocation: newLocation || {
          name: "",
          organisation_id: 0,
          is_supplier: false,
          is_producer: false,
        },
      })
        .unwrap()
        .then((response) => {
          setSelectedOrganisationId(response.id);
          onClose();
        })
        .catch((error: object) => {
          let errorMessage = "Failed to save new customer";
          // @ts-expect-error - data is expected
          if (error?.data && error?.data?.detail?.includes("already exists")) {
            errorMessage = "Customer already exists";
          }
          toast({
            title: "Error",
            description: errorMessage,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    } else {
      if (!isNewOrganisation && selectedOrganisationId) {
        if (
          newLocation &&
          newLocation?.address &&
          organisationId &&
          locationId
        ) {
          await postBuyerLocation({
            supplierOrganisationId: organisationId,
            supplierLocationId: locationId,
            buyerLocation: newLocation,
          })
            .unwrap()
            .then(() => {
              toast({
                title: "Success",
                description: "Saved new location",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
              setNewLocation(null);
              setShowLocationNameInput(false);
              setShowAddLocation(false);
            })
            .catch((error: unknown) => {
              toast({
                title: "Error",
                description: (error as Error).message,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            });
        }
        const supplierLocationRelationsToAdd = [
          ...currentBuyerLocationIds,
        ].filter((e) => !existingBuyerLocationIds.has(e));
        const supplierLocationRelationsToRemove = [
          ...existingBuyerLocationIds,
        ].filter((e) => !currentBuyerLocationIds.has(e));

        try {
          const [buyerAddResults, buyerDeleteResults] = await Promise.all([
            handleAddBuyers(supplierLocationRelationsToAdd),
            handleDeleteBuyers(supplierLocationRelationsToRemove),
          ]);

          if (!buyerAddResults || !buyerDeleteResults) {
            throw new Error("Failed to save locations");
          }

          const failedRelations = [
            ...buyerAddResults.failedRelations,
            ...buyerDeleteResults.failedRelations,
          ];

          if (failedRelations.length > 0) {
            toast({
              title: "Error",
              description: "Failed to save some customer locations",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Success",
              description: "Saved customer locations",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          }
        } catch (error: unknown) {
          toast({
            title: "Error",
            description: (error as Error).message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Account</ModalHeader>
        <ModalBody>
          <FormControl marginBottom={4}>
            <FormLabel>Account Type</FormLabel>
            <RadioGroup onChange={handleSetIsIndividual} value={radioValue}>
              <Stack spacing={5} direction="row">
                <Radio colorScheme="teal" value="org">
                  Business/Organization
                </Radio>
                <Radio colorScheme="teal" value="individual">
                  Individual/Consumer
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>
              {isIndividual ? "Customer" : "Organization"} Name
            </FormLabel>
            {isIndividual ? (
              <Input
                placeholder="Enter Customer Name"
                onChange={(e) => setSelectedOrganisationName(e.target.value)}
              />
            ) : (
              <OrganisationSearchOrCreate
                setOrganisationId={setSelectedOrganisationId}
                setOrganisationName={setSelectedOrganisationName}
              />
            )}
          </FormControl>
          {selectedOrganisationName && (
            <>
              <FormControl>
                <FormLabel marginTop={4}>
                  {isIndividual ? "Delivery Addresses" : "Business Locations"}
                </FormLabel>
                {selectedOrganisationId &&
                  selectedOrganisationLocations &&
                  selectedOrganisationLocations?.map((buyer) => (
                    <HStack key={buyer.id} paddingX={8} paddingY={4}>
                      <Text>
                        {buyer.name && buyer.name.length > 0
                          ? buyer.name
                          : buyer.address?.street_1 || ""}
                      </Text>
                      <Spacer />
                      <Checkbox
                        isChecked={currentBuyerLocationIds.has(
                          buyer.id.toString(),
                        )}
                        onChange={() =>
                          handleSelectExistingLocation(buyer.id.toString())
                        }
                      />
                    </HStack>
                  ))}
                {!showAddLocation && (
                  <Button
                    marginLeft={8}
                    onClick={() => setShowAddLocation(true)}
                  >
                    + Add {isIndividual ? "Delivery Address" : "Location"}
                  </Button>
                )}
              </FormControl>
              {showAddLocation && (
                <>
                  <Text m={4}>Location Details:</Text>
                  <APIProvider
                    apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
                  >
                    <Map
                      defaultZoom={13}
                      defaultCenter={{ lat: 49.246292, lng: -123.116226 }}
                    ></Map>
                    <GoogleMapsAutoComplete
                      onPlaceSelect={(place) => {
                        setShowLocationNameInput(true);
                        if (place && place.name && place.address_components) {
                          const name = isIndividual
                            ? ""
                            : getNewLocationName(place.name);
                          setNewLocationName(name);
                          setNewLocation({
                            name: name,
                            organisation_id: selectedOrganisationId || 0,
                            address: getAddressFromComponents(
                              place.address_components,
                            ),
                            is_supplier: false,
                            is_producer: false,
                            phone: place.international_phone_number,
                          } as Location);
                        }
                      }}
                    />
                  </APIProvider>
                  {!isIndividual && showLocationNameInput && (
                    <FormControl mt={4} isRequired>
                      <FormLabel>Location Name</FormLabel>
                      <Input
                        defaultValue={newLocationName || ""}
                        onChange={(e) => {
                          setNewLocationName(e.target.value);
                        }}
                      />
                    </FormControl>
                  )}
                  <FormControl isRequired isReadOnly isDisabled>
                    <FormLabel>Street 1</FormLabel>
                    <Input value={newLocation?.address?.street_1 || ""} />
                  </FormControl>
                  <FormControl isReadOnly isDisabled>
                    <FormLabel>Street 2</FormLabel>
                    <Input value={newLocation?.address?.street_2 || ""} />
                  </FormControl>
                  <FormControl isRequired isReadOnly isDisabled>
                    <FormLabel>City</FormLabel>
                    <Input value={newLocation?.address?.city || ""} />
                  </FormControl>
                  <FormControl isRequired isReadOnly isDisabled>
                    <FormLabel>State</FormLabel>
                    <Input value={newLocation?.address?.state || ""} />
                  </FormControl>
                  <FormControl isRequired isReadOnly isDisabled>
                    <FormLabel>Postal Code</FormLabel>
                    <Input value={newLocation?.address?.postal_code || ""} />
                  </FormControl>
                  <FormControl isRequired isReadOnly isDisabled>
                    <FormLabel>Country</FormLabel>
                    <Input value={newLocation?.address?.country || ""} />
                  </FormControl>
                </>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="teal"
            mr={3}
            isDisabled={isSaveDisabled}
            onClick={handleSave}
            isLoading={
              isLoadingPostBuyer ||
              isLoadingDeleteBuyer ||
              isLoadingPostBuyerLocation ||
              isLoadingPostCustomerOrg
            }
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCustomerModal;
