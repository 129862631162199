import country from "country-list-js";
import { Address } from "../types";

export type Province = {
  name: string;
  short: string | null;
  alias: string | null;
};

export type Country = {
  name: string;
  capital: string;
  code: { iso2: string; iso3: string };
  currency: { code: string; symbol: string; decimal: string };
  provinces: Province[];
  region: string;
};

export const getCountryByName = (name: string): Country | undefined => {
  if (["USA", "US", "United States of America"].includes(name.replace(".", "")))
    name = "United States";
  const foundCountry = country.findByName(name);
  if (foundCountry.name === "United States") {
    foundCountry.name = "USA";
  }
  if (!foundCountry) return foundCountry;
  if (foundCountry.name === "Ireland") {
    foundCountry.provinces = [
      { name: "Antrim" },
      { name: "Armagh" },
      { name: "Carlow" },
      { name: "Cavan" },
      { name: "Clare" },
      { name: "Cork" },
      { name: "Derry" },
      { name: "Donegal" },
      { name: "Down" },
      { name: "Dublin" },
      { name: "Fermanagh" },
      { name: "Galway" },
      { name: "Kerry" },
      { name: "Kildare" },
      { name: "Kilkenny" },
      { name: "Laois" },
      { name: "Leitrim" },
      { name: "Limerick" },
      { name: "Longford" },
      { name: "Louth" },
      { name: "Mayo" },
      { name: "Meath" },
      { name: "Monaghan" },
      { name: "Offaly" },
      { name: "Roscommon" },
      { name: "Sligo" },
      { name: "Tipperary" },
      { name: "Tyrone" },
      { name: "Waterford" },
      { name: "Westmeath" },
      { name: "Wexford" },
      { name: "Wicklow" },
    ];
    foundCountry.provinces.forEach((province: Province) => {
      province.short = province.name;
      province.alias = `Co. ${province.name}`;
    });
  }
  return foundCountry;
};

export const findProvince = (
  country: Country,
  provinceName: string,
): Province | undefined => {
  return country.provinces.find(
    (province: Province) =>
      province.name === provinceName ||
      province.short === provinceName ||
      province.alias === provinceName,
  );
};

type AddressParts = {
  street_number?: string;
  route?: string;
  neighborhood?: string;
  postal_town?: string;
  locality?: string;
  sublocality?: string;
  sublocality_level_1?: string;
  administrative_area_level_1?: string;
  postal_code?: string;
  country?: string;
};

export const getAddressFromComponents = (
  addressComponents: google.maps.GeocoderAddressComponent[],
): Address => {
  const address: Address = {
    street_1: "",
    street_2: null,
    city: "",
    state: "",
    postal_code: "",
    country: "",
  };

  const addressParts: AddressParts = {};

  addressComponents.forEach(
    (component: google.maps.GeocoderAddressComponent) => {
      switch (component.types[0]) {
        case "street_number":
          addressParts.street_number = component.long_name;
          break;
        case "route":
          addressParts.route = component.long_name;
          break;
        case "neighborhood":
          addressParts.neighborhood = component.long_name;
          break;
        case "locality":
          addressParts.locality = component.long_name;
          break;
        case "sublocality":
          addressParts.sublocality = component.long_name;
          break;
        case "sublocality_level_1":
          addressParts.sublocality = component.long_name;
          break;
        case "administrative_area_level_1":
          addressParts.administrative_area_level_1 = component.long_name;
          break;
        case "postal_code":
          addressParts.postal_code = component.long_name;
          break;
        case "country":
          addressParts.country = component.long_name;
          address.country = component.long_name;
          break;
      }
    },
  );

  const foundCountry = getCountryByName(
    addressComponents.find(
      (component: google.maps.GeocoderAddressComponent) =>
        component.types[0] === "country",
    )?.long_name ?? "",
  );

  address.street_1 = addressParts.street_number
    ? `${addressParts.street_number} ${addressParts.route}`
    : addressParts.route ?? "";
  address.street_2 =
    foundCountry?.region !== "North America"
      ? addressParts.sublocality ?? addressParts.neighborhood ?? ""
      : "";
  if (address.street_2 === address.street_1) {
    address.street_2 = "";
  }
  if (address.street_2 && address.street_1 === "") {
    address.street_1 = address.street_2;
    address.street_2 = "";
  }
  address.city = addressParts.locality ?? addressParts.postal_town ?? "";
  address.state = addressParts.administrative_area_level_1 ?? "";
  address.postal_code =
    addressParts.postal_code ??
    addressParts.administrative_area_level_1 ??
    addressParts.locality ??
    addressParts.postal_town ??
    "";

  if (foundCountry?.name === "Ireland") {
    if (address.postal_code === "") {
      address.postal_code = address.state;
    }
    if (address.state.includes("County ")) {
      address.state = address.state.split("County ")[1];
    }
    if (address.city === "") {
      address.city = address.state;
    }
    if (address.postal_code.includes("County")) {
      address.postal_code = address.postal_code.replace("County", "Co.");
    }
  }
  if (address.street_1 === "" && addressParts.sublocality_level_1) {
    address.street_1 = addressParts.sublocality_level_1;
  }

  return address;
};
