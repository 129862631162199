import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import AccountsPage from "./routes/AccountsPage";
import ContainersPage from "./routes/ContainersPage";
import Dashboard from "./routes/Dashboard";
import ErrorPage from "./routes/ErrorPage";
import PurchaseOrdersPage from "./routes/PurchaseOrdersPage";
import HarvestsPage from "./routes/HarvestsPage";
import LoginPage from "./routes/LoginPage/LoginPage";
import ProductsPage from "./routes/ProductsPage";
import PricesPage from "./routes/PricesPage";
import PrivateRoute from "./routes/PrivateRoute";
import Root from "./routes/Root";
import SalesOrdersPage from "./routes/SalesOrdersPage";
import SignUpPage from "./routes/SignUpPage";
import StockPage from "./routes/StockPage";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { PiPlant, PiShippingContainer, PiShoppingCart } from "react-icons/pi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { LuBarChart3, LuLineChart } from "react-icons/lu";
import {
  AiOutlineProduct,
  AiOutlineShop,
  AiOutlineSolution,
} from "react-icons/ai";
import { MdOutlineEventNote } from "react-icons/md";
import { RiSeedlingLine } from "react-icons/ri";
import PasswordResetRequestPage from "./routes/PasswordResetRequestPage";
import HomePage from "./routes/PublicSite/HomePage";
import { DemoRequest } from "./routes/PublicSite/DemoRequest";
import { theme } from "./theme";
import EventSalesPage from "./routes/EventSalesPage";
import GrowOpsPage from "./routes/GrowOpsPage.tsx";

posthog.init("phc_Saq4GkpDQ6JmMTpceygK99GNFVb1Iwt21pwdumbeqKh", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "always", // or 'always' to create profiles for anonymous users as well
});

Sentry.init({
  dsn: "https://050639d590782b6bc69bcc2a41598662@o4507488632897536.ingest.us.sentry.io/4507488635781120",
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === "production",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/stocky-ai\.ca/,
    /^https:\/\/stocky-ai\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const routes = [
  {
    name: "index",
    path: "/",
    element: <HomePage />,
    includeInNav: false,
    navName: "",
    navLink: "",
    isPublic: true,
    usePublicSiteNavbar: true,
  },
  {
    name: "requestDemo",
    path: "/request-demo",
    element: <DemoRequest />,
    includeInNav: false,
    navName: "",
    navLink: "",
    isPublic: true,
    usePublicSiteNavbar: true,
  },
  {
    name: "login",
    path: "/login",
    element: <LoginPage />,
    includeInNav: false,
    navName: "",
    navLink: "",
    isPublic: true,
  },
  {
    name: "register",
    path: "/register",
    element: <SignUpPage />,
    includeInNav: false,
    navName: "",
    navLink: "",
    isPublic: true,
  },
  {
    name: "resetPassword",
    path: "/password-reset",
    element: <PasswordResetRequestPage />,
    includeInNav: false,
    navName: "",
    navLink: "",
    isPublic: true,
  },
  {
    name: "dashboard",
    path: "/dashboard",
    element: (
      <PrivateRoute route={"dashboard"}>
        <Dashboard />
      </PrivateRoute>
    ),
    includeInNav: false,
    navName: "Dashboard",
    navLink: "dashboard",
    navIcon: <LuLineChart style={{ width: "1.2rem", height: "1.2rem" }} />,
  },
  {
    name: "grow",
    path: "/grow",
    element: (
      <PrivateRoute route={"grow"} locationFilters={["is_producer"]}>
        <GrowOpsPage />
      </PrivateRoute>
    ),
    includeInNav: true,
    locationFilters: ["is_producer"],
    navName: "Grow",
    navLink: "grow",
    navIcon: <RiSeedlingLine style={{ width: "1.2rem", height: "1.2rem" }} />,
  },
  {
    name: "harvest",
    path: "/harvest",
    element: (
      <PrivateRoute route={"harvest"} locationFilters={["is_producer"]}>
        <HarvestsPage />
      </PrivateRoute>
    ),
    includeInNav: true,
    locationFilters: ["is_producer"],
    navName: "Harvest",
    navLink: "harvest",
    navIcon: <PiPlant style={{ width: "1.2rem", height: "1.2rem" }} />,
  },
  {
    name: "products",
    path: "/products",
    element: (
      <PrivateRoute route={"products"}>
        <ProductsPage />
      </PrivateRoute>
    ),
    includeInNav: true,
    navName: "Products",
    navLink: "products",
    navIcon: <AiOutlineProduct style={{ width: "1.2rem", height: "1.2rem" }} />,
  },
  {
    name: "prices",
    path: "/prices",
    element: (
      <PrivateRoute route={"prices"}>
        <PricesPage />
      </PrivateRoute>
    ),
    includeInNav: true,
    navName: "Prices",
    navLink: "prices",
    navIcon: (
      <LiaFileInvoiceDollarSolid
        style={{ width: "1.2rem", height: "1.2rem" }}
      />
    ),
  },
  {
    name: "stock",
    path: "/stock",
    element: (
      <PrivateRoute route={"stock"}>
        <StockPage />
      </PrivateRoute>
    ),
    includeInNav: true,
    navName: "Stock",
    navLink: "stock",
    navIcon: <LuBarChart3 style={{ width: "1.2rem", height: "1.2rem" }} />,
  },
  {
    name: "sales",
    path: "/sales",
    element: (
      <PrivateRoute route={"sales"}>
        <SalesOrdersPage />
      </PrivateRoute>
    ),
    includeInNav: true,
    locationFilters: ["is_producer", "is_supplier"],
    navName: "Sales",
    navLink: "sales",
    navIcon: <AiOutlineShop style={{ width: "1.2rem", height: "1.2rem" }} />,
  },
  {
    name: "purchases",
    path: "/purchases",
    element: (
      <PrivateRoute route={"purchases"}>
        <PurchaseOrdersPage />
      </PrivateRoute>
    ),
    includeInNav: true,
    navName: "Purchases",
    navLink: "purchases",
    navIcon: <PiShoppingCart style={{ width: "1.2rem", height: "1.2rem" }} />,
  },
  {
    name: "containers",
    path: "/containers",
    element: (
      <PrivateRoute route={"containers"}>
        <ContainersPage />
      </PrivateRoute>
    ),
    includeInNav: true,
    navName: "Containers",
    navLink: "containers",
    navIcon: (
      <PiShippingContainer style={{ width: "1.2rem", height: "1.2rem" }} />
    ),
  },
  {
    name: "eventSales",
    path: "/event-sales",
    element: (
      <PrivateRoute route={"eventSales"} locationFilters={["is_producer"]}>
        <EventSalesPage />
      </PrivateRoute>
    ),
    includeInNav: true,
    navName: "Event Sales",
    navLink: "event-sales",
    navIcon: (
      <MdOutlineEventNote style={{ width: "1.2rem", height: "1.2rem" }} />
    ),
  },
  {
    name: "accounts",
    path: "/accounts",
    element: (
      <PrivateRoute route={"accounts"}>
        <AccountsPage />
      </PrivateRoute>
    ),
    includeInNav: true,
    navName: "Accounts",
    navLink: "accounts",
    navIcon: (
      <AiOutlineSolution style={{ width: "1.2rem", height: "1.2rem" }} />
    ),
  },
];

export const routeAccess: { [index: string]: string[] } = {
  harvest: ["Klippers", "Kootenay", "Mandair"],
  products: ["ALL"],
  sales: ["Klippers", "Jasmine", "Kootenay", "Mandair"],
  purchases: ["ALL", "Klippers", "Jasmine", "Kootenay"],
  prices: ["Jasmine", "Kootenay"],
  containers: ["Jasmine"],
  accounts: ["Klippers", "Jasmine", "Kootenay", "Mandair"],
  eventSales: ["Klippers", "Mandair"],
};

export const PUBLIC_ROUTES = routes.filter((route) => route.isPublic);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: routes,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <RouterProvider router={router} />
        </ChakraProvider>
      </Provider>
    </PostHogProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
