import { Order, InvoiceItem, Invoice, InvoiceBase } from "../../types";
import React, { useEffect, useState } from "react";
import InvoiceSummary from "./InvoiceSummary";
import {
  Heading,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Spacer,
  useToast,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  useDisclosure,
} from "@chakra-ui/react";
import {
  getAddressArray,
  getDiscountPercentage,
  getInvoiceCredit,
  getInvoiceTotal,
  getLocationAndOrgName,
} from "../../utils/invoiceUtils";
import {
  useGetCurrentUserQuery,
  usePostInvoiceMutation,
  usePostInvoicePdfEmailMutation,
  usePutInvoiceMutation,
} from "../../redux/apiSlice";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import * as Sentry from "@sentry/react";
import InvoicePdf from "../PdfPrinter/InvoicePdf";

import { usePDF } from "@react-pdf/renderer";
import { ChevronDownIcon } from "@chakra-ui/icons";
import EmailDetailsModal from "../../components/EmailDetailsModal/EmailDetailsModal";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";

import _ from "lodash";
import { exportQuickbooksInvoiceCSV } from "../InvoiceExporter/Export";

interface InvoiceModalProps {
  order?: Order;
  invoice?: Invoice;
  isOpen: boolean;
  onClose: () => void;
  supplierOrCustomerName: string;
  isSupplierView?: boolean;
}

export default function InvoiceModal({
  order,
  invoice,
  isOpen,
  onClose,
  supplierOrCustomerName,
  isSupplierView = false,
}: InvoiceModalProps): React.ReactElement {
  const toast = useToast();
  const {
    isOpen: isEmailModalOpen,
    onOpen: onEmailModalOpen,
    onClose: onEmailModalClose,
  } = useDisclosure();

  const klippersLogo = new URL(
    "../../assets/images/klippers.png",
    import.meta.url,
  ).href;
  const stockyCart = new URL(
    "../../assets/images/stockyCart.png",
    import.meta.url,
  ).href;

  const { getCurrentOrganisation, getCurrentLocation } = userSlice.selectors;
  const currentOrganisation = useAppSelector(getCurrentOrganisation);
  const currentLocation = useAppSelector(getCurrentLocation);

  const [supplierOrgId, setSupplierOrgId] = useState<number>();
  const [supplierLocationId, setSupplierLocationId] = useState<number>();
  const [buyerOrgId, setBuyerOrgId] = useState<number>();
  const [buyerLocationId, setBuyerLocationId] = useState<number>();

  const [invoiceItems, setInvoiceItems] = useState<InvoiceItem[]>(
    invoice ? invoice.invoice_items : [],
  );
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);
  const [totalCredit, setTotalCredit] = useState<number>(0);
  const [deliveryDate, setDeliveryDate] = useState<string>(
    new Intl.DateTimeFormat("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(Date.now()),
  );
  const [invoiceDate, setInvoiceDate] = useState<string>(
    new Intl.DateTimeFormat("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(Date.now()),
  );
  const [buyerPO, setBuyerPO] = useState<string | undefined>(undefined);
  const [deliveryAddressId, setDeliveryAddressId] = useState<
    number | undefined
  >();
  const [deliveryNote, setDeliveryNote] = useState<string>("");

  const [isPickup, setIsPickup] = useState<boolean>(false);

  const { data: currentUser } = useGetCurrentUserQuery("");

  const [postInvoice, { isLoading: isInvoiceLoading }] =
    usePostInvoiceMutation();
  const [putInvoice, { isLoading: isInvoiceUpdating }] =
    usePutInvoiceMutation();

  const [postInvoiceEmail, { isLoading: isInvoiceEmailLoading }] =
    usePostInvoicePdfEmailMutation();

  const { getUserId, getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const userId = useAppSelector(getUserId);
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const canEditInvoice = !invoice?.id || invoice?.status === "PENDING";

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const handleSaveInvoice = async () => {
    if (!invoice?.id) {
      if (!order && isSupplierView) {
        console.error(
          "Supplier view not supported without order. Address org and location Ids in payload",
        );
        Sentry.captureException(
          "Supplier view for creating invoice not supported without order. Address org and location Ids in payload",
        );
        toast({
          title: "Invoice failed to create.",
          description:
            "Please try again later. Contact support if issues persists.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      const invoicePayload: InvoiceBase = {
        user_id: currentUser?.id || Number(userId),
        location_id: order
          ? order.location_id
          : isSupplierView
          ? 0
          : Number(locationId), // TODO: implement for supplier view w/o order
        organisation_id: order
          ? order.organisation_id
          : isSupplierView
          ? 0
          : Number(organisationId), // TODO: implement for supplier view w/o order
        supplier_location_id: order
          ? order.supplier_location_id
          : isSupplierView
          ? Number(locationId)
          : undefined, // undefined handled in BE
        supplier_organisation_id: order
          ? order.supplier_organisation_id
          : isSupplierView
          ? invoice?.supplier_organisation_id || Number(organisationId)
          : Number(organisationId),
        delivery_date: deliveryDate,
        invoice_items: invoiceItems,
        status: isSupplierView ? "PENDING" : "CONFIRMED",
        total: invoiceTotal,
        credit: totalCredit,
        invoice_date: deliveryDate, // TODO: implement invoice date?
        order_id: order ? order.id : invoice?.order_id,
        buyer_po_number: buyerPO,
        is_pickup: isPickup,
        delivery_address_id: deliveryAddressId,
        delivery_note: deliveryNote,
      };
      postInvoice({ invoice: invoicePayload, isSupplierView })
        .unwrap()
        .then(() => {
          toast({
            title: "Invoice created.",
            description: `You can view this in your purchase invoices list.
              If you have enabled automated emails for this ${
                isSupplierView ? "customer" : "supplier"
              },
              an email has been sent.`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onClose();
        })
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          toast({
            title: "Invoice failed to create.",
            description: "Please try again later.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    } else {
      const invoicePayload: Invoice = {
        ...invoice,
        user_id: currentUser?.id || Number(userId),
        delivery_date: deliveryDate,
        invoice_items: invoiceItems,
        total: invoiceTotal,
        credit: totalCredit,
        invoice_date: deliveryDate, // TODO: implement invoice date?
        order_id: order ? order.id : invoice?.order_id,
        buyer_po_number: buyerPO,
        is_pickup: isPickup,
        delivery_address_id: deliveryAddressId,
        delivery_note: deliveryNote,
      };
      putInvoice(invoicePayload)
        .unwrap()
        .then(() => {
          toast({
            title: "Invoice updated.",
            description: "",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onClose();
        })
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          toast({
            title: "Invoice failed to update.",
            description: "Please try again later.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  useEffect(() => {
    if (invoice) {
      setSupplierOrgId(invoice.supplier_organisation_id);
      setSupplierLocationId(invoice.supplier_location_id);
      setBuyerOrgId(invoice.organisation_id);
      setBuyerLocationId(invoice.location_id);
      setInvoiceItems(invoice.invoice_items);
      setInvoiceTotal(Number(invoice.total));
      setTotalCredit(Number(invoice.credit));
      setDeliveryDate(invoice.delivery_date.slice(0, 10));
      setInvoiceDate(invoice.invoice_date.slice(0, 10));
      setBuyerPO(invoice.buyer_po_number);
      setDeliveryAddressId(
        invoice.delivery_address_id ??
          (order ? order.delivery_address_id : undefined),
      );
      setDeliveryNote(invoice.delivery_note ?? "");
      setIsPickup(invoice.is_pickup);
    } else if (order && order.order_items) {
      setSupplierOrgId(order.supplier_organisation_id);
      setSupplierLocationId(order.supplier_location_id);
      setBuyerOrgId(order.organisation_id);
      setBuyerLocationId(order.location_id);
      const newInvoiceItems: InvoiceItem[] = order.order_items.map(
        (orderItem) => {
          const invoiceItem: InvoiceItem = {
            product_id: orderItem.product_id,
            product: orderItem.product,
            product_unit_id: orderItem.product_unit_id,
            product_unit: orderItem.product_unit,
            requested_quantity: Number(orderItem.quantity),
            invoiced_quantity: Number(orderItem.quantity),
            received_quantity: Number(orderItem.quantity),
            price: orderItem.price,
            discounted_price: orderItem.discounted_price,
            discount_percent: getDiscountPercentage(orderItem),
            total: 0,
            credit: 0,
          };
          invoiceItem.total = getInvoiceTotal(invoiceItem);
          invoiceItem.credit = getInvoiceCredit(invoiceItem);
          return invoiceItem;
        },
      );
      setInvoiceItems(newInvoiceItems);
      setDeliveryDate(order.delivery_date.slice(0, 10));
      setBuyerPO(order.buyer_po_number);
      setDeliveryAddressId(order.delivery_address_id);
      setIsPickup(order.is_pickup);
      setDeliveryNote("");
    }
  }, [order, invoice]);

  const invoicePdf = invoice ? (
    <InvoicePdf
      logo={
        currentOrganisation.name.includes("Klippers")
          ? klippersLogo
          : stockyCart
      }
      title={currentOrganisation.name}
      customerName={
        invoice.organisation_id === invoice.supplier_organisation_id
          ? invoice.location?.name || ""
          : getLocationAndOrgName(invoice.location)
      }
      invoiceNo={invoice?.invoice_number || invoice?.id?.toString() || ""}
      address={getAddressArray(invoice.supplier_location?.address)}
      deliveryAddress={"\n".concat(
        getAddressArray(
          invoice.delivery_address ?? invoice.location?.address,
        ).join("\n"),
      )}
      invoiceDate={invoice.invoice_date}
      invoice={invoice}
      organisation={currentOrganisation}
      supplierPhone={currentLocation.phone}
      supplierEmail={currentLocation.email}
      buyerPONumber={invoice.buyer_po_number}
      isPickup={invoice.is_pickup}
      deliveryNote={invoice.delivery_note}
    />
  ) : undefined;

  const [instance, updateInstance] = usePDF({ document: invoicePdf });

  useEffect(() => {
    const newInvoicePdf = invoice ? (
      <InvoicePdf
        logo={
          currentOrganisation.name.includes("Klippers")
            ? klippersLogo
            : stockyCart
        }
        title={currentOrganisation.name}
        customerName={
          invoice.organisation_id === invoice.supplier_organisation_id
            ? invoice.location?.name || ""
            : getLocationAndOrgName(invoice.location)
        }
        invoiceNo={invoice?.invoice_number || invoice?.id?.toString() || ""}
        address={getAddressArray(invoice.supplier_location?.address)}
        deliveryAddress={"\n".concat(
          getAddressArray(
            invoice.delivery_address ?? invoice.location?.address,
          ).join("\n"),
        )}
        invoiceDate={invoice.invoice_date}
        invoice={invoice}
        organisation={currentOrganisation}
        supplierPhone={currentLocation.phone}
        supplierEmail={currentLocation.email}
        buyerPONumber={invoice.buyer_po_number}
        isPickup={invoice.is_pickup}
        deliveryNote={invoice.delivery_note}
      />
    ) : undefined;
    if (newInvoicePdf) {
      updateInstance(newInvoicePdf);
    }
  }, [invoice]);

  const handleEmailInvoice = async ({
    toEmails,
    ccEmails,
    bccEmails,
  }: {
    toEmails: string[];
    ccEmails: string[];
    bccEmails: string[];
  }) => {
    if (!invoice) {
      Sentry.captureException("Invoice not found");
      toast({
        title: "Email failed to send.",
        description: "Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      onEmailModalClose();
      return;
    }
    if (instance.blob) {
      const reader = new FileReader();
      reader.readAsDataURL(instance.blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        postInvoiceEmail({
          invoiceId: invoice.id,
          blob: base64data,
          toEmails,
          ccEmails,
          bccEmails,
        })
          .unwrap()
          .then(() => {
            toast({
              title: "Email sent.",
              description: "",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onEmailModalClose();
          })
          .catch((error) => {
            try {
              Sentry.captureException(JSON.stringify(error));
            } catch {
              Sentry.captureException(error);
            }
            toast({
              title: "Email failed to send.",
              description: "Please try again later.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          });
      };
    } else {
      Sentry.captureException("PDF blob not found");
    }
  };

  return (
    <Modal
      size={isFullscreen ? "full" : "4xl"}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
    >
      {isEmailModalOpen && (
        <EmailDetailsModal
          isOpen={isEmailModalOpen}
          onClose={onEmailModalClose}
          sendEmail={handleEmailInvoice}
          isSending={isInvoiceEmailLoading}
          defaultToEmails={
            invoice?.location?.email ? [invoice.location.email] : []
          }
          defaultCcEmails={
            invoice?.supplier_location?.email
              ? [invoice.supplier_location.email]
              : []
          }
        />
      )}
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader>
          <HStack>
            <Heading>{invoice ? "" : "New "}Invoice</Heading>
            <Spacer />
            <Button
              onClick={() => setIsFullscreen(!isFullscreen)}
              size="md"
              variant="outline"
              mr={4}
            >
              {isFullscreen ? (
                <AiOutlineFullscreenExit />
              ) : (
                <AiOutlineFullscreen />
              )}
            </Button>
            {invoice && isSupplierView && (
              <Menu>
                <MenuButton ml={2} as={Button} rightIcon={<ChevronDownIcon />}>
                  Actions
                </MenuButton>
                <MenuList textColor={"black"}>
                  <MenuItem onClick={(e) => e.stopPropagation()}>
                    <a
                      href={instance.url || undefined}
                      download={`invoice-${
                        invoice?.invoice_number ||
                        invoice?.id?.toString() ||
                        "print"
                      }.pdf`}
                    >
                      Download PDF
                    </a>
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      onEmailModalOpen();
                    }}
                  >
                    Email PDF
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      exportQuickbooksInvoiceCSV({ data: invoice });
                    }}
                  >
                    Export Quickbooks
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {supplierOrgId &&
            supplierLocationId &&
            buyerOrgId &&
            buyerLocationId && (
              <InvoiceSummary
                supplierOrgId={supplierOrgId}
                supplierLocationId={supplierLocationId}
                buyerOrgId={buyerOrgId}
                buyerLocationId={buyerLocationId}
                invoiceItems={invoiceItems}
                invoiceDate={invoiceDate}
                deliveryDate={deliveryDate}
                invoiceTotal={invoiceTotal}
                totalCredit={totalCredit}
                buyerPONumber={buyerPO}
                deliveryAddressId={deliveryAddressId}
                isPickup={isPickup}
                deliveryNote={deliveryNote}
                setInvoiceItems={setInvoiceItems}
                setInvoiceDate={setInvoiceDate}
                setDeliveryDate={setDeliveryDate}
                setInvoiceTotal={setInvoiceTotal}
                setTotalCredit={setTotalCredit}
                setBuyerPONumber={setBuyerPO}
                setDeliveryAddressId={setDeliveryAddressId}
                setIsPickup={setIsPickup}
                setDeliveryNote={setDeliveryNote}
                isSupplierView={isSupplierView}
                supplierOrCustomerName={supplierOrCustomerName}
                isEditable={canEditInvoice}
              />
            )}
        </ModalBody>
        <ModalFooter>
          {canEditInvoice && (
            <Button
              colorScheme="teal"
              mr={3}
              onClick={handleSaveInvoice}
              isLoading={isInvoiceLoading || isInvoiceUpdating}
            >
              Save
            </Button>
          )}
          <Spacer />
          <Button colorScheme="red" variant={"ghost"} mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
