import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import StatsCard from "../StatsCard/StatsCard";

type KPI = {
  title: string;
  stat: string;
  helpText?: string;
  arrowType?: "increase" | "decrease";
};

interface PageOverviewProps {
  heading: string;
  subheading?: string;
  mainKPI?: KPI;
  secondaryKPI?: KPI;
  stats?: KPI[];
  showDateRangePicker?: boolean;
  endElement?: React.ReactNode;
}

function PageOverview({
  heading,
  subheading,
  mainKPI,
  secondaryKPI,
  stats,
  showDateRangePicker,
  endElement,
}: PageOverviewProps) {
  return (
    <Flex
      gap={4}
      w={"100%"}
      marginTop={8}
      textColor={"rgba(255,255,255,0.87)"}
      paddingX={8}
    >
      <Box>
        <Heading as={"h2"} mb={2}>
          {heading}
        </Heading>
        {showDateRangePicker ? (
          <DateRangePicker />
        ) : subheading ? (
          <Text fontSize={"24px"}>{subheading}</Text>
        ) : (
          <Box my={7} />
        )}
      </Box>
      {mainKPI && (
        <Flex flexDirection={"column"}>
          <Text fontSize={"4xl"} fontWeight={"bold"}>
            {mainKPI.stat}
          </Text>
          <Box fontSize={"sm"}>{mainKPI.helpText}</Box>
        </Flex>
      )}
      {secondaryKPI && (
        <Flex flexDirection={"column"}>
          <Text fontSize={"4xl"} fontWeight={"bold"}>
            {secondaryKPI.stat}
          </Text>
          <Box fontSize={"sm"}>{secondaryKPI.helpText}</Box>
        </Flex>
      )}
      {stats &&
        stats.map(({ title, stat, helpText, arrowType }) => (
          <StatsCard
            key={title + stat + helpText}
            title={title}
            stat={stat}
            helpText={helpText}
            arrowType={arrowType}
          />
        ))}
      {endElement && <Box ml="auto">{endElement}</Box>}
    </Flex>
  );
}

export default PageOverview;
