import {
  HStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "../Autocomplete";
import * as React from "react";
import { GrowArea } from "../../types";
import { useGetGrowAreasQuery } from "../../redux/apiSlice";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import { RiDeleteBack2Line } from "react-icons/ri";

interface GrowAreaSearchOrCreateProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  setGrowAreaId: (id: number | null | undefined) => void;
  setGrowAreaName?: (name: string) => void;
  includeSearchIcon?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const GrowAreaSearchOrCreate = ({
  searchTerm,
  setSearchTerm,
  setGrowAreaId,
  setGrowAreaName = () => {},
  includeSearchIcon = false,
  onKeyDown,
}: GrowAreaSearchOrCreateProps): React.ReactElement => {
  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const {
    data: growAreas = [],
    error: growAreasError,
    isLoading: growAreasLoading,
  } = useGetGrowAreasQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const [isHoveringDelete, setIsHoveringDelete] =
    React.useState<boolean>(false);

  const clearSearch = () => {
    setGrowAreaId(undefined);
    setGrowAreaName("");
    setSearchTerm("");
  };

  return (
    <AutoComplete openOnFocus isLoading={growAreasLoading}>
      <HStack>
        <InputGroup>
          {includeSearchIcon && (
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
            >
              <SearchIcon />
            </InputLeftElement>
          )}
          <AutoCompleteInput
            onKeyDown={onKeyDown}
            onItemSelect={(value) => {
              setSearchTerm(value);
              if (!growAreas) return;
              const growArea = growAreas.find(
                (growArea) => growArea.name === value,
              );
              setGrowAreaId(growArea?.id);
              setGrowAreaName(value);
            }}
            placeholder={includeSearchIcon ? "Search for grow area" : ""}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value.length === 0) {
                clearSearch();
              }
            }}
            // TODO: improve UI by enabling selection with key presses
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     // @ts-ignore
            //     console.log("filtering", e.target.value);
            //     // @ts-ignore
            //     setProductFilter(Object.keys(e.target.value));
            //   }
            // }}
          />
          <InputRightElement>
            <RiDeleteBack2Line
              style={{ cursor: "pointer", opacity: isHoveringDelete ? 1 : 0.5 }}
              onClick={clearSearch}
              onMouseEnter={() => setIsHoveringDelete(true)}
              onMouseLeave={() => setIsHoveringDelete(false)}
            />
          </InputRightElement>
        </InputGroup>
        <AutoCompleteList>
          {growAreas.map((growArea: GrowArea) => (
            <AutoCompleteItem
              id={`option-${growArea.id}`}
              key={`option-${growArea.id}`}
              value={growArea.name}
              textTransform="capitalize"
              onClick={() => {
                setSearchTerm(growArea.name);
                setGrowAreaId(growArea.id);
                setGrowAreaName(growArea.name);
              }}
            >
              {growArea.name}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </HStack>
    </AutoComplete>
  );
};

export default GrowAreaSearchOrCreate;
