import { Badge, Checkbox, Td, useDisclosure } from "@chakra-ui/react";
import * as React from "react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import {
  formatCurrency,
  getInvoiceStatusColor,
  getInvoiceStatusLabel,
} from "../../utils/invoiceUtils";
import { AccountInfo } from "./types";
import AvatarEntry from "../../components/AvatarEntry/AvatarEntry";

interface AccountsViewEntryProps {
  index: number;
  accountInfo: AccountInfo;
  isSelected: boolean;
  onSelect: () => void;
}
export default function AccountsViewEntry({
  index,
  accountInfo,
  isSelected,
  onSelect,
}: AccountsViewEntryProps): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <InteractiveRow itemIndex={index} onClick={isOpen ? onClose : onOpen}>
        <Td>
          <Checkbox isChecked={isSelected} onChange={onSelect} />
        </Td>
        <Td fontSize="sm">
          <AvatarEntry name={accountInfo.name} />
        </Td>
        <Td fontSize="sm">${formatCurrency(accountInfo.amountOwed)}</Td>
        <Td fontSize="sm">${formatCurrency(accountInfo.creditAmount)}</Td>
        <Td fontSize="sm">${formatCurrency(accountInfo.amountPaid)}</Td>
        <Td fontSize="sm">${formatCurrency(accountInfo.balance)}</Td>
      </InteractiveRow>
      {isOpen && (
        <>
          {accountInfo.accountEntries.map((entry, entryIndex) => {
            return (
              <InteractiveRow key={index} itemIndex={`${index}-${entryIndex}`}>
                <Td />
                <Td fontSize="sm" pl={16} fontWeight={600}>
                  {entry.name}{" "}
                  {entry.isInvoice && (
                    <Badge
                      colorScheme={getInvoiceStatusColor(entry.status, true)}
                    >
                      {getInvoiceStatusLabel(entry.status, true)}
                    </Badge>
                  )}
                </Td>
                <Td fontSize="sm" fontWeight={600}>
                  ${formatCurrency(entry.amountOwed)}
                </Td>
                <Td fontSize="sm" fontWeight={600}>
                  ${formatCurrency(entry.creditAmount)}
                </Td>
                <Td fontSize="sm" fontWeight={600}>
                  {entry.isInvoice ? "(" : ""}$
                  {formatCurrency(entry.amountPaid)}
                  {entry.isInvoice ? ")" : ""}
                </Td>
                <Td fontSize="sm" fontWeight={600}>
                  ${formatCurrency(entry.balance)}
                </Td>
              </InteractiveRow>
            );
          })}
        </>
      )}
    </>
  );
}
