import React, { useEffect, useState } from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import { useAppSelector } from "../redux/hooks";
import { userSlice } from "../redux/userSlice";
import {
  useGetInventoryItemsQuery,
  useGetPricesQuery,
  useGetProductsQuery,
  useGetProductUnitsQuery,
} from "../redux/apiSlice";
import { Badge, HStack, Td, useDisclosure } from "@chakra-ui/react";
import DataTabs from "../components/DataTabs/DataTabs";
import { Product } from "../types";
import { getProductUnitLabel } from "../utils/productUnitUtils";
import ProductViewEntry from "../feature/ProductView/ProductViewEntry";
import AddProductModal from "../feature/ProductView/AddProductModal";
import InventoryProductSearch from "../components/InventoryProductSearch/InventoryProductSearch";
import Page from "../components/Page/Page";
import { skipToken } from "@reduxjs/toolkit/query";

export const badgeColors = [
  "blue",
  "green",
  "orange",
  "purple",
  "teal",
  "pink",
  "red",
  "cyan",
];

function ProductsPage(): React.ReactElement {
  const { getCurrentOrganisationId, getCurrentOrganisation } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const organisation = useAppSelector(getCurrentOrganisation);

  const [productList, setProductList] = useState<Product[]>([]);
  const [productFilterId, setProductFilterId] = useState<
    number | null | undefined
  >(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [inventoryUnitIds, setInventoryUnitIds] = useState<Set<number>>(
    new Set(),
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data: inventoryItems,
    isLoading: isLoadingInventoryItems,
    error: errorInventoryItems,
  } = useGetInventoryItemsQuery({
    organisationId: (organisationId || organisation?.id) ?? skipToken,
  });

  const {
    data: productUnits,
    isLoading: isLoadingProductUnits,
    error: errorProductUnits,
  } = useGetProductUnitsQuery("");
  const {
    data: products,
    isLoading: isLoadingProducts,
    error: errorProducts,
  } = useGetProductsQuery("");

  const {
    data: prices,
    isLoading: isLoadingPrices,
    error: errorPrices,
  } = useGetPricesQuery(organisationId || organisation?.id, {
    skip: !organisationId || !organisation,
  });

  useEffect(() => {
    if (
      inventoryItems &&
      inventoryItems.length > 0 &&
      products &&
      products.length > 0
    ) {
      const productIds = new Set(inventoryItems.map((item) => item.product_id));
      setProductList(
        products
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((product) => productIds.has(product.id)),
      );
      setInventoryUnitIds(
        new Set(inventoryItems.map((item) => item.product_unit_id)),
      );
    }
  }, [inventoryItems, products]);

  useEffect(() => {
    if (inventoryItems && productUnits && prices && products) {
      const productIds = new Set(inventoryItems.map((item) => item.product_id));
      let filteredProducts = products
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((product) => productIds.has(product.id));

      if (productFilterId) {
        filteredProducts = filteredProducts.filter(
          (product) => product.id === productFilterId,
        );
      }
      setProductList(filteredProducts);
    }
  }, [inventoryItems, productUnits, productFilterId]);

  return (
    <>
      <PageOverview heading={"Products"} subheading={""} />
      <Page
        isLoading={
          isLoadingInventoryItems ||
          isLoadingProductUnits ||
          isLoadingPrices ||
          isLoadingProducts
        }
        isError={Boolean(
          errorInventoryItems ||
            errorProductUnits ||
            errorPrices ||
            errorProducts,
        )}
      >
        <HStack>
          <InventoryProductSearch
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setProductFilterId={setProductFilterId}
            includeSearchIcon
          />
        </HStack>
        <AddProductModal isOpen={isOpen} onClose={onClose} />
        <DataTabs
          showButton={true}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          data={[
            {
              label: "Products",
              headers: ["Name", "Units"],
              entries: productList
                ? productList.map((product, index) => (
                    <ProductViewEntry
                      key={index}
                      itemIndex={index}
                      product={product}
                      inventoryUnitIds={inventoryUnitIds}
                    >
                      <Td fontSize="sm">{product.name}</Td>
                      <Td fontSize="sm">
                        {product.product_units.map((unit, unitIndex) => {
                          if (inventoryUnitIds.has(unit.id)) {
                            return (
                              <Badge
                                colorScheme={badgeColors[unitIndex % 8]}
                                fontSize="0.8em"
                                key={unit.id}
                                marginRight="0.5rem"
                              >
                                {getProductUnitLabel(unit)}
                              </Badge>
                            );
                          }
                        })}
                      </Td>
                    </ProductViewEntry>
                  ))
                : [],
            },
          ]}
        />
        {products && products.length === 0 && (
          <div>
            Issue loading products! Please refresh and contact support if issue
            persists.
          </div>
        )}
        {inventoryItems && inventoryItems.length === 0 && (
          <div>No products found! Add a product to get started.</div>
        )}
      </Page>
    </>
  );
}

export default ProductsPage;
