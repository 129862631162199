import _ from "lodash";
import { MappedValue, SpreadSheetMapping } from "./MappingDefinition";

function getMappedValue(
  data: object,
  mapping: SpreadSheetMapping,
): MappedValue {
  let value;

  if (mapping.key_in_data) {
    // https://lodash.com/docs#get
    value = _.get(data, mapping.key_in_data);
  }

  // if no key given, set value to default
  if (mapping.key_in_data === null && mapping.default) {
    value = mapping.default;
  }

  // if there's no value at this point the mapping hasn't been set up properly
  if (!value) {
    throw new Error(
      "check data mapping settings - missing `key_in_data` or `default`",
    );
  }

  // apply transform
  if (mapping.transform) {
    value = mapping.transform(value);
  }

  return value;
}

export function processMappings({
  data,
  mappings,
}: {
  data: object;
  mappings: SpreadSheetMapping[];
}) {
  // split mappings between general (customer, date, etc.) and item specific

  const generalInvoiceFields = mappings.filter(
    (mapping) => !mapping.multiple_items_key,
  );

  const lineItems = mappings.filter((mapping) => mapping.multiple_items_key);

  // make sure columns end up in the right order by putting all item related fields at the back

  const columnNames = generalInvoiceFields
    .map((mapping) => mapping.column_name)
    .concat(lineItems.map((mapping) => mapping.column_name));

  const invoiceGeneralInfo = generalInvoiceFields.map((mapping) =>
    getMappedValue(data, mapping),
  );

  // ensure there is only one items list referred to in the mapping

  const multipleItemsKeys: (_.PropertyPath | undefined)[] = Array.from(
    new Set(lineItems.map((mapping) => mapping.multiple_items_key)),
  );

  if (multipleItemsKeys.length !== 1) {
    throw new Error(
      "Check mappings and make sure there is only one unique `multiple_items_key` between mappings",
    );
  } else if (multipleItemsKeys.length === 1) {
    const [items_key] = multipleItemsKeys;

    const itemsFromData = items_key ? _.get(data, items_key) : [];

    const invoiceLineItemMappings = lineItems.filter(
      (mapping) => mapping.multiple_items_key == items_key,
    );

    const partialItemLines = itemsFromData.map((item: object) => {
      return invoiceLineItemMappings.map((mapping) =>
        getMappedValue(item, mapping),
      );
    });

    let finalData = partialItemLines.map((line: [MappedValue]) => {
      const finalLine = [...invoiceGeneralInfo, ...line];
      return finalLine;
    });

    finalData.unshift(columnNames);

    return finalData;
  }
}
