import { ProductUnit, ProductUnitBase } from "../../types.ts";

export type ProductUnitSettings = ProductUnit & {
  is_new_unit: boolean;
  in_inventory: boolean;
};

const getUnitSettings = (
  productUnit: ProductUnit,
  inventoryUnitIds: Set<number>,
): ProductUnitSettings => {
  return {
    ...productUnit,
    is_new_unit: Boolean(!productUnit.id),
    in_inventory: productUnit.id ? inventoryUnitIds.has(productUnit.id) : true,
  } as ProductUnitSettings;
};

export class ProductUnitMgr {
  public newProductUnits: ProductUnitBase[] = [];
  public productUnits: ProductUnitSettings[] = [];
  public inventoryUnitIds: Set<number>;
  public productUnitIndex: Map<string, number> = new Map(); // Map of product unit hash to index in productUnits

  constructor(productUnits: ProductUnit[], inventoryUnitIds: Set<number>) {
    this.productUnits = productUnits.map((productUnit) =>
      getUnitSettings(productUnit, inventoryUnitIds),
    );
    this.inventoryUnitIds = inventoryUnitIds;
    this.updateProductUnitIndex();
  }

  private getProductUnitHash(
    productUnit: ProductUnit | ProductUnitBase,
  ): string {
    return `${productUnit.unit_label} (${productUnit.unit_quantity}x${productUnit.unit_measure})`;
  }

  private updateProductUnitIndex(): void {
    this.productUnitIndex = new Map(
      this.productUnits.map((productUnit, index) => [
        this.getProductUnitHash(productUnit),
        index,
      ]),
    );
  }

  public addNewProductUnit(productUnit: ProductUnitBase): void {
    if (this.productUnitIndex.has(this.getProductUnitHash(productUnit))) {
      throw new Error("Duplicate product unit");
    }
    this.newProductUnits.push(productUnit);
    this.productUnits.push(
      getUnitSettings(productUnit as ProductUnit, new Set()),
    );
    this.updateProductUnitIndex();
  }

  public removeProductUnit(productUnit: ProductUnitSettings): void {
    if (productUnit.is_new_unit) {
      const index = this.newProductUnits.indexOf(productUnit);
      if (index > -1) {
        this.newProductUnits.splice(index, 1);
      }
    }
    this.updateProductUnitIndex();
  }

  public toggleProductUnitInventory(
    productUnit: ProductUnitSettings,
    value: boolean,
  ): void {
    const index = this.productUnits.indexOf(productUnit);
    if (index > -1) {
      this.productUnits[index].in_inventory = value;
    }
  }

  public getNewInventoryUnitIds(): number[] {
    const newInventoryUnitIds: number[] = [];
    this.productUnits.forEach((productUnit) => {
      if (
        !productUnit.is_new_unit &&
        productUnit.in_inventory &&
        !this.inventoryUnitIds.has(productUnit.id)
      ) {
        newInventoryUnitIds.push(productUnit.id);
      }
    });
    return newInventoryUnitIds;
  }

  public getRemovedInventoryUnitIds(): number[] {
    const removedInventoryUnitIds: number[] = [];
    this.productUnits.forEach((productUnit) => {
      if (
        !productUnit.is_new_unit &&
        !productUnit.in_inventory &&
        this.inventoryUnitIds.has(productUnit.id)
      ) {
        removedInventoryUnitIds.push(productUnit.id);
      }
    });
    return removedInventoryUnitIds;
  }
}
