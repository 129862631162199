import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import StackEntry from "../../components/StackEntry/StackEntry";
import { GrowArea } from "../../types";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { userSlice } from "../../redux/userSlice.ts";
import { useAppSelector } from "../../redux/hooks.ts";
import { usePostGrowAreaMutation } from "../../redux/apiSlice.ts";

interface GrowAreaDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  growArea?: GrowArea;
}

const GrowAreaDetailModal = ({
  isOpen,
  onClose,
  growArea,
}: GrowAreaDetailModalProps): React.ReactElement => {
  const toast = useToast();
  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const [postGrowArea, { isLoading }] = usePostGrowAreaMutation();

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const [name, setName] = useState<string>(growArea?.name || "");
  const [noSubAreas, setNoSubAreas] = useState<number>(
    growArea?.sub_areas?.length || 0,
  );
  const [subAreaLabel, setSubAreaLabel] = useState<string>(
    growArea?.sub_area_label || "",
  );

  useEffect(() => {
    setName(growArea?.name || "");
  }, [growArea]);

  const handleSave = () => {
    const payload = {
      location_id: Number(locationId),
      organisation_id: Number(organisationId),
      name,
      parent_grow_area_id: null,
    } as GrowArea;
    postGrowArea(payload)
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Grow Area created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch((error) => {
        toast({
          title: "Error - failed to save grow area",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isFullscreen ? "full" : "5xl"}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent height="100vh" margin={{ base: 0, md: "auto" }}>
        <ModalCloseButton />
        <ModalHeader padding={{ base: 4, md: 6 }}>
          <Flex alignItems="center">
            <Heading>Grow Area: {name}</Heading>
            <Spacer />
            <Button
              onClick={() => setIsFullscreen(!isFullscreen)}
              size="md"
              variant="outline"
              mr={8}
            >
              {isFullscreen ? (
                <AiOutlineFullscreenExit />
              ) : (
                <AiOutlineFullscreen />
              )}
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody
          flex="1"
          overflow="auto"
          display="flex"
          flexDirection="column"
          px={{ base: 4, md: 6 }}
        >
          <Box pt={4}>
            <Flex direction={["column", "row"]} gap={4}>
              <StackEntry mb={8}>
                <FormLabel>Grow Area Name</FormLabel>
                <Input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </StackEntry>
            </Flex>
          </Box>
          <Box pt={4}>
            <Flex direction={["column", "row"]} gap={4}>
              <StackEntry mb={8}>
                <FormLabel>No. of Sub Grow Areas</FormLabel>
                <Input
                  type="number"
                  value={noSubAreas}
                  onChange={(e) => setNoSubAreas(Number(e.target.value))}
                />
              </StackEntry>
              <StackEntry mb={8}>
                <FormLabel>Sub Grow Areas Label/Prefix</FormLabel>
                <Input
                  type="text"
                  value={subAreaLabel}
                  onChange={(e) => setSubAreaLabel(e.target.value)}
                />
              </StackEntry>
            </Flex>
          </Box>
          {subAreaLabel && subAreaLabel.length > 0 && noSubAreas > 0 && (
            <Box pt={4} maxW="600px">
              <Heading size="md">Sub Grow Areas:</Heading>
              <Flex flexWrap={"wrap"} direction={["column", "row"]} gap={4}>
                {new Array(noSubAreas).fill(0).map((_, index) => (
                  <Box minW="200px" key={index} pt={4} outline={"groove"}>
                    <Heading size="md">
                      {subAreaLabel}
                      {index + 1}
                    </Heading>
                  </Box>
                ))}
              </Flex>
            </Box>
          )}
        </ModalBody>
        <ModalFooter flexDirection="column" width="100%" px={4} py={6}>
          <Button
            colorScheme="teal"
            onClick={handleSave}
            isLoading={isLoading}
            isDisabled={Boolean(!name || (noSubAreas && !subAreaLabel))}
            flex={{ base: 1, sm: "0 1 auto" }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GrowAreaDetailModal;
