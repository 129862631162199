export const capitalizeFirstLetter = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const capitalizeWords = (word: string): string => {
  return word
    .split(" ")
    .map((s) => capitalizeFirstLetter(s))
    .join(" ");
};
