import { Td, useDisclosure } from "@chakra-ui/react";
import React from "react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import { Transplant } from "../../types";
import TransplantDetailModal from "./TransplantDetailModal.tsx";

interface TransplantViewEntryProps {
  index: number;
  transplant: Transplant;
}

export default function TransplantViewEntry({
  index,
  transplant,
}: TransplantViewEntryProps): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {isOpen && <TransplantDetailModal isOpen={isOpen} onClose={onClose} />}
      <InteractiveRow itemIndex={index} onClick={isOpen ? onClose : onOpen}>
        <Td fontSize="sm">{transplant.transplant_date.slice(0, 10)}</Td>
        <Td fontSize="sm">{transplant.product?.name || "Lettuce - Romaine"}</Td>
        <Td fontSize="sm">{transplant.quantity}</Td>
        <Td fontSize="sm">
          {transplant.from_grow_area?.name || transplant.from_grow_area_id}
        </Td>
        <Td fontSize="sm">
          {transplant.to_grow_area?.name || transplant.to_grow_area_id}
        </Td>
      </InteractiveRow>
    </>
  );
}
