import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import FileUpload from "../../components/FileUpload/FileUpload.tsx";
import DataTable from "../../components/DataTable/DataTable.tsx";
import EditableColumn from "../../components/DataTable/EditableColumn.tsx";
import { createColumnHelper } from "@tanstack/react-table";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { useGetProductsQuery } from "../../redux/apiSlice.ts";
import Fuse from "fuse.js";
import { Product, ProductUnit } from "../../types.ts";
import {
  processProductName,
  removeProductStopwords,
  removeTrailingS,
} from "../../utils/nlpUtils.ts";

interface ImportPricesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type PriceImport = {
  product_id?: number;
  product_unit_id?: number;
  product?: Product;
  suggested_product?: Product;
  confident_match?: boolean;
  product_unit?: ProductUnit;
  supplier_product_name: string;
  certificates?: string;
  certifying_body?: string;
  unit_quantity: number;
  price: number;
  price_per_base_unit: number;
  origin: string;
  producer_name: string;
};

const ImportPricesModal = ({
  isOpen,
  onClose,
}: ImportPricesModalProps): React.ReactElement => {
  const toast = useToast();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(true);
  const [parsedData, setParsedData] = useState<PriceImport[]>([]);
  const [errorRows, setErrorRows] = useState<string[][]>([]);
  const [baseUnit, setBaseUnit] = useState<string>("kg");
  const enableEdit = false;
  const [parsingComplete, setParsingComplete] = useState<boolean>(false);
  const [isMatchingProducts, setIsMatchingProducts] = useState<boolean>(false);
  const [fuse, setFuse] = useState<Fuse<Product> | null>(null);

  const { data: products } = useGetProductsQuery("");

  useEffect(() => {
    if (products) {
      setIsMatchingProducts(true);
      setFuse(
        new Fuse(products, {
          keys: ["name"],
        }),
      );
      const dataCopy: PriceImport[] = [];
      if (parsedData && parsedData.length) {
        for (const row of parsedData) {
          let supplierName: string | string[] = row.supplier_product_name;
          if (!supplierName || !supplierName.length) {
            continue;
          }
          supplierName = removeProductStopwords(
            processProductName(supplierName),
          );
          const match = fuse?.search(supplierName);
          if (match && match.length) {
            row.suggested_product = match[0].item;
            if (
              removeTrailingS(supplierName) ===
              removeTrailingS(processProductName(match[0].item.name))
            ) {
              row.confident_match = true;
            }
          }
          dataCopy.push(row);
        }
        setParsedData(dataCopy);
        setIsMatchingProducts(false);
      }
    }
  }, [products, parsingComplete]);

  const columnHelper = createColumnHelper<PriceImport>();

  const columns = [
    columnHelper.accessor("supplier_product_name", {
      cell: (info) => (
        <EditableColumn
          inputType={"text"}
          value={info.getValue()}
          setValue={(value: string | number) => {
            // handleDescriptionChange(info.row.index, value);
          }}
          width={"300px"}
          isEditable={enableEdit}
        />
      ),
      header: "Description",
    }),
    columnHelper.accessor("suggested_product", {
      cell: (info) => (
        <>
          {isMatchingProducts ? (
            <Spinner />
          ) : (
            <Text
              color={
                info.row.original.confident_match ? "green.500" : "red.500"
              }
            >
              {info.getValue()?.name || "No match found"}
            </Text>
          )}
        </>
      ),
      header: "Product",
    }),
    columnHelper.accessor("unit_quantity", {
      cell: (info) => (
        <EditableColumn
          value={Number(info.getValue()).toFixed(2)}
          setValue={(value: string | number) => {
            // handleCostChange(info.row.index, Number(value));
          }}
          width={"100%"}
          isEditable={enableEdit}
        />
      ),
      header: "Weight",
      meta: {
        isNumeric: true,
      },
    }),
    columnHelper.accessor("price", {
      cell: (info) => (
        <EditableColumn
          value={Number(info.getValue()).toFixed(2)}
          setValue={(value: string | number) => {
            // handleCostChange(info.row.index, Number(value));
          }}
          label={"$"}
          width={"60px"}
          isEditable={enableEdit}
        />
      ),
      header: "Price",
      meta: {
        isNumeric: true,
        isCurrency: true,
      },
    }),
    columnHelper.accessor("price_per_base_unit", {
      cell: (info) => (
        <EditableColumn
          value={Number(info.getValue()).toFixed(2)}
          setValue={(value: string | number) => {
            // handleCostChange(info.row.index, Number(value));
          }}
          label={"$"}
          width={"60px"}
          isEditable={enableEdit}
        />
      ),
      header: `Price per ${baseUnit}`,
      meta: {
        isNumeric: true,
        isCurrency: true,
      },
    }),
    columnHelper.accessor("origin", {
      cell: (info) => <Text w={"100px"}>{info.getValue() ?? ""}</Text>,
      header: "Origin",
    }),
    columnHelper.accessor("producer_name", {
      cell: (info) => <Text w={"120px"}>{info.getValue() ?? ""}</Text>,
      header: "Producer",
    }),
    columnHelper.accessor("certificates", {
      cell: (info) => <Text w={"40px"}>{info.getValue() ?? ""}</Text>,
      header: "Certs",
    }),
    columnHelper.accessor("certifying_body", {
      cell: (info) => info.getValue() ?? "",
      header: "Cert Body",
    }),
  ];

  useEffect(() => {
    if (parsingComplete) {
      if (!parsedData || !parsedData.length) {
        toast({
          title: "File failed to parse.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else if (errorRows.length > 0) {
        toast({
          title: "Some rows could not be parsed.",
          description: "Please check the following rows and try again.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        console.log("Error rows: ", errorRows);
      } else {
        toast({
          title: "File parsed successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [parsingComplete, errorRows, parsedData]);

  const skipRow = (data: string[]) => {
    return (
      data.length < 2 ||
      data[1].trim() === "CERTS" ||
      data[2].trim() === "WT" ||
      data[3].trim() === "PRICE" ||
      (isNaN(Number(data[2].trim())) && isNaN(Number(data[3].trim())))
    );
  };

  const handleFileUpload = (file: File | null) => {
    setParsedData([]);
    setErrorRows([]);

    let rowCount = 0;
    if (!file) {
      return;
    }
    Papa.parse(file, {
      step: (results: { data: string[] }) => {
        if (!results || !results.data || results.data.length < 2) {
          return;
        }
        if (rowCount < 7) {
          rowCount++;
          return;
        }
        if (skipRow(results.data)) {
          // setErrorRows((prevRows) => [...prevRows, results.data]);
          return; // Skip the header row and any other rows that don't contain the expected data
        }
        const formattedResults = results.data.map((item: string) =>
          item.trim().replace("  ", " "),
        );
        const unitQuantity = Number(formattedResults[2]);
        const unitPrice = Number(formattedResults[3]);
        setParsedData((prevData) => {
          const newPrice: PriceImport = {
            supplier_product_name: formattedResults[0],
            unit_quantity: unitQuantity,
            price: unitPrice,
            price_per_base_unit: unitPrice / unitQuantity,
            origin: formattedResults[4],
            producer_name: formattedResults[5],
            certificates: formattedResults[1],
            certifying_body: formattedResults[6],
          };
          return [...prevData, newPrice];
        });
      },
      complete: () => {
        setParsingComplete(true);
      },
      error: () => {
        toast({
          title: "Failed to parse file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size={isFullscreen ? "full" : "5xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex alignItems="center">
            <Heading>Import Supplier Prices</Heading>
            <Spacer />
            <Button
              onClick={() => setIsFullscreen(!isFullscreen)}
              size="md"
              variant="outline"
              mr={8}
            >
              {isFullscreen ? (
                <AiOutlineFullscreenExit />
              ) : (
                <AiOutlineFullscreen />
              )}
            </Button>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FileUpload onFileRead={(file) => handleFileUpload(file)} />
          {parsedData.length > 0 && (
            <Box mt={4}>
              <DataTable data={parsedData} columns={columns} />
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImportPricesModal;
