import React from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import { useAppSelector } from "../redux/hooks";
import { userSlice } from "../redux/userSlice";
import {
  useGetGrowAreasQuery,
  useGetSeedRecordsQuery,
  useGetTransplantsQuery,
} from "../redux/apiSlice";
import Page from "../components/Page/Page";
import DataTabs from "../components/DataTabs/DataTabs";
import { useDisclosure } from "@chakra-ui/react";
import GrowAreaViewEntry from "../feature/GrowOpsView/GrowAreaViewEntry.tsx";
import GrowAreaDetailModal from "../feature/GrowOpsView/GrowAreaDetailModal.tsx";
import TransplantViewEntry from "../feature/GrowOpsView/TransplantViewEntry.tsx";
import TransplantDetailModal from "../feature/GrowOpsView/TransplantDetailModal.tsx";
import SeedRecordDetailModal from "../feature/GrowOpsView/SeedRecordDetailModal.tsx";
import SeedRecordViewEntry from "../feature/GrowOpsView/SeedRecordViewEntry.tsx";

function GrowOpsPage(): React.ReactElement {
  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const {
    data: growAreas,
    error: growAreasError,
    isLoading: growAreasLoading,
  } = useGetGrowAreasQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const {
    data: seedRecords,
    error: seedRecordsError,
    isLoading: seedRecordsLoading,
  } = useGetSeedRecordsQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const {
    data: transplants,
    error: transplantsError,
    isLoading: transplantsLoading,
  } = useGetTransplantsQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const [tabIndex, setTabIndex] = React.useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <PageOverview heading={"Grow Ops"} />
      {isOpen && (
        <>
          {tabIndex === 0 ? (
            <SeedRecordDetailModal isOpen={isOpen} onClose={onClose} />
          ) : (
            <>
              {tabIndex === 1 ? (
                <TransplantDetailModal isOpen={isOpen} onClose={onClose} />
              ) : (
                <GrowAreaDetailModal isOpen={isOpen} onClose={onClose} />
              )}
            </>
          )}
        </>
      )}

      <Page
        isLoading={growAreasLoading || transplantsLoading}
        isError={Boolean(
          growAreasError || transplantsError || seedRecordsError,
        )}
      >
        <>
          <DataTabs
            onChange={(tab: number) => setTabIndex(tab)}
            onOpen={onOpen}
            onClose={onClose}
            showButton={true}
            data={[
              {
                label: "Seed Records",
                buttonLabel: "Record Seeding",
                isLoading: seedRecordsLoading,
                headers: ["Date", "Product", "Quantity", "Grow Area"],
                entries: seedRecords
                  ? seedRecords
                      .slice()
                      .map((seedRecord, index) => (
                        <SeedRecordViewEntry
                          index={index}
                          seedRecord={seedRecord}
                        />
                      ))
                  : [],
              },
              {
                label: "Transplants",
                buttonLabel: "Record Transplant",
                isLoading: transplantsLoading,
                headers: [
                  "Date",
                  "Product",
                  "Quantity",
                  "From Grow Area",
                  "To Grow Area",
                ],
                entries: transplants
                  ? transplants
                      .slice()
                      .map((transplant, index) => (
                        <TransplantViewEntry
                          index={index}
                          transplant={transplant}
                        />
                      ))
                  : [],
              },
              {
                label: "Grow Areas",
                buttonLabel: "Add Grow Area",
                isLoading: growAreasLoading,
                headers: ["Name", "Parent Grow Area"],
                entries: growAreas
                  ? growAreas
                      .slice()
                      .map((growArea, index) => (
                        <GrowAreaViewEntry index={index} growArea={growArea} />
                      ))
                  : [],
              },
            ]}
          />
        </>
      </Page>
    </>
  );
}

export default GrowOpsPage;
