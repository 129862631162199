import React, { useState } from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { EditIcon, CheckIcon } from "@chakra-ui/icons";

interface EditableQuantityProps {
  value: number;
  onChange: (newValue: number) => void;
}

const EditableQuantity: React.FC<EditableQuantityProps> = ({
  value,
  onChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value.toString());

  const handleSave = () => {
    setIsEditing(false);
    const newValue = parseFloat(editValue);
    if (!isNaN(newValue) && newValue !== value) {
      onChange(newValue);
    } else {
      setEditValue(value.toString());
    }
  };

  return (
    <InputGroup size="sm">
      <Input
        value={isEditing ? editValue : value}
        readOnly={!isEditing}
        onChange={(e) => setEditValue(e.target.value)}
        onFocus={() => setIsEditing(true)}
        onBlur={handleSave}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSave();
          }
        }}
      />
      <InputRightElement>
        {isEditing ? (
          <IconButton
            aria-label="Save"
            icon={<CheckIcon />}
            size="xs"
            onClick={handleSave}
          />
        ) : (
          <EditIcon
            color="teal.500"
            onClick={() => setIsEditing(true)}
            cursor="pointer"
          />
        )}
      </InputRightElement>
    </InputGroup>
  );
};

export default EditableQuantity;
