import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  VStack,
  Text,
  Flex,
} from "@chakra-ui/react";
import { EventSalesReconciliationItem } from "../../types";
import { getProductUnitLabel } from "../../utils/productUnitUtils";

interface EditModalProps {
  isOpen: boolean;
  onClose: () => void;
  item: EventSalesReconciliationItem;
  onSave: (updatedItem: EventSalesReconciliationItem) => void;
  currentStep: number;
  onPrevious: () => void;
}

const EditModal: React.FC<EditModalProps> = ({
  isOpen,
  onClose,
  item,
  onSave,
  currentStep,
  onPrevious,
}) => {
  const [editedItem, setEditedItem] = React.useState(item);
  const [localOpeningQuantity, setLocalOpeningQuantity] = useState<
    number | null
  >(null);
  const [showOpeningQuantityInput, setShowOpeningQuantityInput] = useState(
    !editedItem.opening_quantity,
  );
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    setEditedItem(item);
    setLocalOpeningQuantity(item.opening_quantity);
    setShowOpeningQuantityInput(!item.opening_quantity);
  }, [item]);

  useEffect(() => {
    if (currentStep === 1) {
      return;
    }
    const openingQuantity = Number(editedItem.opening_quantity) || 0;
    const closingQuantity = Number(editedItem.closing_quantity) || 0;
    const soldQuantity = Number(editedItem.sold_quantity) || 0;
    const wastedQuantity = Number(editedItem.wasted_quantity) || 0;

    const varianceQuantity =
      closingQuantity - openingQuantity + soldQuantity + wastedQuantity;

    setEditedItem((prev) => ({
      ...prev,
      variance_quantity: +varianceQuantity.toFixed(3),
    }));
  }, [
    editedItem.opening_quantity,
    editedItem.closing_quantity,
    editedItem.sold_quantity,
    editedItem.wasted_quantity,
    currentStep,
  ]);

  const checkIfValueNaN = (value: number | string) => {
    const parsedValue = value.toString();
    return isNaN(+parsedValue) ? 0 : parsedValue;
  };

  const numberInputProps = {
    precision: 2,
    step: 0.01,
  };

  const handleSave = React.useCallback(() => {
    onSave(editedItem);
    initialFocusRef.current?.focus();
  }, [editedItem, onSave]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave();
    }
  };

  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent onKeyDown={handleKeyDown}>
        <ModalHeader>
          <Text as="span" mr={2}>
            Edit Quantities:
          </Text>
          <Text as="span">{item.product?.name}</Text>
          <Text>
            <Text as="span">Unit:</Text>
            <Text as="span" ml={2}>
              {getProductUnitLabel(item.product_unit)}
            </Text>
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            {currentStep === 1 ? (
              <FormControl>
                <FormLabel>Opening Quantity</FormLabel>
                <NumberInput
                  value={checkIfValueNaN(editedItem.opening_quantity ?? "")}
                  onChange={(valueString) => {
                    setEditedItem((prev) => ({
                      ...prev,
                      opening_quantity: valueString,
                    }));
                  }}
                  {...numberInputProps}
                >
                  <NumberInputField
                    ref={initialFocusRef}
                    onFocus={handleInputFocus}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
            ) : (
              <>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  width="full"
                >
                  <Text mr="auto" fontWeight="bold">
                    Opening Quantity: {editedItem.opening_quantity || "Not set"}
                  </Text>
                  {showOpeningQuantityInput && (
                    <Flex>
                      <FormControl>
                        <NumberInput
                          value={localOpeningQuantity ?? ""}
                          onChange={(_, value) =>
                            setLocalOpeningQuantity(value)
                          }
                          isInvalid
                        >
                          <NumberInputField ref={initialFocusRef} />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                      <Button
                        ml={2}
                        onClick={() => {
                          setEditedItem((prev) => ({
                            ...prev,
                            opening_quantity: localOpeningQuantity,
                          }));
                          setShowOpeningQuantityInput(false);
                        }}
                      >
                        Done
                      </Button>
                    </Flex>
                  )}
                </Flex>
                <FormControl>
                  <FormLabel>Closing Quantity</FormLabel>
                  <NumberInput
                    value={checkIfValueNaN(editedItem.closing_quantity ?? "")}
                    onChange={(valueString) => {
                      setEditedItem((prev) => ({
                        ...prev,
                        closing_quantity: valueString,
                      }));
                    }}
                    {...numberInputProps}
                  >
                    <NumberInputField
                      ref={initialFocusRef}
                      onFocus={handleInputFocus}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl>
                  <FormLabel>Sold Quantity</FormLabel>
                  <NumberInput
                    value={checkIfValueNaN(editedItem.sold_quantity ?? "")}
                    onChange={(valueString) => {
                      setEditedItem((prev) => ({
                        ...prev,
                        sold_quantity: valueString,
                      }));
                    }}
                    {...numberInputProps}
                  >
                    <NumberInputField onFocus={handleInputFocus} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl>
                  <FormLabel>Wasted Quantity</FormLabel>
                  <NumberInput
                    value={checkIfValueNaN(editedItem.wasted_quantity ?? "")}
                    onChange={(valueString) => {
                      setEditedItem((prev) => ({
                        ...prev,
                        wasted_quantity: valueString,
                      }));
                    }}
                    {...numberInputProps}
                  >
                    <NumberInputField onFocus={handleInputFocus} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl>
                  <FormLabel>Variance</FormLabel>
                  <Text fontWeight="bold">
                    {checkIfValueNaN(editedItem.variance_quantity ?? "")}
                  </Text>
                </FormControl>
              </>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button onClick={onPrevious}>Previous product</Button>
          <Button colorScheme="teal" mr={3} onClick={handleSave}>
            Save and next product
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
