import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import StackEntry from "../../components/StackEntry/StackEntry";
import { Product, ProductUnit, Transplant } from "../../types";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { userSlice } from "../../redux/userSlice.ts";
import { useAppSelector } from "../../redux/hooks.ts";
import {
  useGetInventoryItemsQuery,
  useGetProductsQuery,
  usePostTransplantMutation,
} from "../../redux/apiSlice.ts";
import InventoryProductSearch from "../../components/InventoryProductSearch/InventoryProductSearch.tsx";
import AddUnitSelectField from "../../components/AddUnitSelectField/AddUnitSelectField.tsx";
import GrowAreaSearchOrCreate from "../../components/GrowAreaSearch/GrowAreaSearchOrCreate.tsx";

interface TransplantDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  transplant?: Transplant;
}

const TransplantDetailModal = ({
  isOpen,
  onClose,
}: TransplantDetailModalProps): React.ReactElement => {
  const toast = useToast();
  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const [postTransplant, { isLoading }] = usePostTransplantMutation();

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const [transplantDate, setTransplantDate] = useState<string>(
    new Date().toISOString(),
  );
  const [fromGrowAreaSearchTerm, setFromGrowAreaSearchTerm] =
    useState<string>("");
  const [fromGrowAreaId, setFromGrowAreaId] = useState<
    number | null | undefined
  >(null);
  const [toGrowAreaSearchTerm, setToGrowAreaSearchTerm] = useState<string>("");
  const [toGrowAreaId, setToGrowAreaId] = useState<number | null | undefined>(
    null,
  );

  const [selectedProductId, setSelectedProductId] = useState<
    number | null | undefined
  >(null);
  const [selectedProductName, setSelectedProductName] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null);
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(null);
  const [currentUnits, setCurrentUnits] = useState<ProductUnit[]>([]);

  const { data: products } = useGetProductsQuery("");
  const { data: inventoryItems } = useGetInventoryItemsQuery(
    {
      organisationId,
    },
    { skip: !organisationId },
  );

  const [productList, setProductList] = useState<Product[]>([]);
  const [inventoryUnitIds, setInventoryUnitIds] = useState<Set<number>>(
    new Set(),
  );

  useEffect(() => {
    if (
      inventoryItems &&
      inventoryItems.length > 0 &&
      products &&
      products.length > 0
    ) {
      const productIds = new Set(inventoryItems.map((item) => item.product_id));
      setProductList(
        products
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((product) => productIds.has(product.id)),
      );
      setInventoryUnitIds(
        new Set(inventoryItems.map((item) => item.product_unit_id)),
      );
    }
  }, [inventoryItems, products]);

  useEffect(() => {
    if (productList && productList.length > 0 && selectedProductId) {
      const currentProduct = productList.find(
        (product) => product.id === selectedProductId,
      );
      if (currentProduct) {
        const filteredUnits = currentProduct.product_units.filter((unit) =>
          inventoryUnitIds.has(unit.id),
        );
        setCurrentUnits(filteredUnits);
        setSelectedUnitId(filteredUnits?.[0]?.id);
        if (!filteredUnits?.[0]) {
          toast({
            title: "No units available!",
            description:
              "This product does not have any units available for harvest. Please add units to create an order.",
            status: "error",
            isClosable: true,
          });
        }
      } else {
        setCurrentUnits([]);
      }
    } else {
      setCurrentUnits([]);
    }
  }, [selectedProductId]);

  const handleSave = () => {
    if (
      !locationId ||
      !organisationId ||
      !fromGrowAreaId ||
      !toGrowAreaId ||
      !selectedQuantity ||
      !selectedProductId
    ) {
      return;
    }
    const payload = {
      location_id: Number(locationId),
      organisation_id: Number(organisationId),
      transplant_date: transplantDate,
      from_grow_area_id: fromGrowAreaId,
      to_grow_area_id: toGrowAreaId,
      product_id: selectedProductId,
      product_unit_id: selectedUnitId,
      quantity: selectedQuantity,
    } as Transplant;
    postTransplant(payload)
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Transplant created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch((error) => {
        toast({
          title: "Error - failed to record transplant",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isFullscreen ? "full" : "5xl"}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent height="100vh" margin={{ base: 0, md: "auto" }}>
        <ModalCloseButton />
        <ModalHeader padding={{ base: 4, md: 6 }}>
          <Flex alignItems="center">
            <Heading>Transplant Record</Heading>
            <Spacer />
            <Button
              onClick={() => setIsFullscreen(!isFullscreen)}
              size="md"
              variant="outline"
              mr={8}
            >
              {isFullscreen ? (
                <AiOutlineFullscreenExit />
              ) : (
                <AiOutlineFullscreen />
              )}
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody
          flex="1"
          overflow="auto"
          display="flex"
          flexDirection="column"
          px={{ base: 4, md: 6 }}
        >
          <Box pt={4}>
            <Flex direction={["column", "row"]} gap={4}>
              <StackEntry mb={8}>
                <FormLabel>Date</FormLabel>
                <Input
                  type="datetime-local"
                  value={transplantDate}
                  onChange={(e) => setTransplantDate(e.target.value)}
                />
              </StackEntry>
              <StackEntry mb={8}>
                <FormLabel>From Grow Area</FormLabel>
                <GrowAreaSearchOrCreate
                  searchTerm={fromGrowAreaSearchTerm}
                  setSearchTerm={setFromGrowAreaSearchTerm}
                  setGrowAreaId={setFromGrowAreaId}
                />
              </StackEntry>
              <StackEntry mb={8}>
                <FormLabel>To Grow Area</FormLabel>
                <GrowAreaSearchOrCreate
                  searchTerm={toGrowAreaSearchTerm}
                  setSearchTerm={setToGrowAreaSearchTerm}
                  setGrowAreaId={setToGrowAreaId}
                />
              </StackEntry>
            </Flex>
          </Box>
          <Box pt={4}>
            <Flex direction={["column", "row"]} gap={4}>
              <FormControl>
                <FormLabel>Product</FormLabel>
                <InventoryProductSearch
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  setProductFilterId={setSelectedProductId}
                  setProductName={setSelectedProductName}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Units</FormLabel>
                <HStack>
                  <Input
                    autoComplete="off"
                    type="number"
                    placeholder="Quantity"
                    value={selectedQuantity || ""}
                    onChange={(e) =>
                      setSelectedQuantity(Number(e.target.value))
                    }
                  />
                  <AddUnitSelectField
                    selectedProductId={selectedProductId}
                    selectedProductName={selectedProductName}
                    selectedUnitId={selectedUnitId}
                    setSelectedUnitId={setSelectedUnitId}
                    units={currentUnits}
                    setUnits={setCurrentUnits}
                  />
                </HStack>
              </FormControl>
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter flexDirection="column" width="100%" px={4} py={6}>
          <Button
            colorScheme="teal"
            onClick={handleSave}
            isLoading={isLoading}
            // isDisabled={Boolean(!name || (noSubAreas && !subAreaLabel))}
            flex={{ base: 1, sm: "0 1 auto" }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TransplantDetailModal;
