import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { OrderBase, OrderItem } from "../../types.ts";
import TransferableOrderSummary from "./TransferableOrderSummary.tsx";

interface OrderAggregatorProps {
  isFullscreen: boolean;
  orders: OrderBase[];
  supplierIdToNameMap: Map<number, string>;
  onRemoveOrderItem: (orderItem: OrderItem) => void;
  onEditOrderItemQuantity: (orderItem: OrderItem) => void;
  onTransferOrders: (
    fromSupplierId: number,
    toSupplierId: number,
    orderItems: OrderItem[],
  ) => void;
}

const OrderAggregator = ({
  isFullscreen,
  orders,
  supplierIdToNameMap,
  onTransferOrders,
  onRemoveOrderItem,
  onEditOrderItemQuantity,
}: OrderAggregatorProps): React.ReactElement => (
  <Box>
    <Box
      height={isFullscreen ? "68vh" : "50vh"}
      width="100%"
      borderWidth={1}
      borderColor="gray.200"
      borderRadius="md"
      overflow="auto"
      boxShadow="sm"
      bg="white"
      position="relative"
    >
      {orders.map((order) => (
        <Box
          key={order.supplier_organisation_id}
          p={4}
          borderBottomWidth={1}
          borderColor="gray.200"
        >
          <TransferableOrderSummary
            supplierIdToNameMap={supplierIdToNameMap}
            onTransferOrders={onTransferOrders}
            order={order}
            onRemoveOrderItem={onRemoveOrderItem}
            onEditOrderItemQuantity={onEditOrderItemQuantity}
          />
        </Box>
      ))}
    </Box>
    <Box
      p={2}
      boxShadow="md"
      border="solid"
      width="fit-content"
      ml="auto"
      borderColor="gray.300"
      borderRadius="md"
    >
      <Text fontSize="lg" fontWeight="bold" pr={4}>
        Grand Total: $
        {orders.reduce((acc, order) => acc + Number(order.total), 0).toFixed(2)}
      </Text>
    </Box>
  </Box>
);

export default OrderAggregator;
