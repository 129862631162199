import { Location, OrderItem, Price, Product, ProductUnit } from "../../types";
import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Spacer,
  Stack,
  useToast,
} from "@chakra-ui/react";
import OrderSummary from "./OrderSummary";
import AddUnitSelectField from "../../components/AddUnitSelectField/AddUnitSelectField";
import InventoryProductSearch from "../../components/InventoryProductSearch/InventoryProductSearch";
import {
  useGetInventoryItemsQuery,
  useGetProductsQuery,
} from "../../redux/apiSlice";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";

const OrderForm = ({
  isSalesOrder,
  supplier,
  productUnitPriceMap,
  orderItems,
  setOrderItems,
}: {
  isSalesOrder?: boolean;
  buyer?: Location;
  supplier?: Location;
  productUnitPriceMap: Map<number, Price[]>;
  orderItems: OrderItem[];
  setOrderItems: (value: OrderItem[]) => void;
  showErrors: boolean;
}): React.ReactElement => {
  const toast = useToast();
  const [selectedProductId, setSelectedProductId] = useState<
    number | null | undefined
  >(null);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [selectedProductName, setSelectedProductName] = useState<string>("");
  const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null);
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(null);
  const [currentUnits, setCurrentUnits] = useState<ProductUnit[]>([]);
  const [currentPrice, setCurrentPrice] = useState<number | string>("");

  const [productList, setProductList] = useState<Product[]>([]);
  const [inventoryUnitIds, setInventoryUnitIds] = useState<Set<number>>(
    new Set(),
  );

  const [orderTotal, setOrderTotal] = useState<number>(0);

  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;

  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const { data: products = [], isLoading: isLoadingProducts } =
    useGetProductsQuery("");

  const { data: inventoryItems, isLoading: isLoadingInventoryItems } =
    useGetInventoryItemsQuery(
      {
        organisationId,
        locationId,
      },
      { skip: !isSalesOrder || !organisationId || !locationId },
    );

  const {
    data: supplierInventoryItems,
    isLoading: isLoadingSupplierInventoryItems = [],
  } = useGetInventoryItemsQuery(
    {
      organisationId: supplier?.organisation_id.toString() || "",
      locationId: supplier?.id.toString() || "",
    },
    { skip: isSalesOrder || !supplier },
  );

  const handleAddToOrder = () => {
    if (selectedProductId && selectedQuantity && selectedUnitId) {
      const product = productList.find(
        (product) => product.id === selectedProductId,
      );
      const productUnit = product?.product_units.find(
        (unit: ProductUnit) => unit.id === selectedUnitId,
      );
      if (!product || !productUnit) {
        return;
      }
      // check if item unit already in order, update quantity
      const existingOrderItem = orderItems.find(
        (item) =>
          item.product.id === product.id &&
          item.product_unit_id === productUnit.id,
      );
      if (
        existingOrderItem &&
        existingOrderItem.price === Number(currentPrice)
      ) {
        const newQuantity = existingOrderItem.quantity + selectedQuantity;
        const newOrderItems = orderItems.map((item) => {
          if (
            item.product.id === product.id &&
            item.product_unit_id === productUnit.id
          ) {
            return {
              product_id: product.id,
              product: product,
              quantity: newQuantity,
              product_unit_id: productUnit.id,
              product_unit: productUnit,
              price: Number(currentPrice),
              total: Number(currentPrice) * newQuantity,
            };
          }
          return item;
        });
        setOrderItems(newOrderItems);
        return;
      }
      setOrderItems([
        ...orderItems,
        {
          product: product,
          quantity: selectedQuantity,
          product_unit: productUnit,
          product_id: product.id,
          product_unit_id: productUnit.id,
          price: Number(currentPrice),
          total: Number(currentPrice) * selectedQuantity,
        },
      ]);
      setSelectedProductId(null);
      setSelectedProductName("");
      setSearchTerm("");
      setSelectedQuantity(null);
      setSelectedUnitId(null);
      setCurrentPrice("");
    }
  };

  const handleMissingProducts = () => {
    setCurrentUnits([]);
    setSelectedProductId(null);
    setSelectedUnitId(null);
    let errorMessage = "No products available!";
    let errorDescription =
      "This may be a configuration issue or a bug. Please contact support@stocky-ai.com if this issue persists.";
    if (isSalesOrder) {
      errorMessage = "No inventory items available for sale!";
      errorDescription =
        "Please add products and ensure they are marked as sellable to create an order.";
    }
    toast({
      title: errorMessage,
      description: errorDescription,
      status: "error",
      isClosable: true,
    });
  };

  useEffect(() => {
    if (!isSalesOrder || isLoadingProducts || isLoadingInventoryItems) return;
    if (inventoryItems && inventoryItems.length > 0) {
      const productIds = new Set(inventoryItems.map((item) => item.product_id));
      const productUnitIds = new Set(
        inventoryItems.map((item) => item.product_unit_id),
      );
      const filteredProducts = products.filter((product) =>
        productIds.has(product.id),
      );
      setProductList(filteredProducts);
      setInventoryUnitIds(productUnitIds);
    } else {
      handleMissingProducts();
    }
  }, [
    isSalesOrder,
    inventoryItems,
    products,
    isLoadingProducts,
    isLoadingInventoryItems,
  ]);

  useEffect(() => {
    if (isSalesOrder || isLoadingProducts || isLoadingSupplierInventoryItems)
      return;
    if (supplierInventoryItems && supplierInventoryItems.length > 0) {
      const productIds = new Set(
        supplierInventoryItems.map((item) => item.product_id),
      );
      const productUnitIds = new Set(
        supplierInventoryItems.map((item) => item.product_unit_id),
      );
      const filteredProducts = products.filter((product) =>
        productIds.has(product.id),
      );
      setProductList(filteredProducts);
      setInventoryUnitIds(productUnitIds);
    } else {
      handleMissingProducts();
    }
  }, [
    isSalesOrder,
    supplierInventoryItems,
    products,
    isLoadingProducts,
    isLoadingSupplierInventoryItems,
  ]);

  useEffect(() => {
    if (
      isLoadingProducts ||
      (isSalesOrder ? isLoadingInventoryItems : isLoadingSupplierInventoryItems)
    )
      return;
    if (productList && productList.length > 0 && selectedProductId) {
      const currentProduct = productList.find(
        (product) => product.id === selectedProductId,
      );
      if (currentProduct) {
        const filteredUnits = currentProduct.product_units.filter(
          (unit: ProductUnit) => inventoryUnitIds.has(unit.id),
        );
        setCurrentUnits(filteredUnits);
        setSelectedUnitId(filteredUnits?.[0]?.id);
        if (!filteredUnits?.[0]) {
          toast({
            title: "No units available!",
            description: `This product does not have any units available for ${
              isSalesOrder ? "sale" : "purchase"
            }.
              Please add units to create an order.`,
            status: "error",
            isClosable: true,
          });
        }
      } else {
        setCurrentUnits([]);
      }
    } else {
      setCurrentUnits([]);
    }
  }, [
    selectedProductId,
    isLoadingProducts,
    isLoadingInventoryItems,
    isLoadingSupplierInventoryItems,
  ]);

  useEffect(() => {
    if (selectedUnitId && productUnitPriceMap) {
      const prices = productUnitPriceMap.get(selectedUnitId);
      if (prices) {
        setCurrentPrice(prices[0].price);
      } else {
        setCurrentPrice("");
      }
    } else if (!selectedUnitId) {
      setCurrentPrice("");
    }
  }, [selectedUnitId]);

  return (
    <>
      <Heading
        as={"h2"}
        w="100%"
        textAlign={"center"}
        fontWeight="normal"
        fontSize={"24"}
        mb="2%"
      >
        Select items
      </Heading>
      <SimpleGrid columns={1} spacing={6} pb="4">
        <FormControl as={GridItem} colSpan={[3, 2]}>
          <FormLabel
            htmlFor="product"
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: "gray.50",
            }}
          >
            Product
          </FormLabel>
          <InventoryProductSearch
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setProductFilterId={setSelectedProductId}
            setProductName={setSelectedProductName}
          />
        </FormControl>

        <FormControl as={GridItem} colSpan={[3, 2]}>
          <FormLabel
            htmlFor="quantity"
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: "gray.50",
            }}
          >
            Units
          </FormLabel>
          <HStack>
            <Input
              type="number"
              placeholder="Quantity"
              focusBorderColor="brand.400"
              rounded="md"
              value={selectedQuantity || ""}
              onChange={(e) => setSelectedQuantity(Number(e.target.value))}
            />
            <AddUnitSelectField
              selectedProductId={selectedProductId}
              selectedProductName={selectedProductName}
              selectedUnitId={selectedUnitId}
              setSelectedUnitId={setSelectedUnitId}
              units={currentUnits}
              setUnits={setCurrentUnits}
              addUnitsEnabled={isSalesOrder}
            />
            <InputGroup
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAddToOrder();
                }
              }}
            >
              <InputLeftElement
                pointerEvents="none"
                color="gray.300"
                fontSize="1.2em"
                marginRight={4}
              >
                $
              </InputLeftElement>
              <NumberInput
                min={0.0}
                focusBorderColor="brand.400"
                isReadOnly={!isSalesOrder}
                isInvalid={
                  selectedUnitId !== null && isSalesOrder && !currentPrice
                }
                value={currentPrice}
                onChange={(value) => setCurrentPrice(value)}
              >
                <NumberInputField
                  paddingLeft={8}
                  placeholder="Price per unit"
                  rounded="md"
                />
              </NumberInput>
            </InputGroup>
          </HStack>
        </FormControl>

        <Button
          isDisabled={
            !selectedProductId ||
            !selectedUnitId ||
            !selectedQuantity ||
            (isSalesOrder && !currentPrice)
          }
          colorScheme={
            !selectedProductId ||
            !selectedUnitId ||
            !selectedQuantity ||
            (isSalesOrder && !currentPrice)
              ? "gray"
              : "teal"
          }
          onClick={handleAddToOrder}
        >
          Add to Order
        </Button>
      </SimpleGrid>
      <OrderSummary
        isSalesOrder={isSalesOrder}
        orderItems={orderItems}
        productUnitPriceMap={productUnitPriceMap}
        setOrderItems={setOrderItems}
        setOrderTotal={setOrderTotal}
        preventItemEdits={false}
        preventAddRow={true}
        onChange={() => {}}
      />
      <Stack direction="row" style={{ marginTop: "auto" }}>
        <span>
          <strong>Order Total:</strong>
        </span>
        <Spacer />
        <span>
          <strong>${orderTotal.toFixed(2)}</strong>
        </span>
      </Stack>
    </>
  );
};

export default OrderForm;
