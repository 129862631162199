type BaseModel = {
  id: number;
  created_at?: string;
  updated_at?: string;
};

export type Address = {
  id?: number;
  street_1: string;
  street_2: string | null;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  place_id?: string;
};

export type DeliveryAddress = {
  id?: number;
  organisation_id: number;
  location_id: number;
  address_id: number;
  address: Address;
  is_default: boolean;
};

export type User = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  organisation_id: number;
  organisation?: Organisation;
  location_id: number;
  location?: Location;
};

export type OrganisationBase = {
  name: string;
  is_individual?: boolean;
};

export type Organisation = BaseModel & OrganisationBase;

export type LocationBase = {
  name: string;
  organisation_id: number;
  organisation?: Organisation;
  is_supplier: boolean;
  is_producer: boolean;
  address_id?: number;
  address?: Address;
  email?: string;
  phone?: string;
};

export type Location = BaseModel & LocationBase;

export type SupplierLocation = BaseModel & {
  organisation_id: number;
  location_id: number;
  supplying_organisation_id: number;
  supplying_location_id: number;
};

export type DailySales = {
  date: string;
  total_sales?: number | string;
  num_sales?: number;
};

export type ProductUnitBase = {
  unit_label: string;
  unit_quantity: number;
  unit_measure: string;
  unit_system: string;
  product_id?: number;
  product_name?: string;
  sub_unit?: ProductUnitBase;
  sub_unit_id?: number;
  sub_unit_quantity?: number; // TODO: may be obsolete
};

export type ProductUnit = BaseModel & ProductUnitBase;

export type Product = {
  id: number;
  name: string;
  product_units: ProductUnit[];
  category?: string;
};

export type InventoryItem = {
  id?: number;
  product_id: number;
  product?: Product;
  product_unit_id: number;
  product_unit?: ProductUnit;
  organisation_id: number;
};

export type Price = {
  id?: number;
  product_id: number;
  product_unit_id: number;
  price: number;
  supplier_organisation_id: number;
  buyer_organisation_id: number;
  minimum_order_quantity: number;
  effective_date: string;
  deactivated_date: string | null;
  in_multiples_of?: number;
  supplier_sku?: string;
  supplier_product_name?: string;
};

export type ProductUnitAndPrices = {
  product_unit: ProductUnit;
  current_price: number;
  supplier_price: number;
};

export type OrderItem = {
  id?: number;
  order_id?: number;
  product_id: number;
  product: Product;
  product_unit_id: number;
  product_unit?: ProductUnit;
  quantity: number;
  price: number;
  discounted_price?: number;
  discount_percent?: number;
  total: number;
};

export type OrderBase = {
  user_id: number;
  organisation_id: number;
  location_id: number;
  supplier_organisation_id: number;
  supplier_location_id?: number;
  status?: string;
  order_items: OrderItem[];
  order_date: string;
  delivery_date: string;
  total: number;
  is_pickup: boolean;
  buyer_po_number?: string;
  delivery_address_id?: number;

  location?: Location;
};

export type Order = OrderBase & BaseModel;

export type InvoiceItem = {
  id?: number;
  invoice_id?: number;
  product_id: number;
  product: Product;
  product_unit_id: number;
  product_unit?: ProductUnit;
  requested_quantity: number;
  invoiced_quantity: number;
  received_quantity: number;
  price: number;
  discounted_price?: number;
  discount_percent?: number;
  total: number;
  credit: number;
  supplier_notes?: string;
  buyer_notes?: string;
};

export type InvoiceBase = {
  user_id: number;
  organisation_id: number;
  location_id: number;
  supplier_organisation_id: number;
  supplier_location_id?: number;
  order_id?: number;
  status: string;
  total: number;
  credit: number;
  invoice_date: string;
  delivery_date: string;
  invoice_items: InvoiceItem[];
  supplier_notes?: string;
  buyer_notes?: string;
  invoice_number?: string;
  buyer_po_number?: string;
  delivery_address_id?: number;
  is_pickup: boolean;
  delivery_note?: string;

  total_due?: number;
  amount_paid?: number;
  balance?: number;
  is_paid?: boolean;
  is_credit_balanced?: boolean;

  location?: Location;
  supplier_location?: Location;
  invoice_payments?: PaymentInvoiceJunction[];
  delivery_address?: Address;
};

export type Invoice = InvoiceBase & BaseModel;

export type Harvest = {
  id?: number;
  organisation_id: number;
  location_id: number;
  harvest_date: string;
  harvest_items: HarvestItem[];
};

export type HarvestItem = {
  id?: number;
  harvest_id?: number;
  product_id: number;
  product?: Product;
  product_unit_id: number;
  product_unit?: ProductUnit;
  quantity: number;
  harvest?: Harvest;
  harvest_date?: string;
};

export type TokenResponse = {
  access_token: string;
  token_type: string;
};

export type LoginResponse = {
  user: User;
  organisation: Organisation;
  location: Location;
  token: TokenResponse;
};

export type Container = {
  id: number | null;
  organisation_id: number;
  location_id: number;
  number: string;
  status: string;
  origin_country: string;
  expected_arrival: string;
  arrived_at?: string | null;
  released_at?: string | null;
  cost_items?: ContainerCostItem[];
  container_invoices?: ContainerInvoiceJunction[];
};

export type ContainerCostItem = {
  id?: number | null;
  container_id: number | null;
  container?: Container;
  cost: number;
  description: string;
  is_paid: boolean;
};

export type ContainerInvoiceJunction = {
  id?: number;
  container_id: number;
  container: Container;
  invoice_id: number;
  invoice: Invoice;
};

export enum PaymentType {
  CHEQUE = "CHEQUE",
  BANK_TRANSFER = "BANK_TRANSFER",
  CREDIT_CARD = "CREDIT_CARD",
  CASH = "CASH",
  OTHER = "OTHER",
}

export type PaymentBase = {
  organisation_id: number;
  location_id: number;
  customer_organisation_id: number;
  customer_location_id: number;
  reference_number: string;
  amount: number;
  payment_date: string;
  payment_type: PaymentType;
  lodged_date?: string | null;
  bounced_date?: string | null;
  customer_location?: Location;
  payment_invoices?: PaymentInvoiceJunction[];
};

export type Payment = BaseModel &
  PaymentBase & {
    amount_assigned?: number;
  };

export type PaymentUpdate = {
  id: number;
  payment_date?: string;
  amount?: number;
  payment_type?: PaymentType;
  reference_number?: string;
  lodged_date?: string | null;
  bounced_date?: string | null;
  payment_invoices?: PaymentInvoiceJunction[];
};

export type PaymentInvoiceJunction = {
  id?: number;
  payment_id?: number;
  payment?: Payment;
  invoice_id: number;
  invoice?: Invoice;
  amount_paid: number;
};

export type EventSalesReconciliationBase = {
  organisation_id: number;
  location_id: number;
  event_name?: string;
  event_date: string;
  reconciled_at?: string | null;
  items: EventSalesReconciliationItem[];
};

export type EventSalesReconciliation = EventSalesReconciliationBase & BaseModel;

export type EventSalesReconciliationItemBase = {
  event_sales_reconciliation_id?: number;
  product_id: number;
  product?: Product;
  product_unit_id: number;
  product_unit?: ProductUnit;
  opening_quantity: number | null;
  closing_quantity: number | null;
  sold_quantity: number | null;
  wasted_quantity: number | null;
  variance_quantity: number | null;
};

export type EventSalesReconciliationItem = EventSalesReconciliationItemBase &
  BaseModel;

export type GrowArea = BaseModel & {
  organisation_id: number;
  location_id: number;
  name: string;
  parent_grow_area_id: number | null;
  parent_grow_area?: GrowArea;
  sub_areas?: GrowArea[];
  sub_area_label?: string;
};

export type SeedRecord = BaseModel & {
  organisation_id: number;
  location_id: number;
  grow_area_id: number;
  grow_area?: GrowArea;
  seed_product_id: number;
  seed_product?: Product;
  seed_product_unit_id: number;
  seed_quantity: number;
  product_id: number;
  planted_at: string;
};

export type Transplant = BaseModel & {
  organisation_id: number;
  location_id: number;
  from_grow_area_id: number;
  from_grow_area?: GrowArea;
  to_grow_area_id: number;
  to_grow_area?: GrowArea;
  product_id: number;
  product_unit_id: number;
  product?: Product;
  product_unit?: ProductUnit;
  quantity: number;
  transplant_date: string;
};
