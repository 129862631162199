import React, { useState } from "react";
import { Button, Input, Box } from "@chakra-ui/react";

interface FileUploadProps {
  onFileRead?: (file: File | null) => void;
  onFileContent?: (fileContent: string | ArrayBuffer | null) => void;
  buttonLabel?: string;
}

const FileUpload = ({
  onFileRead,
  onFileContent,
  buttonLabel = "Import File",
}: FileUploadProps): React.ReactElement => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = () => {
    if (file) {
      onFileRead?.(file);
      if (onFileContent) {
        const reader = new FileReader();
        reader.onload = (event) => {
          onFileContent(event.target?.result ?? null);
        };
        reader.readAsText(file);
      }
    }
  };

  return (
    <Box>
      <Input type="file" onChange={handleFileChange} />
      {file && (
        <Box mt={4}>
          <Button mt={2} colorScheme="teal" onClick={handleUpload}>
            {buttonLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FileUpload;
