import {
  Badge,
  HStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import * as React from "react";
import { Organisation } from "../../types";
import { useGetOrganisationsQuery } from "../../redux/apiSlice";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "../Autocomplete";
import { useEffect } from "react";

interface OrganisationSearchOrCreateProps {
  setOrganisationId?: (id: number | null | undefined) => void;
  setOrganisationName?: (name: string) => void;
  includeSearchIcon?: boolean;
  disableCreation?: boolean;
  placeholder?: string;
}

const OrganisationSearchOrCreate = ({
  setOrganisationId = () => {},
  setOrganisationName = () => {},
  includeSearchIcon = false,
  disableCreation = false,
  placeholder = "Search for a customer",
}: OrganisationSearchOrCreateProps): React.ReactElement => {
  const { data: organisations } = useGetOrganisationsQuery("");
  const [organisationNames, setOrganisationNames] = React.useState<Set<string>>(
    new Set(),
  );
  const [forceAddOrganisation, setForceAddOrganisation] =
    React.useState<boolean>(false);

  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const [newOrganisationName, setNewOrganisationName] = React.useState<
    string | undefined
  >(undefined);

  const clearSearch = () => {
    setOrganisationId(undefined);
    setOrganisationName("");
    setSearchTerm("");
  };

  // useEffect to set forceAddOrganisation to false while searchTerm is updating, timeout to force add if stopped
  // updating and searchTerm not in organisationNames
  useEffect(() => {
    setForceAddOrganisation(false);
    const timer = setTimeout(() => {
      if (
        !disableCreation &&
        searchTerm &&
        !organisationNames.has(searchTerm)
      ) {
        setForceAddOrganisation(true);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    if (organisations) {
      const names = new Set<string>();
      organisations.forEach((org: Organisation) => {
        names.add(org.name);
      });
      setOrganisationNames(names);
    }
  }, [organisations]);

  useEffect(() => {}, [organisationNames, searchTerm]);

  return (
    <AutoComplete openOnFocus>
      <HStack>
        <InputGroup>
          {includeSearchIcon && (
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
            >
              <SearchIcon />
            </InputLeftElement>
          )}
          <AutoCompleteInput
            onItemSelect={(value) => {
              setSearchTerm(value);
              setOrganisationName(value);
              const organisation = organisations?.find(
                (org) => org.name === value,
              );
              setOrganisationId(organisation?.id);
              setNewOrganisationName(undefined);
            }}
            placeholder={includeSearchIcon ? placeholder : ""}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value.length === 0) {
                clearSearch();
              }
              setNewOrganisationName(undefined);
            }}
          />
          {newOrganisationName && (
            <InputRightElement marginRight={4}>
              <Badge colorScheme="green">New!</Badge>
            </InputRightElement>
          )}
        </InputGroup>
        <AutoCompleteList
          enableAddItem={!disableCreation}
          addItemLabel={"organization"}
          onAddItem={(value: string) => {
            if (disableCreation) return;
            setNewOrganisationName(value);
            setOrganisationName(value);
          }}
          forceAddItem={forceAddOrganisation}
        >
          {organisations
            ?.slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((organisation: Organisation) => (
              <AutoCompleteItem
                id={`option-${organisation.id}`}
                key={`option-${organisation.id}`}
                value={organisation.name}
                textTransform="capitalize"
                onClick={() => {
                  setSearchTerm(organisation.name);
                  setOrganisationId(organisation.id);
                  setOrganisationName(organisation.name);
                  setNewOrganisationName(undefined);
                }}
              >
                {organisation.name}
              </AutoCompleteItem>
            ))}
        </AutoCompleteList>
      </HStack>
    </AutoComplete>
  );
};

export default OrganisationSearchOrCreate;
