import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  useToast,
  FormControl,
  FormLabel,
  Text,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Badge,
} from "@chakra-ui/react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import { Product } from "../../types";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import { badgeColors } from "../../routes/ProductsPage";
import {
  useDeleteInventoryItemsFromUnitIdListMutation,
  usePostInventoryItemListMutation,
  usePostProductUnitListMutation,
} from "../../redux/apiSlice";
import * as Sentry from "@sentry/react";
import ProductUnitManager from "../../components/ProductUnitManager/ProductUnitManager.tsx";
import { ProductUnitMgr } from "../../components/ProductUnitManager/types.ts";
import { userSlice } from "../../redux/userSlice.ts";
import { useAppSelector } from "../../redux/hooks.ts";

interface ProductViewEntryProps {
  itemIndex: number;
  product: Product;
  inventoryUnitIds: Set<number>;
  children?: React.ReactNode[];
  onClick?: () => void;
}

export default function ProductViewEntry({
  itemIndex,
  product,
  inventoryUnitIds,
  children,
}: ProductViewEntryProps) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [productUnitMgr, setProductUnitMgr] = useState<ProductUnitMgr | null>(
    null,
  );

  useEffect(() => {
    if (isOpen) {
      setProductUnitMgr(
        new ProductUnitMgr(
          product.product_units,
          inventoryUnitIds || new Set(),
        ),
      );
    }
  }, [product.product_units, inventoryUnitIds, isOpen]);

  const [postProductUnits, { isLoading: isPostingProductUnits }] =
    usePostProductUnitListMutation();

  const [postInventoryItemList, { isLoading: isPostingInventoryItems }] =
    usePostInventoryItemListMutation();
  const [
    deleteInventoryItemListFromUnitIdList,
    { isLoading: isDeletingInventoryItems },
  ] = useDeleteInventoryItemsFromUnitIdListMutation();

  const { getCurrentOrganisationId } = userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const handleAddProductUnit = () => {
    if (!productUnitMgr) {
      return;
    }
    const newProductUnits = productUnitMgr.newProductUnits;
    const newInventoryUnitIds = productUnitMgr.getNewInventoryUnitIds();
    const removedInventoryUnitIds = productUnitMgr.getRemovedInventoryUnitIds();
    if (
      !(
        newProductUnits.length ||
        newInventoryUnitIds.length ||
        removedInventoryUnitIds.length
      )
    ) {
      toast({
        title: "No units to add or modify.",
        description: "Please add or modify units before saving.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const errors = {
      newProductUnits: null,
      newInventoryUnitIds: null,
      removedInventoryUnitIds: null,
    };

    if (newInventoryUnitIds.length) {
      if (!organisationId) {
        toast({
          title: "No organisation ID.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      postInventoryItemList({
        organisationId,
        inventoryItems: newInventoryUnitIds.map((productUnitId) => ({
          product_id: product.id,
          product_unit_id: productUnitId,
          organisation_id: organisationId,
        })),
      })
        .unwrap()
        .then(() => {})
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          errors["newInventoryUnitIds"] = error;
        });
    }

    if (removedInventoryUnitIds.length) {
      if (!organisationId) return;
      deleteInventoryItemListFromUnitIdList({
        organisationId,
        productUnitIds: removedInventoryUnitIds,
      })
        .unwrap()
        .then(() => {})
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          errors["removedInventoryUnitIds"] = error;
        });
    }

    postProductUnits({ productId: product.id, productUnits: newProductUnits })
      .unwrap()
      .then(() => {})
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        errors["newProductUnits"] = error;
      });
    if (
      errors["newProductUnits"] ||
      errors["newInventoryUnitIds"] ||
      errors["removedInventoryUnitIds"]
    ) {
      toast({
        title: "Units failed to create or modify.",
        description:
          "Please check your inputs and try again. If the issue persists please contact support.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Units created/updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  };

  return (
    <InteractiveRow itemIndex={itemIndex} onClick={onOpen}>
      <Modal
        size={"6xl"}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>{product.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack>
              <FormControl>
                <FormLabel>Product</FormLabel>
                <Text>{product.name}</Text>
              </FormControl>
              <FormControl>
                <FormLabel>Units</FormLabel>
                {product.product_units.map((unit, unitIndex) => {
                  if (inventoryUnitIds.has(unit.id)) {
                    return (
                      <Badge
                        colorScheme={badgeColors[unitIndex % 8]}
                        fontSize="0.8em"
                        key={unit.id}
                        marginRight="0.5rem"
                      >
                        {getProductUnitLabel(unit)}
                      </Badge>
                    );
                  }
                })}
              </FormControl>
              {/*<FormControl>*/}
              {/*  <FormLabel>Default Reporting Unit</FormLabel>*/}
              {/*  <Select>*/}
              {/*    {product.product_units.map((unit) => (*/}
              {/*      <option key={unit.id} value={unit.id}>*/}
              {/*        {getProductUnitLabel(unit)}*/}
              {/*      </option>*/}
              {/*    ))}*/}
              {/*  </Select>*/}
              {/*</FormControl>*/}
            </HStack>
            <Accordion defaultIndex={[0]} allowMultiple marginTop={"1rem"}>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <b>Units</b>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {productUnitMgr && (
                    <ProductUnitManager
                      product={product}
                      productUnitMgr={productUnitMgr}
                    />
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              mr={3}
              onClick={() => handleAddProductUnit()}
              isLoading={
                isPostingProductUnits ||
                isPostingInventoryItems ||
                isDeletingInventoryItems
              }
            >
              Save
            </Button>
            <Spacer />
            <Button
              colorScheme="red"
              variant={"ghost"}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {children}
    </InteractiveRow>
  );
}
