import { Box, BoxProps, Flex, FlexProps } from "@chakra-ui/layout";
import React from "react";
import { useAutoCompleteContext } from "../autocomplete-context";

export interface EmptyStateProps extends BoxProps {
  enableAddItem?: boolean;
  addItemLabel?: string;
  onAddItem?: (value: string) => void;
  forceAddItem?: boolean;
}

export const EmptyState = (props: EmptyStateProps): React.ReactElement => {
  const { enableAddItem, addItemLabel, onAddItem, forceAddItem } = props;
  const context = useAutoCompleteContext();
  const { getEmptyStateProps, defaultEmptyStateProps, onClose } = context;

  const noSuggestions = context.filteredList.every((i) => i.noFilter);

  const style = {
    ...emptyStyles,
    cursor: enableAddItem ? "pointer" : "default",
    marginTop: !noSuggestions && forceAddItem ? "1rem" : "inherit",
  };

  const noOptionText = "No options found!";
  const addOptionText = `${
    noSuggestions
      ? `No match found for ${context.query} - \n `
      : `${context.query} - \n `
  } Add a new ${addItemLabel ? addItemLabel : "item"}?`;

  const emptyState = getEmptyStateProps(
    <Flex
      {...style}
      {...defaultEmptyStateProps}
      onClick={() => {
        if (onAddItem) {
          onAddItem(context.query);
        }
        onClose();
      }}
    >
      {enableAddItem || forceAddItem ? addOptionText : noOptionText}
    </Flex>,
    forceAddItem,
  );

  return <Box {...props}>{emptyState}</Box>;
};

const emptyStyles: FlexProps = {
  fontSize: "sm",
  align: "center",
  justify: "center",
  fontWeight: "bold",
  fontStyle: "italic",
};
