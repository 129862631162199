import React, { useState, useEffect } from "react";
import {
  format,
  addYears,
  subYears,
  startOfMonth,
  endOfMonth,
  isSameMonth,
  isWithinInterval,
  isBefore,
  isAfter,
} from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

interface MonthRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
  onDone?: () => void;
}

const MIN_DATE = new Date(2024, 4, 1); // May 2024
const NEXT_MONTH = new Date().getMonth() + 1;
const NEXT_MONTH_START_DATE = new Date(new Date().getFullYear(), NEXT_MONTH, 1);

function MonthRangePicker({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onDone,
}: MonthRangePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selecting, setSelecting] = useState<"start" | "end" | null>(null);
  const [hoverDate, setHoverDate] = useState<Date | null>(null);

  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    if (startDate) {
      setCurrentDate(startDate);
    }
  }, [startDate]);

  const handleMonthClick = (date: Date) => {
    if (isBefore(date, MIN_DATE)) return;
    if (isAfter(date, NEXT_MONTH_START_DATE)) return;

    if (!selecting || selecting === "start") {
      onStartDateChange(startOfMonth(date));
      setSelecting("end");
    } else {
      onEndDateChange(endOfMonth(date));
      setSelecting("start");
    }
  };

  const isInRange = (date: Date) => {
    if (!startDate || !endDate) return false;
    return isWithinInterval(date, { start: startDate, end: endDate });
  };

  const getMonthStyle = (date: Date) => {
    if (isBefore(date, MIN_DATE) || isAfter(date, NEXT_MONTH_START_DATE))
      return "bg-gray-100 text-gray-400 cursor-not-allowed";
    if (startDate && isSameMonth(date, startDate))
      return "bg-teal-200 text-teal-800";
    if (endDate && isSameMonth(date, endDate))
      return "bg-teal-200 text-teal-800";
    if (isInRange(date)) return "bg-teal-200 text-teal-800";
    if (
      hoverDate &&
      isWithinInterval(date, { start: startDate || hoverDate, end: hoverDate })
    )
      return "bg-gray-100";
    return "bg-white text-gray-700 hover:bg-gray-100";
  };

  const renderMonths = () => {
    const months = [];
    for (let i = 0; i < 12; i++) {
      const date = new Date(currentDate.getFullYear(), i, 1);
      months.push(
        <button
          key={i}
          onClick={() => handleMonthClick(date)}
          onMouseEnter={() => setHoverDate(date)}
          onMouseLeave={() => setHoverDate(null)}
          className={`w-full py-1 px-2 text-sm rounded-md transition-colors duration-200 border border-gray-200 ${getMonthStyle(
            date,
          )}`}
          disabled={
            isBefore(date, MIN_DATE) || isAfter(date, NEXT_MONTH_START_DATE)
          }
        >
          {format(date, "MMM")}
        </button>,
      );
    }
    return months;
  };

  const handleDone = () => {
    if (startDate && !endDate) {
      onEndDateChange(endOfMonth(startDate));
    }
    if (onDone) {
      onDone();
    }
    setIsOpen(false);
  };

  return (
    <div ref={ref} className="relative">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center cursor-pointer text-teal-600"
      >
        {(!startDate || !endDate) && (
          <span className="font-bold">Choose a month range</span>
        )}
        {startDate && endDate && (
          <span className="font-bold">
            {startDate ? format(startDate, "MMM yyyy") : "Start"} -{" "}
            {endDate ? format(endDate, "MMM yyyy") : "End"}
          </span>
        )}
      </div>

      {isOpen && (
        <div className="absolute z-10 bg-white shadow-xl rounded-md p-3 mt-2 left-0 border border-gray-200 w-52">
          <div className="flex justify-between items-center mb-2">
            <span className="font-bold text-gray-800 text-sm">
              {currentDate.getFullYear()}
            </span>
            <div className="flex space-x-1">
              <button
                onClick={() => setCurrentDate(subYears(currentDate, 1))}
                className="p-1 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors duration-200 text-gray-600"
              >
                <ChevronLeftIcon className="h-4 w-4" />
              </button>
              <button
                onClick={() => setCurrentDate(addYears(currentDate, 1))}
                className="p-1 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors duration-200 text-gray-600"
              >
                <ChevronRightIcon className="h-4 w-4" />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-1 mb-2">{renderMonths()}</div>
          <div className="flex justify-end">
            <button
              onClick={handleDone}
              className="px-3 py-1 text-sm bg-teal-500 text-white rounded-md hover:bg-teal-600 transition-colors duration-200"
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MonthRangePicker;
