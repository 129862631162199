import { PropertyPath } from "lodash";
import { getFormattedDate } from "../../utils/invoiceUtils";

export type MappedValue = string | number;

export interface SpreadSheetMapping {
  column_name: string;
  key_in_data: PropertyPath | null;
  required?: boolean;
  multiple_items_key?: PropertyPath;
  transform?: (value: any) => MappedValue;
  default?: MappedValue;
}

export const invoiceMappings: SpreadSheetMapping[] = [
  {
    column_name: "InvoiceNo",
    key_in_data: "id",
    required: true,
  },
  {
    column_name: "Customer",
    key_in_data: "location.organisation.name",
    required: true,
  },
  {
    column_name: "InvoiceDate",
    key_in_data: "invoice_date",
    required: true,
    transform: (dateString) => {
      return getFormattedDate(dateString);
    },
  },
  {
    column_name: "DueDate",
    key_in_data: "delivery_date",
    required: true,
    transform: (dateString) => {
      return getFormattedDate(dateString);
    },
  },
  {
    column_name: "Item(Product/Service)",
    multiple_items_key: "invoice_items",
    key_in_data: "product_unit.product_name",
    required: true,
  },
  {
    column_name: "ItemDescription",
    multiple_items_key: "invoice_items",
    key_in_data: "product_unit",
    required: true,
    transform: (productUnit) => {
      const { unit_measure, unit_quantity } = productUnit;
      return `${unit_quantity}${unit_measure}`;
    },
  },
  {
    column_name: "ItemQuantity",
    multiple_items_key: "invoice_items",
    key_in_data: "invoiced_quantity",
    required: true,
  },
  {
    column_name: "ItemRate",
    multiple_items_key: "invoice_items",
    key_in_data: "price",
    required: true,
  },
  {
    column_name: "ItemAmount",
    multiple_items_key: "invoice_items",
    key_in_data: "total",
    required: true,
  },
  {
    column_name: "ItemTaxCode",
    key_in_data: null,
    default: "EXEMPT",
  },
  {
    column_name: "Currency",
    key_in_data: null,
    default: "CAD",
  },
];
