import { ProductUnit, ProductUnitBase } from "../types";

export const formatProductQuantity = (quantity: number | string) => {
  quantity = Number(quantity);
  if (quantity % 1 === 0) {
    return quantity;
  }
  const response = quantity.toFixed(2);
  if (response.slice(-1) === "0") {
    return response.slice(0, -1);
  }
  return response;
};

export const productUnitLabel = (
  productUnit: ProductUnit | ProductUnitBase,
  measureOverwrite?: string,
  isSubUnit: boolean = false,
) => {
  if (isSubUnit) {
    return `${formatProductQuantity(productUnit.unit_quantity)}${
      measureOverwrite || productUnit.unit_measure
    } ${productUnit.unit_label}`;
  }
  const unitInfo = `${
    productUnit.sub_unit_quantity
      ? formatProductQuantity(productUnit.sub_unit_quantity)
      : formatProductQuantity(productUnit.unit_quantity)
  }${measureOverwrite || productUnit.unit_measure}`;
  return productUnit.unit_label && productUnit.unit_label.length > 0
    ? `${productUnit.unit_label}${
        unitInfo === "1unit" ? "" : " (" + unitInfo + ")"
      }`
    : unitInfo;
};

export const getProductUnitLabel = (
  productUnit?: ProductUnit | ProductUnitBase,
) => {
  if (!productUnit) {
    return "";
  }
  // quick fix for Klippers - bunches should be each, showing weight info could lead to complaints
  if (productUnit.unit_label.toLowerCase().includes("bunch")) {
    return "bunch";
  }
  const measure =
    productUnit.sub_unit &&
    productUnit.sub_unit.unit_quantity !== 1 &&
    productUnit.sub_unit.unit_measure !== "unit"
      ? ` x ${productUnitLabel(productUnit.sub_unit, undefined, true)}`
      : productUnit.unit_measure;
  return productUnitLabel(productUnit, measure);
};
