import { Td, useDisclosure } from "@chakra-ui/react";
import React from "react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import { GrowArea } from "../../types";
import GrowAreaDetailModal from "./GrowAreaDetailModal.tsx";

interface GrowAreaViewEntryProps {
  index: number;
  growArea: GrowArea;
}

export default function GrowAreaViewEntry({
  index,
  growArea,
}: GrowAreaViewEntryProps): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {isOpen && (
        <GrowAreaDetailModal
          isOpen={isOpen}
          onClose={onClose}
          growArea={growArea}
        />
      )}
      <InteractiveRow itemIndex={index} onClick={isOpen ? onClose : onOpen}>
        <Td fontSize="sm">{growArea.name}</Td>
        <Td fontSize="sm">{growArea.parent_grow_area?.name || "--"}</Td>
      </InteractiveRow>
    </>
  );
}
