import React, { useEffect, useState } from "react";
import { Invoice, PaymentInvoiceJunction } from "../../types";
import {
  Badge,
  Checkbox,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  NumberInput,
  Stack,
  Text,
} from "@chakra-ui/react";
import StackEntry from "../../components/StackEntry/StackEntry";
import {
  formatCurrency,
  getInvoiceStatusColor,
  getInvoiceStatusLabel,
} from "../../utils/invoiceUtils";
import InvoicePaymentStatusUpdateBadge from "./InvoicePaymentStatusUpdateBadge";

interface InvoicePaymentEntryProps {
  paymentInvoice?: PaymentInvoiceJunction;
  invoice: Invoice;
  paymentAmountRemaining: number;
  updatePaymentInvoiceAmount: (invoiceId: number, amount: number) => void;
  originalAmountAssigned: string;
}
const InvoicePaymentEntry = ({
  paymentInvoice,
  invoice,
  paymentAmountRemaining,
  updatePaymentInvoiceAmount,
  originalAmountAssigned,
}: InvoicePaymentEntryProps): React.ReactElement => {
  const [amountAlreadyPaid, setAmountAlreadyPaid] = useState<number>(
    invoice.amount_paid ?? 0,
  );
  const [amountAssigned, setAmountAssigned] = useState<string>(
    Number(paymentInvoice?.amount_paid ?? 0).toFixed(2),
  );
  const [cachedAmountAssigned, setCachedAmountAssigned] = useState<string>(
    Number(paymentInvoice?.amount_paid ?? 0).toFixed(2),
  );
  const [creditOwed, setCreditOwed] = useState<string>("--.--");
  const [isError, setIsError] = useState<boolean>(false);

  const [isPaid, setIsPaid] = useState<boolean>(false);

  useEffect(() => {
    if (invoice.credit) {
      const amountPaid = Number(amountAlreadyPaid) + Number(amountAssigned);
      if (
        Number(amountPaid) <
        (isNaN(Number(invoice.total_due))
          ? Number(invoice.total) - Number(invoice.credit)
          : Number(invoice.total_due))
      ) {
        setCreditOwed("--.--");
        return;
      }

      setCreditOwed(
        formatCurrency(
          Math.max(
            0,
            Number(amountPaid) - Number(invoice.total) + Number(invoice.credit),
          ),
        ),
      );
    }
  }, [amountAlreadyPaid, amountAssigned]);

  const handleAmountUpdate = (amount: string) => {
    const duplicateAction =
      amount === cachedAmountAssigned ||
      Number(amount).toFixed(2) === cachedAmountAssigned;
    if (Number(amount) === 0) {
      setAmountAssigned("0.00");
      setCachedAmountAssigned("0.00");
      if (!duplicateAction) {
        updatePaymentInvoiceAmount(invoice.id, 0);
      }
      return;
    }
    if (duplicateAction) {
      return;
    }
    let updateAmount = Number(amount);
    if (updateAmount < 0) {
      updateAmount = 0;
    } else {
      let buffer: number;
      if (updateAmount < Number(cachedAmountAssigned)) {
        buffer = Number(cachedAmountAssigned) - updateAmount;
      } else {
        buffer = updateAmount - Number(cachedAmountAssigned);
      }

      if (updateAmount > paymentAmountRemaining + buffer) {
        updateAmount = paymentAmountRemaining + buffer;
        setIsError(true);
        setTimeout(() => {
          setIsError(false);
        }, 4000);
      }
    }
    setAmountAssigned(updateAmount.toFixed(2));
    setCachedAmountAssigned(updateAmount.toFixed(2));
    updatePaymentInvoiceAmount(invoice.id, updateAmount);
  };

  useEffect(() => {
    setAmountAssigned(Number(paymentInvoice?.amount_paid ?? 0).toFixed(2));
    setCachedAmountAssigned(
      Number(paymentInvoice?.amount_paid ?? 0).toFixed(2),
    );
    setIsPaid((paymentInvoice?.amount_paid || 0) > 0);
  }, [paymentInvoice]);

  useEffect(() => {
    const originalAmount = Number(originalAmountAssigned);
    if (originalAmount > 0 && invoice.amount_paid) {
      setAmountAlreadyPaid(Number(invoice.amount_paid) - originalAmount);
    }
  }, [originalAmountAssigned]);

  const handleSetIsPaid = (value: boolean) => {
    setIsPaid(value);

    let updateAmount = 0;
    const balanceRemaining =
      Number(invoice.balance || 0) + Number(originalAmountAssigned);
    if (value) {
      updateAmount = paymentAmountRemaining;
      if (Number(cachedAmountAssigned) > 0) {
        updateAmount = paymentAmountRemaining + Number(cachedAmountAssigned);
      }
      updateAmount = Math.min(updateAmount, balanceRemaining);
    }
    setAmountAssigned(updateAmount.toFixed(2));
    setCachedAmountAssigned(updateAmount.toFixed(2));
    updatePaymentInvoiceAmount(invoice.id, updateAmount);
  };

  return (
    <>
      <Stack direction={"row"}>
        <Heading size="xs" textTransform="uppercase">
          Invoice #{invoice.id} - {invoice.invoice_date.slice(0, 10)}
        </Heading>
        <Badge colorScheme={getInvoiceStatusColor(invoice.status, true)}>
          {getInvoiceStatusLabel(invoice.status, true)}
        </Badge>
        <InvoicePaymentStatusUpdateBadge
          invoice={invoice}
          amountAssigned={amountAssigned}
          originalAmountAssigned={originalAmountAssigned}
          amountAlreadyPaid={amountAlreadyPaid}
        />
      </Stack>
      <Stack direction={"row"} key={invoice.id} mt={4}>
        <StackEntry>
          <FormLabel>Pay?</FormLabel>
          <Checkbox
            isChecked={isPaid}
            onChange={(e) => handleSetIsPaid(e.target.checked)}
          />
        </StackEntry>
        <StackEntry>
          <FormLabel>Total:</FormLabel>
          <Text h={50}>${formatCurrency(invoice.total)}</Text>
        </StackEntry>
        <StackEntry>
          <FormLabel>Credit:</FormLabel>
          <Text>${formatCurrency(invoice.credit)}</Text>
        </StackEntry>
        <StackEntry>
          <FormLabel>Total Due:</FormLabel>
          <Text>${formatCurrency(invoice.total_due ?? 0)}</Text>
        </StackEntry>
        <StackEntry>
          <FormLabel>Already Paid:</FormLabel>
          <Text>${formatCurrency(amountAlreadyPaid)}</Text>
        </StackEntry>
        <StackEntry>
          <FormLabel>Assigned:</FormLabel>
          <InputGroup size={"md"} w={"80px"} mr={4}>
            <InputLeftElement>$</InputLeftElement>
            <NumberInput
              min={0}
              max={Math.min(
                invoice.total_due ?? invoice.total,
                paymentAmountRemaining,
              )}
              value={amountAssigned}
              precision={2}
              step={0.1}
            >
              <Input
                // TODO: make currency number input component
                ml={"10px"}
                id={"new-item-cost"}
                type="number"
                isInvalid={isError}
                value={amountAssigned}
                onChange={(e) => setAmountAssigned(e.target.value)}
                onFocus={(e) => {
                  e.target.select();
                  if (amountAssigned === "0.00") {
                    setAmountAssigned("");
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAmountUpdate(amountAssigned);
                  }
                }}
                onBlur={() => {
                  handleAmountUpdate(amountAssigned);
                }}
              />
            </NumberInput>
          </InputGroup>
        </StackEntry>
        <StackEntry>
          <FormLabel>Balance:</FormLabel>
          <Text>${formatCurrency(invoice?.balance || 0)}</Text>
        </StackEntry>
        <StackEntry>
          <FormLabel>Credit Owed:</FormLabel>
          <Text>${creditOwed}</Text>
        </StackEntry>
      </Stack>
    </>
  );
};

export default InvoicePaymentEntry;
