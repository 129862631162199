import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  useMediaQuery,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import { Location, Order, OrderItem, Price } from "../../types";
import AvatarEntry from "../../components/AvatarEntry/AvatarEntry";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import {
  useGetBuyerLocationsQuery,
  useGetCurrentUserQuery,
  useGetPricesQuery,
  useGetSupplierLocationsQuery,
  usePostOrderMutation,
} from "../../redux/apiSlice";
import * as Sentry from "@sentry/react";
import OrderForm from "./OrderForm";
import { getLocationAndOrgName } from "../../utils/invoiceUtils";
import OrderReview from "./OrderReview";
import CustomerSearch from "../../components/CustomerSearch/CustomerSearch";
import debounce from "../../utils/debounce";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";

interface OrderModalProps {
  isOpen: boolean;
  onClose: () => void;
  isSalesView?: boolean;
}

const SelectCustomer = ({
  next,
  buyers,
  currentBuyer,
  setBuyer,
}: {
  next: (ignoreCheck?: boolean) => void;
  buyers: Location[];
  currentBuyer?: Location;
  setBuyer: (value: Location | undefined) => void;
}) => {
  const handleClick = (value: string) => {
    const customer = buyers.find(
      (buyer) => getLocationAndOrgName(buyer) === value,
    );
    setBuyer(customer || undefined);
    if (customer) {
      setTimeout(() => next(true), 400);
    }
  };

  return (
    <>
      <Heading
        as={"h2"}
        w="100%"
        textAlign={"center"}
        fontWeight="normal"
        fontSize={"24"}
        mb="2%"
        marginTop={"1rem"}
      >
        Select a Customer
      </Heading>
      <CustomerSearch
        setCustomerName={(name: string) => {
          handleClick(name);
        }}
      />
      <FormControl as={GridItem} colSpan={[6, 3]}>
        <FormLabel
          htmlFor="customer"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
        >
          Customer
        </FormLabel>
        <div style={{ height: "400px", overflowY: "auto" }}>
          <List spacing={3} overflowY={"auto"}>
            {buyers
              .slice()
              .sort((a, b) =>
                getLocationAndOrgName(a).localeCompare(
                  getLocationAndOrgName(b),
                ),
              )
              .map((buyer) => (
                <ListItem key={getLocationAndOrgName(buyer)}>
                  <AvatarEntry
                    name={getLocationAndOrgName(buyer)}
                    handleClick={handleClick}
                    isSelected={currentBuyer && currentBuyer.id === buyer.id}
                  />
                </ListItem>
              ))}
          </List>
        </div>
      </FormControl>
    </>
  );
};

const SelectSupplier = ({
  next,
  suppliers,
  setSupplier,
  currentSupplier,
}: {
  next: (value: boolean) => void;
  suppliers: Location[];
  setSupplier: (value: Location | undefined) => void;
  currentSupplier?: Location;
}) => {
  const handleClick = (value: string) => {
    const supplier = suppliers.find(
      (supplier) => getLocationAndOrgName(supplier) === value,
    );
    setSupplier(supplier || undefined);
    if (supplier) {
      setTimeout(() => next(true), 400);
    }
  };

  return (
    <>
      <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        Select a Supplier
      </Heading>
      <FormControl as={GridItem} colSpan={[6, 3]}>
        <FormLabel
          htmlFor="supplier"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
        >
          Supplier
        </FormLabel>
        <List spacing={3}>
          {suppliers.map((supplier) => (
            <ListItem key={supplier.id}>
              <AvatarEntry
                name={getLocationAndOrgName(supplier)}
                handleClick={handleClick}
                isSelected={
                  currentSupplier && currentSupplier.id === supplier.id
                }
              />
            </ListItem>
          ))}
        </List>
      </FormControl>
    </>
  );
};

function Multistep({
  isSalesView,
  activeStep,
  buyerLocations,
  supplierLocations,
  buyer,
  setBuyer,
  supplier,
  setSupplier,
  productUnitIdToPriceMap,
  orderItems,
  setOrderItems,
  deliveryDate,
  setDeliveryDate,
  deliveryMethod,
  setDeliveryMethod,
  nextStep,
  isNextFocused,
  buyerPONumber,
  setBuyerPONumber,
  setDeliveryAddressId,
}: {
  isSalesView: boolean;
  activeStep: number;
  buyerLocations: Location[];
  supplierLocations: Location[];
  buyer?: Location;
  setBuyer: (location: Location | undefined) => void;
  supplier: Location;
  setSupplier: (location: Location | undefined) => void;
  productUnitIdToPriceMap: Map<number, Price[]>;
  orderItems: OrderItem[];
  setOrderItems: (items: OrderItem[]) => void;
  deliveryDate: string;
  setDeliveryDate: (date: string) => void;
  deliveryMethod: string;
  setDeliveryMethod: (method: string) => void;
  nextStep: () => void;
  prevStep: () => void;
  isNextFocused: boolean;
  setIsNextFocused: (value: boolean) => void;
  buyerPONumber: string | undefined;
  setBuyerPONumber: (value: string) => void;
  setDeliveryAddressId: (value: number | undefined) => void;
}) {
  return (
    <>
      {activeStep === 0 ? (
        <>
          {isSalesView ? (
            <SelectCustomer
              next={nextStep}
              buyers={buyerLocations}
              setBuyer={setBuyer}
              currentBuyer={buyer}
            />
          ) : (
            <SelectSupplier
              next={nextStep}
              suppliers={supplierLocations}
              setSupplier={setSupplier}
              currentSupplier={supplier}
            />
          )}
        </>
      ) : activeStep === 1 ? (
        <OrderForm
          isSalesOrder={isSalesView}
          buyer={isSalesView ? buyer : undefined}
          supplier={isSalesView ? undefined : supplier}
          productUnitPriceMap={productUnitIdToPriceMap}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          showErrors={isNextFocused}
        />
      ) : (
        buyer && (
          <OrderReview
            isSalesOrder={isSalesView}
            buyer={buyer}
            supplier={supplier}
            orderItems={orderItems}
            productUnitPriceMap={productUnitIdToPriceMap}
            setOrderItems={setOrderItems}
            deliveryDate={deliveryDate}
            setDeliveryDate={setDeliveryDate}
            deliveryMethod={deliveryMethod}
            setDeliveryMethod={setDeliveryMethod}
            showErrors={isNextFocused}
            buyerPONumber={buyerPONumber}
            setBuyerPONumber={setBuyerPONumber}
            setDeliveryAddressId={setDeliveryAddressId}
          />
        )
      )}
    </>
  );
}

function OrderModal({ isOpen, onClose, isSalesView = false }: OrderModalProps) {
  const toast = useToast();
  const [buyer, setBuyer] = useState<undefined | Location>(undefined);
  const [supplier, setSupplier] = useState<undefined | Location>(undefined);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [productUnitIdToPriceMap, setProductUnitIdToPriceMap] = useState<
    Map<number, Price[]>
  >(new Map());
  const [deliveryDate, setDeliveryDate] = useState<string>("");
  const [deliveryMethod, setDeliveryMethod] = useState<string>("delivery");
  const [buyerPONumber, setBuyerPONumber] = useState<string | undefined>(
    undefined,
  );
  const [deliveryAddressId, setDeliveryAddressId] = useState<
    number | undefined
  >();

  const [isNextFocused, setIsNextFocused] = useState(false);
  const {
    getUserId,
    getCurrentLocationId,
    getCurrentOrganisationId,
    getCurrentLocation,
  } = userSlice.selectors;
  const userId = useAppSelector(getUserId);
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const currentLocation = useAppSelector(getCurrentLocation);

  useEffect(() => {
    if (!isSalesView) {
      setBuyer(currentLocation);
    }
  }, [currentLocation]);

  const { data: buyerLocations = [] } = useGetBuyerLocationsQuery(
    { organisationId, locationId },
    { skip: !isSalesView },
  );

  const { data: supplierLocations = [] } = useGetSupplierLocationsQuery(
    { organisationId, locationId },
    { skip: isSalesView || !organisationId || !locationId },
  );
  const { data: supplierPrices } = useGetPricesQuery(
    (isSalesView ? organisationId : supplier?.organisation_id) || "",
    { skip: isSalesView ? !organisationId : !supplier },
  );

  const { data: currentUser } = useGetCurrentUserQuery("");

  const [postOrder, { isLoading: isPostingOrder }] = usePostOrderMutation();

  const steps = [
    {
      title: "First",
      description: `Select ${isSalesView ? "Customer" : "Supplier"}`,
    },
    { title: "Second", description: "Fill Order" },
    { title: "Third", description: "Confirm" },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [sectionOpen, setSectionOpen] = React.useState(true);

  useEffect(() => {
    if (supplierPrices && supplierPrices.length > 0) {
      const priceMap = new Map();
      supplierPrices.forEach((price: Price) => {
        if (!priceMap.has(price.product_unit_id)) {
          priceMap.set(price.product_unit_id, [price]);
        } else {
          const existingPrices = priceMap.get(price.product_unit_id);
          existingPrices.push(price);
          priceMap.set(price.product_unit_id, existingPrices);
        }
      });
      setProductUnitIdToPriceMap(priceMap);
    }
  }, [supplierPrices, isOpen]);

  const handlePurchaseOrderSubmit = async () => {
    if (!supplier || !orderItems || orderItems.length < 1) {
      return;
    }
    postOrder({
      user_id: currentUser?.id || Number(userId),
      location_id: Number(locationId),
      organisation_id: Number(organisationId),
      supplier_location_id: supplier.id,
      supplier_organisation_id: supplier.organisation_id,
      order_items: orderItems,
      order_date: new Date().toISOString(),
      delivery_date: deliveryDate,
      total: orderItems.reduce((total, item) => total + item.total, 0),
      is_pickup: deliveryMethod === "pickup",
      buyer_po_number: buyerPONumber,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Purchase Order created.",
          description:
            "You can view this in your purchase orders list. " +
            "If you have enabled automated emails for this supplier, an email has been sent.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        toast({
          title: "Purchase Order failed to create.",
          description: "Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleSalesOrderSubmit = async () => {
    if (!buyer || !orderItems || orderItems.length < 1) {
      return;
    }
    postOrder({
      user_id: currentUser?.id || Number(userId),
      location_id: buyer.id,
      organisation_id: buyer.organisation_id,
      supplier_organisation_id: Number(organisationId),
      supplier_location_id: Number(locationId),
      order_items: orderItems,
      order_date: new Date().toISOString(),
      delivery_date: deliveryDate,
      total: orderItems.reduce((total, item) => total + item.total, 0),
      is_pickup: deliveryMethod === "pickup",
      buyer_po_number: buyerPONumber,
      delivery_address_id: deliveryAddressId,
    } as Order)
      .unwrap()
      .then(() => {
        toast({
          title: "Sales Order created.",
          description:
            "You can view this in your sales order list. " +
            "If you have enabled automated emails for this supplier, an email has been sent.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        toast({
          title: "Sales Order failed to create.",
          description: "Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleSectionChange = (index: number) => {
    if (index !== activeStep) {
      setSectionOpen(false);
      setTimeout(() => {
        setActiveStep(index);
        setSectionOpen(true);
      }, 400);
    }
  };

  const nextStep = (ignoreCheck: boolean = false) => {
    if (
      !ignoreCheck &&
      activeStep === 0 &&
      (isSalesView ? !buyer : !supplier)
    ) {
      setIsNextFocused(true);
      toast({
        title: `${isSalesView ? "Customer" : "Supplier"} selection required.`,
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => setIsNextFocused(false), 300);
      return;
    }
    if (activeStep < steps.length) {
      handleSectionChange(activeStep + 1);
    }
  };

  const prevStep = () => {
    if (activeStep > 0) {
      handleSectionChange(activeStep - 1);
    }
  };

  const handleChangeStep = (index: number) => {
    if (index !== activeStep) {
      if (index > activeStep) {
        nextStep();
      } else {
        prevStep();
      }
    }
  };

  const debouncedSaveOrder = debounce(
    () =>
      isSalesView ? handleSalesOrderSubmit() : handlePurchaseOrderSubmit(),
    300,
  );
  const [isMobileView] = useMediaQuery("(max-width: 478px)");

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  return (
    <>
      <Modal
        size={isFullscreen ? "full" : "3xl"}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems="center">
              <Heading>
                New {`${isSalesView ? "Sales" : "Purchase"}`} Order
              </Heading>
              <Spacer />
              <Button
                onClick={() => setIsFullscreen(!isFullscreen)}
                size="md"
                variant="outline"
                mr={8}
              >
                {isFullscreen ? (
                  <AiOutlineFullscreenExit />
                ) : (
                  <AiOutlineFullscreen />
                )}
              </Button>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stepper
              index={activeStep}
              colorScheme={"teal"}
              width={"95%"}
              gap="0"
              marginBottom={"16px"}
              marginX={"auto"}
              orientation={isMobileView ? "vertical" : "horizontal"}
            >
              {steps.map((step, index) => (
                <Step key={index} onClick={() => handleChangeStep(index)}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            <div style={{ width: "100%" }}>
              <Collapse in={sectionOpen} animateOpacity>
                <Box rounded="lg" m="4px" as="form">
                  <Multistep
                    isSalesView={isSalesView}
                    buyer={isSalesView ? buyer : currentLocation}
                    setBuyer={setBuyer}
                    supplier={isSalesView ? currentLocation : supplier}
                    setSupplier={setSupplier}
                    buyerLocations={isSalesView ? buyerLocations : []}
                    supplierLocations={isSalesView ? [] : supplierLocations}
                    productUnitIdToPriceMap={productUnitIdToPriceMap}
                    orderItems={orderItems}
                    setOrderItems={setOrderItems}
                    deliveryDate={deliveryDate}
                    setDeliveryDate={setDeliveryDate}
                    deliveryMethod={deliveryMethod}
                    setDeliveryMethod={setDeliveryMethod}
                    activeStep={activeStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    isNextFocused={isNextFocused}
                    setIsNextFocused={setIsNextFocused}
                    buyerPONumber={buyerPONumber}
                    setBuyerPONumber={setBuyerPONumber}
                    setDeliveryAddressId={setDeliveryAddressId}
                  />
                </Box>
              </Collapse>
            </div>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup
              mt="5%"
              w="100%"
              onMouseEnter={() => {
                setIsNextFocused(true);
              }}
              onMouseLeave={() => {
                setTimeout(() => setIsNextFocused(false), 1000);
              }}
            >
              <Flex w="100%" justifyContent="space-between">
                <Flex>
                  <Button
                    onClick={prevStep}
                    isDisabled={activeStep === 0}
                    colorScheme="teal"
                    variant="solid"
                    w="7rem"
                    mr="5%"
                  >
                    Back
                  </Button>
                  <Button
                    w="7rem"
                    isDisabled={
                      buyer === undefined ||
                      (activeStep === 1 &&
                        (!orderItems || orderItems.length < 1)) ||
                      activeStep === 2
                    }
                    onClick={() => nextStep()}
                    colorScheme="teal"
                    variant="outline"
                  >
                    Next
                  </Button>
                </Flex>
                {activeStep === 2 ? (
                  <Button
                    w="7rem"
                    colorScheme="teal"
                    variant="solid"
                    onClick={() => debouncedSaveOrder()}
                    isDisabled={
                      isPostingOrder ||
                      buyer === undefined ||
                      !orderItems ||
                      orderItems.length < 1 ||
                      !deliveryDate ||
                      deliveryDate.length < 1
                    }
                  >
                    Submit
                  </Button>
                ) : null}
              </Flex>
              <Spacer />
              <Button
                colorScheme="red"
                variant={"ghost"}
                mr={3}
                onClick={onClose}
              >
                Close
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default OrderModal;
