import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { isEqual } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import AddUnitSelectField from "../../components/AddUnitSelectField/AddUnitSelectField";
import DataTable from "../../components/DataTable/DataTable";
import EditableColumn from "../../components/DataTable/EditableColumn";
import InventoryProductSearch from "../../components/InventoryProductSearch/InventoryProductSearch";
import {
  useGetInventoryItemsQuery,
  useGetProductsQuery,
} from "../../redux/apiSlice";
import { useAppSelector } from "../../redux/hooks";
import { userSlice } from "../../redux/userSlice";
import { OrderItem, Price, ProductUnit } from "../../types";
import {
  calculateDiscountedPrice,
  getItemSalesPrice,
  getOrderTotal,
  isItemDiscounted,
} from "../../utils/invoiceUtils";
import { getProductUnitLabel } from "../../utils/productUnitUtils";

interface OrderSummaryProps {
  isSalesOrder?: boolean;
  orderItems: OrderItem[];
  productUnitPriceMap: Map<number, Price[]>;
  setOrderItems: (
    value: OrderItem[] | ((prevItems: OrderItem[]) => OrderItem[]),
  ) => void;
  setOrderTotal: (value: number) => void;
  preventItemEdits?: boolean;
  preventAddRow?: boolean;
  onChange?: () => void;
  isReviewStep?: boolean;
}

export default function OrderSummary({
  isSalesOrder = false,
  orderItems,
  productUnitPriceMap,
  setOrderItems,
  setOrderTotal,
  preventItemEdits = true,
  preventAddRow = true,
  onChange = () => {},
  isReviewStep = false,
}: OrderSummaryProps): React.ReactElement {
  const toast = useToast();
  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const { data: products } = useGetProductsQuery("");

  const { data: inventoryItems } = useGetInventoryItemsQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const [newProductId, setNewProductId] = useState<number | null | undefined>(
    null,
  );
  const [newItemProductName, setNewItemProductName] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [newProductUnitId, setNewProductUnitId] = useState<number | null>(null);
  const [newProductUnits, setNewProductUnits] = useState<ProductUnit[]>([]);
  const [newItemQuantity, setNewItemQuantity] = useState<number>(1);
  const [newItemCost, setNewItemCost] = useState<number | string>("");
  const [newItemTotal, setNewItemTotal] = useState<number | null>(null);

  const [enableDiscounts, setEnableDiscounts] = useState<boolean>(false);

  const updateOrderTotal = useCallback(() => {
    if (orderItems && orderItems.length > 0) {
      const newOrderTotal = orderItems.reduce((total, item) => {
        const unitPrice: number =
          getItemSalesPrice(item) ||
          productUnitPriceMap.get(item.product_unit_id)?.[0].price ||
          0;
        return total + unitPrice * item.quantity;
      }, 0);
      setOrderTotal(newOrderTotal);
    } else {
      setOrderTotal(0);
    }
  }, [orderItems, productUnitPriceMap, setOrderTotal]);

  useEffect(() => {
    updateOrderTotal();
  }, [updateOrderTotal]);

  useEffect(() => {
    let inventoryUnitIds = new Set();
    if (
      inventoryItems &&
      inventoryItems.length > 0 &&
      products &&
      products.length > 0
    ) {
      inventoryUnitIds = new Set(
        inventoryItems.map((item) => item.product_unit_id),
      );
    } else {
      return;
    }
    if (products && products.length > 0 && newProductId) {
      const currentProduct = products.find(
        (product) => product.id === newProductId,
      );
      if (currentProduct) {
        const filteredUnits = currentProduct.product_units.filter(
          (unit: ProductUnit) => inventoryUnitIds.has(unit.id),
        );
        setNewProductUnits(filteredUnits);
        setNewProductUnitId(filteredUnits?.[0]?.id);
        if (!filteredUnits?.[0]) {
          toast({
            title: "No units available!",
            description: "Please add units to proceed with order.",
            status: "warning",
            isClosable: true,
          });
        }
      } else {
        setNewProductUnits([]);
      }
    } else {
      setNewProductUnits([]);
    }
  }, [products, inventoryItems, newProductId]);

  const handleEdit = useCallback(
    (index: number, quantity: number) => {
      onChange();
      setOrderItems((prevItems: OrderItem[]) =>
        prevItems.map((orderItem, i) => {
          if (i === index) {
            const updatedOrderItem = {
              ...orderItem,
              quantity: quantity,
            };
            updatedOrderItem.total = getOrderTotal(updatedOrderItem);
            return updatedOrderItem;
          }
          return orderItem;
        }),
      );
    },
    [onChange, setOrderItems],
  );

  const handleEditPrice = useCallback(
    (index: number, price: number) => {
      onChange();
      setOrderItems((prevItems: OrderItem[]) =>
        prevItems.map((orderItem, i) => {
          if (i === index) {
            const updatedOrderItem = {
              ...orderItem,
              price: price,
              discounted_price: isItemDiscounted(orderItem)
                ? calculateDiscountedPrice(orderItem)
                : orderItem.discounted_price,
              discount_percent: orderItem.discount_percent,
            };
            updatedOrderItem.total = getOrderTotal(updatedOrderItem);
            return updatedOrderItem;
          }
          return orderItem;
        }),
      );
    },
    [onChange, setOrderItems],
  );

  const handleEditDiscountPrice = useCallback(
    (index: number, price: number) => {
      onChange();
      setOrderItems((prevItems: OrderItem[]) =>
        prevItems.map((orderItem, i) => {
          if (i === index) {
            const updatedOrderItem = {
              ...orderItem,
              discounted_price: price,
              discount_percent: (1 - price / orderItem.price) * 100,
            };
            updatedOrderItem.total = getOrderTotal(updatedOrderItem);
            return updatedOrderItem;
          }
          return orderItem;
        }),
      );
    },
    [onChange, setOrderItems],
  );

  const handleEditDiscountPercent = useCallback(
    (index: number, percent: number) => {
      onChange();
      setOrderItems((prevItems: OrderItem[]) =>
        prevItems.map((orderItem, i) => {
          if (i === index) {
            const updatedOrderItem = {
              ...orderItem,
              discounted_price: (orderItem.price * (100 - percent)) / 100,
              discount_percent: percent,
            };
            updatedOrderItem.total = getOrderTotal(updatedOrderItem);
            return updatedOrderItem;
          }
          return orderItem;
        }),
      );
    },
    [onChange, setOrderItems],
  );

  const handleAddNewItem = useCallback(() => {
    if (
      products &&
      newProductId &&
      newProductUnitId &&
      newItemQuantity &&
      newItemCost
    ) {
      const product = products.find((product) => product.id === newProductId);
      const productUnit = product?.product_units.find(
        (unit: ProductUnit) => unit.id === newProductUnitId,
      );
      if (!product || !productUnit) {
        return;
      }
      const newItem: OrderItem = {
        product_id: newProductId,
        product: product,
        product_unit_id: newProductUnitId,
        product_unit: productUnit,
        quantity: newItemQuantity,
        price: Number(newItemCost),
        total: Number(newItemCost) * newItemQuantity,
      };
      setOrderItems((prevItems) => [...prevItems, newItem]);
      onChange();
      setNewItemQuantity(1);
      setNewItemCost("");
      setNewItemTotal(null);
      setNewProductId(null);
      setNewItemProductName("");
      setNewProductUnitId(null);
      setNewProductUnits([]);
    }
  }, [
    products,
    newProductId,
    newProductUnitId,
    newItemQuantity,
    newItemCost,
    onChange,
    setOrderItems,
  ]);

  const handleDeleteOrderItem = useCallback(
    (index: number) => {
      onChange();
      setOrderItems((prevItems: OrderItem[]) =>
        prevItems.filter((_, i) => i !== index),
      );
    },
    [onChange, setOrderItems],
  );

  const handleEditUnit = useCallback(
    (index: number, newUnitId: number) => {
      onChange();
      setOrderItems((prevItems: OrderItem[]) =>
        prevItems.map((orderItem, i) => {
          if (i === index) {
            const updatedOrderItem = {
              ...orderItem,
              product_unit_id: newUnitId,
              product_unit: orderItem.product.product_units.find(
                (unit) => unit.id === newUnitId,
              ),
            };
            updatedOrderItem.total = getOrderTotal(updatedOrderItem);
            return updatedOrderItem;
          }
          return orderItem;
        }),
      );
    },
    [onChange, setOrderItems],
  );

  const handleChangeProduct = useCallback(
    (index: number, newProductId: number) => {
      onChange();
      setOrderItems((prevItems: OrderItem[]) => {
        return prevItems.map((orderItem, i) => {
          if (i === index) {
            const newProduct = products?.find((p) => p.id === newProductId);
            if (!newProduct) return orderItem;

            const updatedOrderItem = {
              ...orderItem,
              product_id: newProductId,
              product: newProduct,
              product_unit_id: newProduct.product_units[0].id,
              product_unit: newProduct.product_units[0],
              price: orderItem.price || 0,
            };
            updatedOrderItem.total = getOrderTotal(updatedOrderItem);
            return updatedOrderItem;
          }
          return orderItem;
        });
      });
    },
    [onChange, setOrderItems, products],
  );

  const columnHelper = createColumnHelper<OrderItem>();

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor("product", {
        cell: (info) =>
          preventItemEdits ? (
            <Text>{info.getValue().name}</Text>
          ) : (
            <Select
              value={info.getValue().id}
              onChange={(e) =>
                handleChangeProduct(info.row.index, Number(e.target.value))
              }
              isDisabled={preventItemEdits}
              size="sm"
            >
              {products?.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </Select>
          ),
        header: "Product",
        size: 200,
      }),
      columnHelper.accessor("product_unit", {
        cell: (info) =>
          preventItemEdits ? (
            <Text>{getProductUnitLabel(info.getValue())}</Text>
          ) : (
            <Select
              value={info.getValue()?.id}
              onChange={(e) =>
                handleEditUnit(info.row.index, Number(e.target.value))
              }
              isDisabled={preventItemEdits}
              size="sm"
              width="90px"
              title={getProductUnitLabel(info.getValue())}
            >
              {info.row.original.product.product_units.map((unit) => (
                <option
                  key={unit.id}
                  value={unit.id}
                  title={getProductUnitLabel(unit)}
                >
                  {getProductUnitLabel(unit)}
                </option>
              ))}
            </Select>
          ),
        header: `Unit`,
        minSize: preventItemEdits ? 80 : 120,
      }),
      columnHelper.accessor("quantity", {
        cell: (info) => (
          <EditableColumn
            inputId={"quantity-" + info.row.index}
            value={info.getValue()}
            setValue={(value: string | number) =>
              handleEdit(info.row.index, Number(value))
            }
            isEditable={!preventItemEdits}
          />
        ),
        header: "Requested Quantity",
        size: 100,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("price", {
        cell: (info) => (
          <EditableColumn
            inputId={"price-" + info.row.index}
            value={Number(info.getValue()).toFixed(2)}
            setValue={(value: string | number) => {
              handleEditPrice(info.row.index, Number(value));
            }}
            label={"$"}
            isEditable={!preventItemEdits}
            customStyles={{ justifyContent: "flex-end" }}
            width="80px"
          />
        ),
        sortingFn: (a, b, columnId) =>
          Number(a.getValue(columnId)) - Number(b.getValue(columnId)),
        header: "Price",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      columnHelper.accessor("discounted_price", {
        cell: (info) => (
          <EditableColumn
            inputId={"discounted-price-" + info.row.index}
            value={Number(info.getValue() || info.row.original.price).toFixed(
              2,
            )}
            setValue={(value: string | number) => {
              handleEditDiscountPrice(info.row.index, Number(value));
            }}
            label={"$"}
            isEditable={!preventItemEdits}
            customStyles={{ justifyContent: "flex-end" }}
            width="80px"
          />
        ),
        sortingFn: (a, b, columnId) =>
          Number(a.getValue(columnId)) - Number(b.getValue(columnId)),
        header: "Discount Price",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      columnHelper.accessor("discount_percent", {
        cell: (info) => (
          <EditableColumn
            inputId={"discount-percent-" + info.row.index}
            value={Number(info.getValue() || 0).toFixed(2)}
            setValue={(value: string | number) => {
              handleEditDiscountPercent(info.row.index, Number(value));
            }}
            label={"%"}
            isEditable={!preventItemEdits}
            customStyles={{ justifyContent: "flex-end" }}
            width="80px"
          />
        ),
        sortingFn: (a, b, columnId) =>
          Number(a.getValue(columnId)) - Number(b.getValue(columnId)),
        header: "Discount %",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      columnHelper.accessor("total", {
        cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
        sortingFn: (a, b, columnId) =>
          Number(a.getValue(columnId)) - Number(b.getValue(columnId)),
        header: "Total",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
    ];
    if (!preventItemEdits) {
      cols.push(
        columnHelper.accessor("id", {
          cell: (info) => (
            <IconButton
              aria-label="Delete item"
              icon={<DeleteIcon color="gray.500" />}
              size="sm"
              colorScheme="gray"
              onClick={() => handleDeleteOrderItem(info.row.index)}
              isDisabled={preventItemEdits}
            />
          ),
          header: "",
          size: 80,
        }) as ColumnDef<OrderItem, number>,
      );
    }
    return cols;
  }, [
    columnHelper,
    preventItemEdits,
    handleEdit,
    handleEditPrice,
    handleEditDiscountPrice,
    handleEditDiscountPercent,
    handleDeleteOrderItem,
    handleEditUnit,
    handleChangeProduct,
    products,
  ]);

  return (
    <div>
      {isSalesOrder && (
        <>
          {(!isReviewStep || (isReviewStep && enableDiscounts)) && (
            <Checkbox
              isChecked={enableDiscounts}
              onChange={(e) => {
                if (!isReviewStep) {
                  if (enableDiscounts) {
                    for (const orderItem of orderItems) {
                      if (
                        orderItem.discounted_price &&
                        orderItem.discounted_price !== orderItem.price
                      ) {
                        toast({
                          title: "Cannot disable discounts!",
                          description: "Please remove discounts to disable.",
                          status: "warning",
                          isClosable: true,
                        });
                        return;
                      }
                    }
                  }
                  setEnableDiscounts(e.target.checked);
                }
              }}
              colorScheme="teal"
              size="md"
              alignSelf="flex-start"
              isDisabled={isReviewStep}
              mb={2}
            >
              Apply discounts
            </Checkbox>
          )}
        </>
      )}
      <Box
        overflowX="auto"
        maxWidth="100%"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
      >
        <Box minWidth="500px">
          <DataTable
            data={orderItems}
            columns={columns}
            columnVisibility={
              !enableDiscounts
                ? { discounted_price: false, discount_percent: false }
                : {}
            }
            showAddRow={!preventAddRow}
            addRowLabel={"item"}
            addRowNextFocus={"new-item-description"}
            addRowChildren={[
              <InventoryProductSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setProductFilterId={setNewProductId}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Tab") {
                    document.getElementById("new-item-unit")?.focus();
                  }
                }}
              />,
              <AddUnitSelectField
                focusId="new-item-unit"
                selectedProductId={newProductId}
                selectedProductName={newItemProductName}
                selectedUnitId={newProductUnitId}
                setSelectedUnitId={setNewProductUnitId}
                units={newProductUnits}
                setUnits={setNewProductUnits}
                addUnitsEnabled={isSalesOrder}
              />,
              <Input
                id={"new-item-quantity"}
                type="number"
                value={newItemQuantity}
                onChange={(e) => setNewItemQuantity(Number(e.target.value))}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Tab") {
                    // handleAddNewItem();
                    document.getElementById("new-item-cost")?.focus();
                  }
                }}
              />,
              <InputGroup>
                <InputLeftElement>$</InputLeftElement>
                <Input
                  id={"new-item-cost"}
                  type="number"
                  value={newItemCost}
                  onChange={(e) => setNewItemCost(Number(e.target.value))}
                  isDisabled={!isSalesOrder}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === "Tab") {
                      handleAddNewItem();
                      document.getElementById("new-item-description")?.focus();
                    }
                  }}
                />
              </InputGroup>,
              <Text>${Number(newItemTotal).toFixed(2)}</Text>,
            ]}
          />
        </Box>
      </Box>
    </div>
  );
}
