import type { ButtonProps } from "@relume_io/relume-ui";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ChatEntry from "./ChatEntry";
import { useEffect, useState } from "react";

type ImageProps = {
  src: string;
  alt?: string;
};

type Props = {
  heading: string;
  description: string;
  buttons: ButtonProps[];
  images: ImageProps[];
  secondaryImages: ImageProps[];
};

// const oliviaAvatar = new URL(
//   "../../assets/images/olivia-avatar.jpg",
//   import.meta.url,
// ).href;
// const mickAvatar = new URL(
//   "../../assets/images/mick-avatar.jpg",
//   import.meta.url,
// ).href;
// const mariaAvatar = new URL(
//   "../../assets/images/maria-avatar.jpg",
//   import.meta.url,
// ).href;
// const janeAvatar = new URL(
//   "../../assets/images/jane-avatar.jpg",
//   import.meta.url,
// ).href;
// const mollyAvatar = new URL(
//   "../../assets/images/molly-avatar.jpg",
//   import.meta.url,
// ).href;
// const jamesAvatar = new URL(
//   "../../assets/images/james-avatar.jpg",
//   import.meta.url,
// ).href;
// const sallyAvatar = new URL(
//   "../../assets/images/sally-avatar.jpg",
//   import.meta.url,
// ).href;
// const lindaAvatar = new URL(
//   "../../assets/images/linda-avatar.jpg",
//   import.meta.url,
// ).href;
// const jimAvatar = new URL("../../assets/images/jim-avatar.jpg", import.meta.url)
//   .href;
// const kimAvatar = new URL("../../assets/images/kim-avatar.jpg", import.meta.url)
//   .href;
// const stockyCart = new URL(
//   "../../assets/images/stockyCart.png",
//   import.meta.url,
// ).href;
//
// const questionsResponses = [
//   {
//     userName: "Olivia",
//     userAvatar: oliviaAvatar,
//     question:
//       "Hey Stocky! How long until I can fulfill an extra order of 200lbs of blueberries per month?",
//     response:
//       "It will take 2 months with your current yield, projected rate of harvest, current commitments, and resource allocation.",
//   },
//   {
//     userName: "Mick",
//     userAvatar: mickAvatar,
//     question:
//       "Hey Stocky, how many more orders can I fulfill if I increase my yield of 'X' by 20%?",
//     response: "You can fulfill an additional 3 orders per month.",
//   },
//   {
//     userName: "Maria",
//     userAvatar: mariaAvatar,
//     question: "Hey Stocky, what product was my bestseller this season?",
//     response: "Your bestseller this season was the 2022 Pinot Noir.",
//   },
//   {
//     userName: "Jane",
//     userAvatar: janeAvatar,
//     question:
//       "Hey Stocky, I'm looking for a new product to add to my inventory.",
//     response:
//       "Based on your current inventory and customer preferences, I recommend adding a new line of organic honey.",
//   },
//   {
//     userName: "Molly",
//     userAvatar: mollyAvatar,
//     question: "Hey Stocky, generate a report of my top 5 customers by revenue.",
//     response:
//       "Your top 5 customers by revenue are XYZ Corp, ABC Inc, MNO Ltd, PQR Corp, and DEF Inc. Report downloading...",
//   },
//   {
//     userName: "James",
//     userAvatar: jamesAvatar,
//     question:
//       "Hey Stocky! What is the best time to plant tomatoes for this season?",
//     response:
//       "The best time to plant tomatoes is in the next 2 weeks to ensure a healthy harvest in 3 months.",
//   },
//   {
//     userName: "Sally",
//     userAvatar: sallyAvatar,
//     question: "How can I increase my yield by 20%?",
//     response:
//       "You can increase your yield by 20% by optimizing your watering schedule and using the right fertilizers.",
//   },
//   {
//     userName: "Linda",
//     userAvatar: lindaAvatar,
//     question: "Which is my largest outstanding account receivable?",
//     response:
//       "Your largest outstanding account receivable is $22,000 from XYZ Corp.",
//   },
//   {
//     userName: "Jim",
//     userAvatar: jimAvatar,
//     question: "Which products should I increase my prices on?",
//     response:
//       "You should increase the prices of your organic strawberries and heirloom tomatoes to maximize your profit margins.",
//   },
//   {
//     userName: "Kim",
//     userAvatar: kimAvatar,
//     question: "Which products should I focus on to increase my revenue by 30%?",
//     response:
//       "You should focus on increasing your yield of strawberries and tomatoes to increase your revenue by 30%.",
//   },
// ];

export type HeroHeaderProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const HeroHeader = (props: HeroHeaderProps) => {
  const { heading, description, images, secondaryImages } = {
    ...HeroHeaderDefaults,
    ...props,
  } as Props;
  const navigate = useNavigate();

  // const [showChat, setShowChat] = useState(false);
  // const [questionResponseIndex, setQuestionResponseIndex] = useState(0);

  // useEffect(() => {
  //   if (showChat) {
  //     setTimeout(() => {
  //       setShowChat(false);
  //       setQuestionResponseIndex(
  //         (questionResponseIndex + 1) % questionsResponses.length,
  //       );
  //     }, 9300);
  //   } else {
  //     setTimeout(() => {
  //       setShowChat(true);
  //     }, 1000);
  //   }
  // }, [showChat]);

  return (
    <section className="relative hero-header-container grid grid-cols-1 gap-y-16 pt-16 md:grid-flow-row md:pt-24 lg:grid-flow-col lg:grid-cols-2 lg:items-center lg:pt-0">
      <div className="mx-[5%] max-w-[40rem] justify-self-start lg:ml-[5vw] lg:mr-20 lg:justify-self-end">
        <h1 className="text-slate-400 mb-5 text-6xl font-bold md:mb-6 md:text-9xl lg:text-10xl">
          {heading}
        </h1>
        <p className="text-slate-100 md:text-md">{description}</p>
        <div className="mt-6 flex gap-x-4 md:mt-8">
          {/*<Button colorScheme="teal">Learn more</Button>*/}
          <Button
            colorScheme="teal"
            variant="outline"
            onClick={() => navigate("/request-demo")}
          >
            Request demo
          </Button>
        </div>
      </div>
      <div className="relative h-[30rem] overflow-hidden pl-[5vw] pr-[5vw] md:h-[40rem] lg:h-screen lg:pl-0">
        <div className="grid w-full grid-cols-2 gap-x-4">
          <div className="-mt-[120%] grid size-full columns-2 grid-cols-1 gap-4 self-center">
            {images.map((image, index) => (
              <div key={index} className="grid size-full grid-cols-1 gap-4">
                <div className="relative w-full pt-[120%]">
                  <img
                    className="absolute inset-0 size-full object-cover"
                    src={image.src}
                    alt={image.alt}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="grid size-full grid-cols-1 gap-4">
            {secondaryImages.map((image, index) => (
              <div key={index} className="grid size-full grid-cols-1 gap-4">
                <div className="relative w-full pt-[120%]">
                  <img
                    className="absolute inset-0 size-full object-cover"
                    src={image.src}
                    alt={image.alt}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*{showChat && (*/}
        {/*  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">*/}
        {/*    <div className="mb-2 chat-entry">*/}
        {/*      <ChatEntry*/}
        {/*        imageSrc={questionsResponses[questionResponseIndex].userAvatar}*/}
        {/*        name={*/}
        {/*          questionsResponses[questionResponseIndex].userName || "User"*/}
        {/*        }*/}
        {/*        isDelivered={true}*/}
        {/*        message={questionsResponses[questionResponseIndex].question}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    <div className="chat-entry-response">*/}
        {/*      <ChatEntry*/}
        {/*        imageSrc={stockyCart}*/}
        {/*        name={"Stocky AI"}*/}
        {/*        isDelivered={true}*/}
        {/*        message={questionsResponses[questionResponseIndex].response}*/}
        {/*        messageTime={"11:47"}*/}
        {/*        isResponse*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </section>
  );
};

const sproutingPlants = new URL(
  "../../assets/images/sprouting-plants-resize.jpg",
  import.meta.url,
).href;
const greenLeafedPlants = new URL(
  "../../assets/images/green-leafed-plants.jpg",
  import.meta.url,
).href;
const greenHouse = new URL(
  "../../assets/images/greenhouse.jpg",
  import.meta.url,
).href;
const peppers = new URL("../../assets/images/peppers.jpg", import.meta.url)
  .href;
const planting = new URL("../../assets/images/planting.jpg", import.meta.url)
  .href;
const tomatoesRipening = new URL(
  "../../assets/images/tomatoes-ripening.jpg",
  import.meta.url,
).href;
const walkThroughVineyard = new URL(
  "../../assets/images/walk-through-vineyard.jpg",
  import.meta.url,
).href;
const barista = new URL("../../assets/images/barista.jpg", import.meta.url)
  .href;
const appleInHand = new URL(
  "../../assets/images/apple-in-hand.jpg",
  import.meta.url,
).href;
const tomatoVines = new URL(
  "../../assets/images/tomato-vines.jpg",
  import.meta.url,
).href;
const greenGrapesOnVine = new URL(
  "../../assets/images/green-grapes-on-vine.jpg",
  import.meta.url,
).href;
const vegOnStall = new URL(
  "../../assets/images/veg-on-stall.jpg",
  import.meta.url,
).href;
const wineInspection = new URL(
  "../../assets/images/wine-inspection.jpg",
  import.meta.url,
).href;
const pickingPapayas = new URL(
  "../../assets/images/picking-papayas.jpg",
  import.meta.url,
).href;
const cabbages = new URL("../../assets/images/cabbages.jpg", import.meta.url)
  .href;
const restaurantBucharest = new URL(
  "../../assets/images/restaurant-bucharest.jpg",
  import.meta.url,
).href;
const wineCasks = new URL("../../assets/images/wine-casks.jpg", import.meta.url)
  .href;

export const HeroHeaderDefaults: HeroHeaderProps = {
  // heading: "Improving Supply Chains in the F&B Industry",
  heading: "Software to Streamline F&B Businesses",
  description:
    "We improve the operational efficiency of the F&B supply chain, from Supply to Sale. " +
    "Integrated ordering & invoicing with account management, accurate inventory tracking with traceability, and digital " +
    "harvest records for producers. Boost your sales and save time & money on admin tasks and certificate management with Stocky AI.",
  buttons: [{ title: "Button" }, { title: "Button", variant: "secondary" }],
  images: [
    // images of business, operation, farmers, F&B businesses, wineries etc.
    {
      src: sproutingPlants,
      alt: "Sprouting plants",
    },
    {
      alt: "Green leafed plants",
      src: greenLeafedPlants,
    },
    {
      alt: "Walk through vineyard",
      src: walkThroughVineyard,
    },
    {
      alt: "Barista",
      src: barista,
    },
    {
      alt: "Green house",
      src: greenHouse,
    },
    {
      alt: "Peppers",
      src: peppers,
    },
    {
      alt: "Apple in hand",
      src: appleInHand,
    },
    {
      alt: "Planting",
      src: planting,
    },
    {
      alt: "Tomatoes ripening on vine",
      src: tomatoesRipening,
    },
    // {
    //   alt: "Lavender field",
    //   src: "https://images.unsplash.com/photo-1656850792907-4ae061fbd94e?q=80&w=2574&auto=format&fit=crop",
    // },
    // {
    //   alt: "Kale at market stall",
    //   src: "https://plus.unsplash.com/premium_photo-1686285541221-1c0be9f3c4fd?q=80&w=2574&auto=format&fit=crop",
    // },
    // {
    //   alt: "Restaurant",
    //   src: "https://images.unsplash.com/photo-1636405188904-bc706f07aa37?q=80&w=2454&auto=format&fit=crop",
    // },
    // {
    //   alt: "Grape harvest",
    //   src: "https://images.unsplash.com/photo-1705320851232-925f55465f46?q=80&w=2574&auto=format&fit=crop",
    // },
    // {
    //   alt: "Papayas",
    //   src: "https://images.unsplash.com/photo-1517383733478-12faa4d8222f?q=80&w=2000&auto=format&fit=crop",
    // },
    // {
    //   alt: "Farmers market",
    //   src: "https://images.unsplash.com/photo-1526399743290-f73cb4022f48?q=80&w=2564&auto=format&fit=crop",
    // },
  ],
  secondaryImages: [
    {
      alt: "Tomato plants",
      src: tomatoVines,
    },
    {
      src: greenGrapesOnVine,
      alt: "Green grapes on vine",
    },
    {
      src: vegOnStall,
      alt: "Veg on stall",
    },
    {
      alt: "Wine inspection",
      src: wineInspection,
    },
    {
      alt: "Picking papayas at farmers market",
      src: pickingPapayas,
    },
    {
      alt: "Cabbages",
      src: cabbages,
    },
    {
      alt: "Restaurant Bucharest",
      src: restaurantBucharest,
    },
    {
      alt: "Wine casks",
      src: wineCasks,
    },
    {
      alt: "Apple in hand",
      src: appleInHand,
    },
    // {
    //   alt: "Green grapes closeup",
    //   src: "https://images.unsplash.com/photo-1706001858535-2d782a8f3137?q=80&w=2574&auto=format&fit=crop",
    // },
    // {
    //   alt: "Wheat field",
    //   src: "https://images.unsplash.com/photo-1656917293586-cf84e42d90dc?q=80&w=2574&auto=format&fit=crop",
    // },
    // {
    //   alt: "Leafy greens at market",
    //   src: "https://plus.unsplash.com/premium_photo-1686754185593-916ea2fa4181?q=80&w=2574&auto=format&fit=crop",
    // },
    // {
    //   alt: "Cafe",
    //   src: "https://images.unsplash.com/photo-1574974051678-44c85748ebcc?q=80&w=2574&auto=format&fit=crop",
    // },
    // {
    //   alt: "Wine cellar",
    //   src: "https://plus.unsplash.com/premium_photo-1664305023165-924e03f4d2f2?q=80&w=2532&auto=format&fit=crop",
    // },
    // {
    //   src: "https://images.unsplash.com/photo-1710563159928-83611beece71?q=80&w=2360&auto=format&fit=crop",
    //   alt: "Farmer smiling in wheat field",
    // },
  ],
};
