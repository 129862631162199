import * as React from "react";
import { Box, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  useGetInvoicesQuery,
  useGetOrdersQuery,
  useGetSuppliersQuery,
} from "../../redux/apiSlice";
import OrderViewEntry from "./OrderViewEntry";
import { filterDatesSlice } from "../../redux/filterDatesSlice";
import { useAppSelector } from "../../redux/hooks";
import debounce from "../../utils/debounce";
import { userSlice } from "../../redux/userSlice";
import DataTabs from "../../components/DataTabs/DataTabs";
import InvoiceViewEntry from "./InvoiceViewEntry";
import OrderModal from "./OrderModal";
import { Order } from "../../types.ts";

const PurchaseOrdersView = ({
  setTabIndex,
}: {
  setTabIndex: (index: number) => void;
}): React.ReactElement => {
  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);
  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const {
    data: orders,
    error: ordersError,
    isLoading: ordersLoading,
  } = useGetOrdersQuery({ organisationId, locationId, startDate, endDate });

  const {
    data: suppliers,
    error: suppliersError,
    isLoading: suppliersLoading,
  } = useGetSuppliersQuery({ organisationId, locationId });

  const {
    data: invoices,
    error: invoicesError,
    isLoading: invoicesLoading,
  } = useGetInvoicesQuery({ organisationId, locationId, startDate, endDate });

  const [filterOrders, setFilterOrders] = useState<boolean>(true);
  const [ordersList, setOrdersList] = useState<Order[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getSupplierName = (supplierId: number) => {
    return (
      suppliers?.find((supplier) => supplier.id === supplierId)?.name || ""
    );
  };
  const debouncedSetFilterOrders = debounce(
    () => setFilterOrders(!filterOrders),
    300,
  );
  useEffect(() => {
    if (orders && suppliers) {
      const sortedOrders = orders.slice().sort((a, b) => {
        if (a.created_at && b.created_at) {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        }
        return b.id && a.id ? b.id - a.id : -1;
      });
      if (filterOrders) {
        setOrdersList(
          sortedOrders.filter(
            (order) => filterOrders && order.status !== "DELIVERED",
          ),
        );
      } else {
        setOrdersList(sortedOrders);
      }
    } else {
      setOrdersList([]);
    }
  }, [orders, suppliers, filterOrders]);

  return (
    <Box
      p={2}
      my={10}
      mx={[0, 0, 10]}
      bg={"gray.200"}
      border="1px solid"
      borderColor="gray.400"
      rounded="md"
      boxShadow="lg"
      overflow="auto"
      minH="300px"
    >
      {isOpen && <OrderModal isOpen={isOpen} onClose={onClose} />}
      <DataTabs
        onChange={(index) => setTabIndex(index)}
        filterApplied={filterOrders}
        setFilterApplied={debouncedSetFilterOrders}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        data={[
          {
            label: "Orders",
            buttonLabel: "Add Purchase Order",
            headers: [
              "Order ID",
              "Supplier",
              "Order Date",
              "Requested For",
              "Status",
              "Value",
            ],
            showFilter: true,
            filterLabel: "Filter delivered orders",
            isLoading: ordersLoading || suppliersLoading,
            entries:
              ordersList && suppliers
                ? ordersList
                    .slice()
                    .sort((a, b) => b.id - a.id)
                    .map((order, index) => (
                      <OrderViewEntry
                        key={index}
                        itemIndex={index}
                        order={order}
                        organisations={suppliers}
                        supplierOrCustomerName={getSupplierName(
                          order.supplier_organisation_id,
                        )}
                      />
                    ))
                : [],
          },
          {
            label: "Invoices",
            headers: [
              "Invoice ID",
              "Order ID",
              "Supplier",
              "Invoice Date",
              "Delivery Date",
              "Status",
              "Total",
              "Credit",
              "Balance",
              "Paid",
              "Due",
            ],
            hideButton: true,
            isLoading: invoicesLoading || suppliersLoading,
            entries: invoices
              ? invoices
                  .slice()
                  .sort((a, b) => b.id - a.id)
                  .map((invoice, index) => (
                    <InvoiceViewEntry
                      key={index}
                      itemIndex={index}
                      invoice={invoice}
                      supplierOrCustomerName={getSupplierName(
                        invoice.supplier_organisation_id,
                      )}
                    />
                  ))
              : [],
          },
        ]}
      />
      {ordersError && <div>Failed to load orders</div>}
      {suppliersError && <div>Failed to load suppliers</div>}
      {invoicesError && <div>Failed to load invoices</div>}
      {(ordersLoading || suppliersLoading || invoicesLoading) && (
        <div>Loading...</div>
      )}
    </Box>
  );
};

export default PurchaseOrdersView;
