import React, { useEffect, useState } from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import PurchaseOrdersView from "../feature/OrdersView/PurchaseOrdersView";
import { filterDatesSlice } from "../redux/filterDatesSlice";
import { useAppSelector } from "../redux/hooks";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { PlusSquareIcon } from "@chakra-ui/icons";
import OrderListModal from "../feature/OrdersView/OrderListModal.tsx";
import { useGetCurrentUserQuery } from "../redux/apiSlice.ts";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ImportPricesModal from "../feature/OrdersView/ImportPricesModal.tsx";

function PurchaseOrdersPage(): React.ReactElement {
  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);

  const { data: currentUser } = useGetCurrentUserQuery("");

  const [tabIndex, setTabIndex] = useState(0);
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isImportPricesModalOpen,
    onOpen: onImportPricesModalOpen,
    onClose: onImportPricesModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (currentUser && currentUser.organisation_id === 1) {
      setIsUserAdmin(true);
    }
  }, [currentUser]);

  return (
    <>
      <PageOverview
        heading={"Purchases"}
        subheading={startDate && endDate && `${startDate} - ${endDate}`}
        showDateRangePicker={true}
        endElement={
          <>
            {isUserAdmin && // TODO: replace with feature flag
              tabIndex === 0 && (
                <Flex
                  gap={2}
                  alignItems={"end"}
                  flexDirection={["column", "row"]}
                >
                  <Tooltip
                    label="Create Order List"
                    aria-label="Create Order List"
                  >
                    <Button
                      aria-label={"Create Order List"}
                      colorScheme={"teal"}
                      onClick={onOpen}
                    >
                      <PlusSquareIcon mr={2} />
                      Create Order List
                    </Button>
                  </Tooltip>
                  <Menu>
                    <MenuButton as={Button}>
                      <HiOutlineDotsVertical />
                    </MenuButton>
                    <MenuList textColor={"black"}>
                      <MenuItem onClick={onImportPricesModalOpen}>
                        Import Supplier Prices
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              )}
          </>
        }
      />
      <PurchaseOrdersView setTabIndex={setTabIndex} />
      <OrderListModal isOpen={isOpen} onClose={onClose} />
      <ImportPricesModal
        isOpen={isImportPricesModalOpen}
        onClose={onImportPricesModalClose}
      />
    </>
  );
}

export default PurchaseOrdersPage;
