import React from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  VStack,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { Logo } from "./Logo/Logo";
import { routes } from "../index";
import {
  checkIfLocationHasAccess,
  checkIfOrgHasAccess,
} from "../routes/PrivateRoute";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { checkIsLoginPath } from "../utils/routerUtils";
import LocationPicker from "../feature/LocationPicker/LocationPicker";
import { userSlice } from "../redux/userSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useGetCurrentUserQuery } from "../redux/apiSlice";
import { getLocationAndOrgName } from "../utils/invoiceUtils.ts";

type LinkProps = {
  navName: string;
  navLink: string;
  navIcon: React.ReactNode;
};

const AppNavBar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navLocation = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isAuthenticated = useAppSelector(
    userSlice.selectors.getIsAuthenticated,
  );

  const {
    getCurrentOrganisationId,
    getCurrentLocation,
    getCurrentOrganisation,
  } = userSlice.selectors;

  const currentOrganisationId = useAppSelector(getCurrentOrganisationId);
  const currentLocation = useAppSelector(getCurrentLocation);
  const currentOrganisation = useAppSelector(getCurrentOrganisation);

  const { data: currentUser } = useGetCurrentUserQuery("", {
    skip: !isAuthenticated,
  });

  const isLoginPath = checkIsLoginPath(navLocation);

  const navigateToLogin = () => {
    if (!isLoginPath) {
      navigate("/login");
    }
  };

  const filteredRoutes = routes.filter((route) => {
    if (
      !currentLocation ||
      !route.includeInNav ||
      !route.navName ||
      !route.navLink ||
      !currentOrganisationId
    ) {
      return false;
    }
    if (currentOrganisation && currentLocation) {
      if (route.locationFilters && route.locationFilters.length > 0) {
        if (!checkIfLocationHasAccess(currentLocation, route.locationFilters)) {
          return false;
        }
      }
      return checkIfOrgHasAccess(currentOrganisation, route.name);
    }
    return true;
  });

  const location = useLocation();

  const renderNavLinks = (isMobile = false) => (
    <>
      {filteredRoutes.map((route, index) => {
        const link = route as LinkProps;
        const isActive =
          (location.pathname[0] === "/"
            ? location.pathname.substring(1)
            : link.navLink) === link.navLink;

        return (
          <React.Fragment key={link.navLink}>
            <Link to={link.navLink} onClick={onClose}>
              <Tooltip label={link.navName} aria-label={link.navName}>
                <Flex
                  w={isMobile ? "full" : "auto"}
                  alignItems="center"
                  justifyContent={isMobile ? "center" : "flex-start"}
                  py={isMobile ? 4 : 2}
                  px={isMobile ? 0 : 3}
                >
                  {isMobile ? (
                    <VStack spacing={2} align="center">
                      <Text color={isActive ? "teal" : "inherit"}>
                        {link.navIcon}
                      </Text>
                      <Text fontSize="sm" color={isActive ? "teal" : "inherit"}>
                        {link.navName}
                      </Text>
                    </VStack>
                  ) : (
                    <Box
                      fontSize="xl"
                      pb={2}
                      color={isActive ? "teal" : "inherit"}
                      borderBottom="1px"
                      borderColor={isActive ? "teal" : "inherit"}
                    >
                      {link.navIcon}
                    </Box>
                  )}
                </Flex>
              </Tooltip>
            </Link>
            {isMobile && index < filteredRoutes.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </>
  );

  const bgColor = useColorModeValue("gray.100", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Flex
      h={16}
      alignItems="center"
      bg={bgColor}
      borderBottom="1px"
      borderColor={borderColor}
    >
      <Flex alignItems="center" justifyContent="space-between" w="full" px={4}>
        <HStack spacing={4}>
          <Box
            onClick={onOpen}
            aria-label="Open menu"
            display={{ base: "flex", md: "none" }}
            justifyContent="center"
          >
            <Image
              width={"48px"}
              height={"48px"}
              minWidth={"48px"}
              src={
                new URL("../assets/images/stockyCart.png", import.meta.url).href
              }
              objectFit="contain"
            />
          </Box>
          <Box display={{ base: "none", md: "block" }}>
            <Logo />
          </Box>

          {isAuthenticated && currentLocation && (
            <HStack as="nav" spacing={1} display={{ base: "none", md: "flex" }}>
              {renderNavLinks()}
            </HStack>
          )}
        </HStack>

        {isAuthenticated && currentLocation && currentUser && (
          <Menu offset={[50, 12]}>
            <MenuButton
              as={Button}
              rounded="full"
              variant="link"
              cursor="pointer"
              minW={0}
            >
              <Flex align="center" justify="right">
                <Text mr={6} whiteSpace={"wrap"} noOfLines={1}>
                  {getLocationAndOrgName(currentLocation)}
                </Text>
                <Avatar size="sm" />
              </Flex>
            </MenuButton>

            <MenuList>
              <Box p={2}>
                {currentUser.first_name} {currentUser.last_name}
              </Box>
              <MenuDivider />
              <LocationPicker />
              <MenuItem isDisabled>Settings</MenuItem>
              <MenuDivider />
              <MenuItem
                isDisabled={isLoginPath || !isAuthenticated}
                onClick={() => {
                  dispatch(userSlice.actions.logout());
                  navigateToLogin();
                }}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>

      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            borderBottomWidth="1px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text>Menu</Text>
            <IconButton
              aria-label="Close menu"
              icon={<CloseIcon />}
              onClick={onClose}
              variant="ghost"
              size="sm"
            />
          </DrawerHeader>
          <DrawerBody>
            <VStack align="stretch" spacing={4}>
              {renderNavLinks(true)}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default AppNavBar;
