import * as React from "react";
import { Input, Text } from "@chakra-ui/react";

interface EditableColumnProps {
  inputId?: string;
  value: string | number;
  inputType?: string;
  setValue: (value: string | number) => void;
  label?: string;
  isEditable?: boolean;
  width?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  customStyles?: React.CSSProperties;
}
export default function EditableColumn({
  inputId,
  value,
  inputType = "number",
  setValue,
  label,
  isEditable = true,
  width = "60px",
  onKeyDown,
  customStyles,
}: EditableColumnProps) {
  const [editValue, setEditValue] = React.useState(value);

  const handleSetValue = () => {
    if (editValue !== value) {
      if (inputType === "number" && !isNaN(Number(editValue))) {
        setValue(editValue);
      } else {
        setValue(editValue);
      }
    }
  };

  return (
    <div
      style={{
        ...(label ? { display: "flex", alignItems: "center" } : {}),
        ...customStyles,
      }}
    >
      {label && <Text>{label}</Text>}
      {isEditable ? (
        <Input
          id={inputId}
          rounded="md"
          size="sm"
          w={width}
          type={inputType}
          defaultValue={editValue}
          onChange={(e) => setEditValue(e.target.value ? e.target.value : "")}
          onBlur={() => handleSetValue()}
          onWheel={(e) => {
            e.currentTarget.blur();
            setValue(editValue); // prevent scrolling from changing value
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Tab") {
              handleSetValue();
            }
            if (onKeyDown) {
              onKeyDown(e);
            }
          }}
        />
      ) : (
        value
      )}
    </div>
  );
}
