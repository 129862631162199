import * as React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Skeleton,
  Spacer,
  Switch,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { PlusSquareIcon } from "@chakra-ui/icons";

type TabData = {
  label: string;
  headers: (string | React.ReactNode)[];
  entries: React.ReactNode[];
  showFilter?: boolean;
  filterLabel?: string;
  buttonLabel?: string;
  hideButton?: boolean;
  isLoading?: boolean;
  customFilter?: React.ReactNode;
};

interface TabProps {
  data: TabData[];
  filterApplied?: boolean;
  setFilterApplied?: (value: boolean) => void;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onChange?: (index: number) => void;
  showButton?: boolean;
  searchElements?: React.ReactNode;
}

function DataTabs({
  data,
  filterApplied,
  setFilterApplied,
  onOpen,
  onChange,
  showButton = true,
  searchElements,
}: TabProps) {
  const [tabIndex, setTabIndex] = useState(0);

  const getButtonTitle = (label: string) => {
    let displayLabel = label.slice(0, -1);
    // if last two letters are ie, replace with y
    if (displayLabel.slice(-2) === "ie") {
      displayLabel = displayLabel.slice(0, -2) + "y";
    }
    return displayLabel;
  };

  const getLoadingTableRow = (headers: (string | React.ReactNode)[]) => {
    return (
      <Tr>
        {headers.map((_, index) => (
          <Td key={index}>
            <Skeleton height={"30px"} minW={"60px"} />
          </Td>
        ))}
      </Tr>
    );
  };

  const getLoadingState = (headers: (string | React.ReactNode)[]) => {
    // return 10 rows of loading state
    return Array.from({ length: 10 }, () => getLoadingTableRow(headers));
  };

  return (
    <>
      <Tabs
        onChange={(index) => {
          setTabIndex(index);
          if (onChange) {
            onChange(index);
          }
        }}
      >
        <TabList>
          {data.map(({ label }) => (
            <Tab key={label} minW="fit-content">
              {label}
            </Tab>
          ))}
          <HStack w={"100%"} display={"flex"} justifyContent={"flex-end"}>
            {searchElements ? searchElements : <Spacer />}
            <Spacer />
            {data[tabIndex].showFilter && setFilterApplied && (
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mb={2}
              >
                <FormLabel htmlFor="email-alerts" mb="0">
                  {data[tabIndex].filterLabel ||
                    `Filter ${data[tabIndex].label.slice(0, -1)}`}
                </FormLabel>
                <Switch
                  colorScheme={"teal"}
                  defaultChecked={filterApplied}
                  onChange={(e) => setFilterApplied(e.target.checked)}
                />
              </FormControl>
            )}
            {data[tabIndex].customFilter && (
              <Box w={"fit-content"} ml="auto" h="40px">
                {data[tabIndex].customFilter}
              </Box>
            )}

            <Spacer />
            {showButton && !data[tabIndex].hideButton && (
              <Button
                marginX={4}
                variant="solid"
                colorScheme="teal"
                aria-label={
                  data[tabIndex].buttonLabel ||
                  `Add ${getButtonTitle(data[tabIndex].label)}`
                }
                fontSize="16px"
                onClick={onOpen}
                minWidth="fit-content"
                px={8}
              >
                <PlusSquareIcon mr={2} />
                <Text noOfLines={1} whiteSpace="wrap">
                  {data[tabIndex].buttonLabel ||
                    `Add ${getButtonTitle(data[tabIndex].label)}`}
                </Text>
              </Button>
            )}
          </HStack>
        </TabList>
        <TabPanels>
          {data.map((tab) => (
            <TabPanel p={4} key={`${tabIndex}_${tab.label}`}>
              <TableContainer>
                <Table size="md">
                  <Thead>
                    <Tr fontWeight="900">
                      {tab.headers.map((header, index) => (
                        <Th key={`${tab.label}_${index}}`}>{header}</Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tab.isLoading ? getLoadingState(tab.headers) : tab.entries}
                  </Tbody>
                </Table>
                {tab.entries && tab.entries.length === 0 && (
                  <div>
                    No items found - create an entry, or try updating filters,
                    dates or settings
                  </div>
                )}
              </TableContainer>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
}

export default DataTabs;
