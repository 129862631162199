import * as React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Button,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  RowSelectionState,
  Updater,
} from "@tanstack/react-table";
import { ReactNode, useState } from "react";

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  columnVisibility?: Record<string, boolean>;
  showAddRow?: boolean;
  onAddingRow?: () => void;
  addRowLabel?: string;
  addRowNextFocus?: string;
  addRowChildren?: ReactNode[];
  enableRowSelection?: boolean;
  onRowSelectionChange?: (updater: Updater<RowSelectionState>) => void;
  rowSelection?: RowSelectionState;
};

function DataTable<Data extends object>({
  data,
  columns,
  columnVisibility,
  showAddRow = false,
  onAddingRow,
  addRowLabel,
  addRowNextFocus,
  addRowChildren,
  enableRowSelection = false,
  onRowSelectionChange,
  rowSelection,
}: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection,
    onRowSelectionChange,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
    },
  });

  const [addRow, setAddRow] = useState<boolean>(false);

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const meta: any = header.column.columnDef.meta;
              return (
                <Th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  isNumeric={meta?.isNumeric}
                  width={header.column.getSize()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}

                  <chakra.span pl="4">
                    {header.column.getIsSorted() ? (
                      header.column.getIsSorted() === "desc" ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr key={row.id}>
            {row.getVisibleCells().map((cell) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const meta: any = cell.column.columnDef.meta;
              return (
                <Td
                  key={cell.id}
                  isNumeric={meta?.isNumeric}
                  width={cell.column.getSize()}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              );
            })}
          </Tr>
        ))}
        {showAddRow && addRowChildren && (
          <Tr key={"add-row"}>
            {addRow ? (
              <>
                {addRowChildren.map((child, index) => (
                  <Td key={index}>{child}</Td>
                ))}
              </>
            ) : (
              <>
                {table
                  .getHeaderGroups()
                  [table.getHeaderGroups().length - 1].headers.map(
                    (header, index) => (
                      <Td key={header.id}>
                        {index === 0 && (
                          <Button
                            variant={"ghost"}
                            onClick={() => {
                              if (onAddingRow) {
                                onAddingRow();
                              }
                              setAddRow(!addRow);
                              setTimeout(() => {
                                if (
                                  addRowNextFocus &&
                                  document.getElementById(addRowNextFocus)
                                ) {
                                  // @ts-ignore
                                  document
                                    .getElementById(addRowNextFocus)
                                    .focus();
                                }
                              }, 200);
                            }}
                          >
                            + Add {addRowLabel ? addRowLabel : "row"}
                          </Button>
                        )}
                      </Td>
                    ),
                  )}
              </>
            )}
          </Tr>
        )}
      </Tbody>
    </Table>
  );
}

export default DataTable;
